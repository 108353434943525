var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dates", class: { open: _vm.open } }, [
    _c("div", { staticClass: "select", class: { open: _vm.open } }, [
      _c(
        "button",
        {
          staticClass: "select__head",
          attrs: {
            "aria-controls": "length-dropdown" + _vm._uid,
            "aria-expanded": !!_vm.open
          },
          on: {
            click: function($event) {
              _vm.open = !_vm.open
            }
          }
        },
        [_vm._v(_vm._s(_vm.title))]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "dates__dropdown",
        attrs: { id: "length-dropdown" + _vm._uid }
      },
      [
        _c("p", { staticClass: "text-center" }, [
          _vm._v("Find content around this many minutes in length.")
        ]),
        _vm._v(" "),
        !!_vm.open
          ? _c(
              "vue-slider",
              _vm._b(
                {
                  attrs: {
                    "use-keyboard": true,
                    "dot-attrs": { "aria-label": "Select content length" },
                    "tooltip-formatter": "{value} min"
                  },
                  model: {
                    value: _vm.value,
                    callback: function($$v) {
                      _vm.value = $$v
                    },
                    expression: "value"
                  }
                },
                "vue-slider",
                _vm.options,
                false
              )
            )
          : _vm._e(),
        _vm._v(" "),
        !!_vm.open
          ? _c(
              "button",
              {
                staticClass: "dates__btn btn btn_sm",
                attrs: { "aria-label": "Submit" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.submit($event)
                  }
                }
              },
              [_vm._v("Submit")]
            )
          : _vm._e(),
        _vm._v(" "),
        !!_vm.open
          ? _c(
              "button",
              {
                staticClass: "dates__btn btn btn_sm clear",
                attrs: { "aria-label": "Clear" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.clear($event)
                  }
                }
              },
              [_vm._v("Clear")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }