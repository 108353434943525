<template>
    <div role="combobox" :aria-label="title" class="select" v-bind:class="{open : open}">
        <button @keydown.tab="open = false" @keydown.escape="open = false" @keydown.down.prevent="down" @keydown.up.prevent="up" class="select__head" v-on:click="open = !open">{{currentTitle}}</button>
        <div class="select__drop" role="listbox">
            <div :aria-selected="isAllSelected()" role="option" class="select__option" :class="{'select__option_selected': isAllSelected()}" v-on:click="clear">All</div>
            <div :aria-selected="isSelected(option)" role="option" class="select__option" :class="{'select__option_selected': isSelected(option)}" v-for="option in options" :key="option.name" v-on:click="select(option)">{{option.name}}</div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { EventBus } from '../vues/event-bus'

    export default {
        props: ['symbol', 'title', 'options'],
        data() {
            return {
                open: false,
                selected: null
            }
        },
        computed: {
            currentTitle() {
                if (this.selected) {
                    return this.selected.name
                }
                else {
                    return this.title
                }
            },
            currentSelectedIndex() {
                if (this.isAllSelected()) return -1;
                return this.options.indexOf(this.selected);
            }
        },
        methods: {
            isAllSelected() {
                return !this.selected;
            },
            isSelected(option) {
                return !!this.selected && this.selected.name == option.name;
            },
            down() {
                if (!this.open) {
                    this.open = true;
                } else if (this.isAllSelected()) {
                    this.select(this.options[0], true);
                } else {
                    let index = this.currentSelectedIndex + 1;
                    if (index >= this.options.length) {
                        this.clear(true);
                    } else {
                        this.select(this.options[index], true);
                    }
                }
            },
            up() {
                if (!this.open) {
                    this.open = true;
                } else if (this.isAllSelected()) {
                    this.open = false;
                } else {
                    let index = this.currentSelectedIndex - 1;
                    if (index < 0) {
                        this.clear(true);
                    } else {
                        this.select(this.options[index], true);
                    }
                }
            },
            select(option, openState) {
                if (openState === undefined) openState = false;

                this.selected = option
                this.open = openState
                this.emit()
            },
            clear(openState) {
                if (openState === undefined) openState = false;

                this.selected = null
                this.open = openState
                this.emit()
            },
            emit() {
                EventBus.$emit('select', { symbol: this.symbol, selected: this.selected })
            }
        },
        mounted() {
            var self = this
            EventBus.$on('setdropdown', (data) => {
                if (data.symbol == self.symbol) {
                    self.selected = self.options.find(x => x.name == data.selected)
                }
            })
        }
    }
</script>