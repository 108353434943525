<template>
    <div>
        <div class="sidebar__title">{{ title }}</div>
        <div class="sidebar__articles articles">
            <div class="articles__item" v-for="(item, index) in items" :key="index">
                <a class="articles__preview" :href="item.url"><img class="articles__pic" :src="item.image" :alt="item.title"></a>
                <div class="articles__inner">
                    <a class="articles__title" :href="item.url">{{item.title}}</a>
                    <a class="articles__author" :href="item.authorUrls[0]" v-if="item.authorNames && item.authorNames.length">&mdash;{{item.authorNames[0]}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    export default {
        props: ['title', 'ignore'],
        data() {
            return {
                items: []
            }
        },
        mounted() {
            var self = this
            var request = {
                ignore: this.ignore
            }
            self.$http.post('/umbraco/surface/catalog/popular', request)
                .then(({ data }) => {
                    self.items = data
                })
                .catch((error) => { })
        }
    }
</script>