var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickout",
          rawName: "v-clickout",
          value: _vm.close,
          expression: "close"
        }
      ],
      staticClass: "filters filters__tags"
    },
    [
      _c("div", { staticClass: "filters__title has-groups" }, [
        _vm._v("Filter by")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "filters__trigger" }, [
        _c(
          "span",
          {
            staticClass: "red",
            on: {
              click: function($event) {
                _vm.active = true
              }
            }
          },
          [
            _vm._v("Open Filters "),
            _vm.values.length
              ? _c("font", [_vm._v("(" + _vm._s(_vm.values.length) + ")")])
              : _vm._e()
          ],
          1
        ),
        _vm.values.length
          ? _c("span", { on: { click: _vm.clear } }, [_vm._v("Clear")])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filters__group", class: { active: _vm.active } },
        [
          _c("div", { staticClass: "filters__group_close" }, [
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    _vm.active = false
                  }
                }
              },
              [_vm._v("×")]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filters__group_controls" },
            _vm._l(_vm.groups, function(item) {
              return _c("tagfilter", {
                key: item.name,
                attrs: { tags: item.tags, group: item.name, title: item.title },
                on: { input: _vm.changed, added: _vm.changed }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filters__group_labels" },
            [
              _vm._l(_vm.values, function(item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "fgl_i",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.remove(item.id, item.group)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.text)), _c("span", [_vm._v("×")])]
                )
              }),
              _vm._v(" "),
              _vm.values.length > 1
                ? _c(
                    "div",
                    {
                      staticClass: "fgl_i clear",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.clear($event)
                        }
                      }
                    },
                    [_vm._v("Clear All"), _c("span", [_vm._v("×")])]
                  )
                : _vm._e()
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }