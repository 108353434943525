var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upcoming-event" }, [
    _c("div", { staticClass: "upcoming__events events events_upcoming" }, [
      _vm.hasItem
        ? _c(
            "div",
            {
              staticClass: "events__item",
              class:
                (_vm.bordered ? "bordered" : "") +
                " " +
                (_vm.background ? "background" : "")
            },
            [
              _c(
                "a",
                {
                  staticClass: "events__preview",
                  attrs: { href: _vm.getUrl(_vm.item) }
                },
                [
                  _c("img", {
                    staticClass: "events__pic",
                    attrs: {
                      src: _vm.item.productionSeasonListImageUrl,
                      alt: _vm.item.productionSeasonListImageAlt
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "events__inner" },
                [
                  _vm.showTag && _vm.tagText && _vm.tagText.length > 0
                    ? [
                        _vm.tagLink && _vm.tagLink.length > 0
                          ? _c(
                              "a",
                              {
                                staticClass: "events__link link",
                                attrs: {
                                  href: _vm.tagLink,
                                  target: _vm.tagTarget
                                }
                              },
                              [_vm._v(_vm._s(_vm.tagText))]
                            )
                          : _c("div", { staticClass: "events__link" }, [
                              _vm._v(_vm._s(_vm.tagText))
                            ])
                      ]
                    : _vm.showTag
                    ? [
                        _vm.item.concertTypes &&
                        _vm.item.concertTypes.length > 0
                          ? _c(
                              "a",
                              {
                                staticClass: "events__link link",
                                attrs: {
                                  href: _vm.item.concertTypes
                                    ? "/concerts-tickets/whats-on?concert-types=" +
                                      _vm.item.concertTypes[0]
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.item.concertTypes
                                      ? _vm.item.concertTypes[0]
                                      : ""
                                  )
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.item.priceRange == "Free"
                          ? _c("div", { staticClass: "events__badge" }, [
                              _vm._v("Free Event")
                            ])
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h6", { staticClass: "events__title" }, [
                    _c("a", { attrs: { href: _vm.item.contentUrl } }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.item.contentTitle || _vm.item.description
                          ) +
                          "\n                "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "events__meta" }, [
                    _c("div", { staticClass: "events__detail" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "14",
                            height: "15"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              fill: "#b22222",
                              "fill-rule": "evenodd",
                              d:
                                "M7 .62a7 7 0 0 1 7 6.98 7 7 0 0 1-7 6.98A7 7 0 0 1 0 7.6 7 7 0 0 1 7 .62zm0 1.5A5.5 5.5 0 0 0 1.5 7.6 5.5 5.5 0 0 0 7 13.08a5.5 5.5 0 0 0 5.5-5.48A5.5 5.5 0 0 0 7 2.12zM7 3.8c.38 0 .69.31.69.69h0v3.48l1.84 1.33c.31.23.37.66.15.96h0l-.07.08c-.1.1-.23.17-.38.19a.7.7 0 0 1-.11.01c-.14 0-.27-.04-.39-.12h0 0l-.01-.01-2.4-1.74h.01-.01V4.48c0-.38.31-.69.69-.69z"
                            }
                          })
                        ]
                      ),
                      _vm._v(_vm._s(_vm.item.performanceDates))
                    ]),
                    _vm._v(" "),
                    _vm.item.venues && _vm.item.venues.length > 0
                      ? _c("div", { staticClass: "events__detail" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "13",
                                height: "16"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "#b22222",
                                  "fill-rule": "evenodd",
                                  d:
                                    "M6.53.06a5.9 5.9 0 0 1 4.28 1.91c1.12 1.17 1.77 2.69 1.77 4.17 0 2.13-1.64 4.94-4.87 8.36h0l-.22.23c-.26.27-.61.41-.96.41-.29 0-.58-.1-.83-.29h0l-.01-.01-.1-.09C2.2 11.2.48 8.31.48 6.13c0-1.47.64-2.99 1.77-4.17A5.9 5.9 0 0 1 6.53.06zm0 1.52c-2.35 0-4.57 2.22-4.57 4.56 0 1.65 1.54 4.12 4.57 7.35 3.03-3.22 4.57-5.7 4.57-7.35 0-2.34-2.22-4.56-4.57-4.56zm0 1.42a3.1 3.1 0 0 1 3.1 3.09 3.1 3.1 0 0 1-3.1 3.09 3.1 3.1 0 0 1-3.1-3.09A3.1 3.1 0 0 1 6.53 3h0zm0 1.47c-.89 0-1.62.73-1.62 1.62S5.63 7.7 6.53 7.7s1.62-.73 1.62-1.62-.73-1.62-1.62-1.62z"
                                }
                              })
                            ]
                          ),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.item.venues.join(", ")) +
                              "\n                "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.item.contentMetaDescription &&
                  _vm.item.contentMetaDescription.length > 0
                    ? _c("div", {
                        staticClass:
                          "events__content events__content_extended_3",
                        domProps: {
                          innerHTML: _vm._s(_vm.item.contentMetaDescription)
                        }
                      })
                    : [
                        _vm.item.productionSeasonDescriptionLong &&
                        _vm.item.productionSeasonDescriptionLong.length > 0
                          ? _c("div", {
                              staticClass:
                                "events__content events__content_extended_3",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.item.productionSeasonDescriptionLong
                                )
                              }
                            })
                          : _vm._e()
                      ],
                  _vm._v(" "),
                  !_vm.buyTicketsButton
                    ? _c("div", { staticClass: "events__foot" }, [
                        _c(
                          "a",
                          {
                            staticClass: "events__more",
                            attrs: { href: _vm.item.contentUrl }
                          },
                          [_vm._v("Event details »")]
                        ),
                        _vm._v(" "),
                        _vm.item.state > 1
                          ? _c("span", { staticClass: "events__divider" }, [
                              _vm._v("|")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.item.state == 2
                          ? _c(
                              "a",
                              {
                                staticClass: "events__more",
                                attrs: { href: _vm.item.subscribeUrl }
                              },
                              [_vm._v("Subscribe »")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.item.state > 2
                          ? _c(
                              "a",
                              {
                                staticClass: "events__more",
                                attrs: { href: _vm.item.bookUrl }
                              },
                              [_vm._v("Order now »")]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _vm.buyTicketsButton
                ? _c("div", { staticClass: "events__supplimentary" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn_sm",
                        attrs: { href: _vm.item.contentUrl /*getUrl(item)*/ }
                      },
                      [
                        _c("div", { staticClass: "btn__circle" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "btn__title" }, [
                          _vm._v("Get tickets")
                        ])
                      ]
                    )
                  ])
                : _vm._e()
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }