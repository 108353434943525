import Vue from '../config.js'
import VueScrollTo from 'vue-scrollto'
import { EventBus } from './event-bus'
var qs = require('qs')

if (document.getElementById('cso-search-vm')) {

    var vm = new Vue({
        el: '#cso-search-vm',
        data: {
            items: [],
            totalPages: 0,
            totalItems: 0,
            request: {
                page: 1,
                pageSize: 20,
                q: q,
                tab: ""
            },
            tab: ""
        },
        watch: {
            tab() {
                if (this.request.tab != this.tab) {
                    this.request.page = 1;
                    this.request.tab = this.tab;
                    this.items = [];
                    this.totalPages = 0;
                    this.totalItems = 0;
                    EventBus.$emit('changePage', { page: this.request.page })
                    this.update(false, false)
                }
            }
        },
        methods: {
           update(scroll = false, setQs = true) {
                var self = this
                self.$http.post('/umbraco/surface/search/cso', self.request)
                    .then(({ data }) => {
                        if (setQs) {
                            var s = {
                                q: this.request.q
                            }
                            if (this.request.page > 1) {
                                s.page = this.request.page
                            }
                            self.setQs(qs.stringify(s, { arrayFormat: 'comma', encode: false, addQueryPrefix: true }))
                        }
                        self.items = data.items
                        self.totalPages = data.totalPages
                        self.totalItems = data.totalItems
                        if (scroll) {
                            VueScrollTo.scrollTo('#results', 700)
                        }
                    })
                    .catch((error) => { })
            },
            restoreState() {
                var queryString = this.getQs()
                if (queryString) {
                    var qsObj = qs.parse(queryString, { comma: true, ignoreQueryPrefix: true })
                    if (qsObj.q) this.request.q = qsObj.q;
                    if (qsObj.page) {
                        this.request.page = qsObj.page
                    }
                    else {
                        this.request.page = 1
                    }
                }
                else {
                    this.request.page = 1
                }
                EventBus.$emit('changePage', { page: this.request.page })
                this.update(false, false)
            }
            ,
            tallImage(image) {
                if (image.indexOf('width=') == -1) {
                    return true
                }
                return false
            }
        },
        mounted() {
            var self = this

            self.restoreState()

            EventBus.$on('pager', (data) => {
                self.request.page = data.page
                self.update(true)
            })
        }
    })

    window.addEventListener('popstate', function (event) {
        vm.restoreState()
    })
}