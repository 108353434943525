<template>
    <label class="switch" :class="checked ? ' set' : ''" :tabindex="tabIndex" @keyup.enter="toggleChecked" @click.prevent="toggleChecked">
        <input tabindex="-1" aria-hidden type="checkbox" v-model="checked"><span>{{ label }}</span>
    </label>
</template>

<script>
    import Vue from 'vue'

    export default {
        props: {
            label: {
                type: String
            },
            value: {
                type: String
            },
            checked: {
                type: Boolean,
                default: false
            },
            tabIndex: {
                type: Number,
                default: 0
            }
        },
        // data() {
        //     return {
        //         checked: this.initialChecked
        //     }
        // },
        computed: {
        },
        methods: {
            toggleChecked: function() {
                this.$emit('on-change', !this.checked, this.value || this.label);
            }
        },
        watch: {
            // checked: function(checked) {
            //     this.$emit('onChange', checked, this.value || this.label);
            // }
        },
        mounted() {
        }
    }
</script>
