var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dates", class: { open: _vm.open } }, [
    _c("div", { staticClass: "select", class: { open: _vm.open } }, [
      _c(
        "button",
        {
          staticClass: "select__head",
          attrs: {
            "aria-controls": "date-dropdown" + _vm._uid,
            "aria-expanded": !!_vm.open
          },
          on: {
            click: function($event) {
              _vm.open = !_vm.open
            }
          }
        },
        [_vm._v(_vm._s(_vm.title))]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "dates__dropdown",
        attrs: { id: "date-dropdown" + _vm._uid }
      },
      [
        _c("div", { staticClass: "dates__fieldset" }, [
          _c(
            "div",
            { staticClass: "dates__field field" },
            [
              !!_vm.open
                ? _c("flat-pickr", {
                    staticClass: "field__input",
                    attrs: { type: "text", placeholder: "Start" },
                    model: {
                      value: _vm.from,
                      callback: function($$v) {
                        _vm.from = $$v
                      },
                      expression: "from"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dates__field field" },
            [
              !!_vm.open
                ? _c("flat-pickr", {
                    staticClass: "field__input",
                    attrs: { type: "text", placeholder: "End" },
                    model: {
                      value: _vm.to,
                      callback: function($$v) {
                        _vm.to = $$v
                      },
                      expression: "to"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        !!_vm.open
          ? _c(
              "button",
              {
                staticClass: "dates__btn btn btn_sm",
                attrs: {
                  disabled: !_vm.allowDateSearch,
                  "aria-label": "Submit"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.submit($event)
                  }
                }
              },
              [_vm._v("Submit")]
            )
          : _vm._e(),
        _vm._v(" "),
        !!_vm.open
          ? _c(
              "button",
              {
                staticClass: "dates__btn btn btn_sm clear",
                attrs: { "aria-label": "Clear Dates" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.clear($event)
                  }
                }
              },
              [_vm._v("Clear Dates")]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }