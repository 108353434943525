var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section" }, [
    !_vm.loaded
      ? _c("div", { staticClass: "section__container container" }, [
          _c("div", { staticClass: "head" }, [
            _c("div", { staticClass: "head__type" }, [
              _vm._v(_vm._s(_vm.pretitle))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "head__title" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "feature" }, [
            _c(
              "a",
              { staticClass: "feature__preview" },
              [
                _c("v-lazy-image", {
                  staticClass: "feature__pic",
                  attrs: {
                    src: "/public/img/placeholder-783x472.gif",
                    "src-placeholder": "/public/img/placeholder-783x472.gif"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm._m(0)
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "posts" }, [
            _c("div", { staticClass: "posts__item" }, [
              _c(
                "a",
                { staticClass: "posts__preview" },
                [
                  _c("v-lazy-image", {
                    staticClass: "posts__pic",
                    attrs: {
                      src: "/public/img/placeholder-600x400.gif",
                      "src-placeholder": "/public/img/placeholder-600x400.gif"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(1)
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "posts__item" }, [
              _c(
                "a",
                { staticClass: "posts__preview" },
                [
                  _c("v-lazy-image", {
                    staticClass: "posts__pic",
                    attrs: {
                      src: "/public/img/placeholder-600x400.gif",
                      "src-placeholder": "/public/img/placeholder-600x400.gif"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(2)
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "posts__item" }, [
              _c(
                "a",
                { staticClass: "posts__preview" },
                [
                  _c("v-lazy-image", {
                    staticClass: "posts__pic",
                    attrs: {
                      src: "/public/img/placeholder-600x400.gif",
                      "src-placeholder": "/public/img/placeholder-600x400.gif"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(3)
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.items.length > 0 && _vm.loaded
      ? _c("div", { staticClass: "section__container container" }, [
          _c("div", { staticClass: "head" }, [
            _c("div", { staticClass: "head__type" }, [
              _vm._v(_vm._s(_vm.pretitle))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "head__title" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "feature" }, [
            _c(
              "a",
              {
                staticClass: "feature__preview",
                attrs: { href: _vm.featured.url }
              },
              [
                _c("v-lazy-image", {
                  key: _vm.featured.id,
                  staticClass: "feature__pic",
                  attrs: {
                    src: _vm.featured.featureImage,
                    "src-placeholder": "/public/img/placeholder-783x472.gif",
                    alt: _vm.featured.title
                  }
                }),
                _vm._v(" "),
                _vm.featured.formatIcon
                  ? _c("div", {
                      staticClass: "feature__play",
                      domProps: { innerHTML: _vm._s(_vm.featured.formatIcon) }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "feature__inner" }, [
              _c(
                "a",
                {
                  staticClass: "feature__link link",
                  attrs: {
                    href:
                      "/search/" +
                      _vm.featured.primaryTopicId +
                      "/" +
                      _vm.featured.slug
                  }
                },
                [_vm._v(_vm._s(_vm.featured.primaryTopic))]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "feature__title" }, [
                _c("a", { attrs: { href: _vm.featured.url } }, [
                  _vm._v(_vm._s(_vm.featured.title))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "feature__content" }, [
                _c("p", [_vm._v(_vm._s(_vm.featured.metaDescription))])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "feature__time" }, [
                _vm._v("— " + _vm._s(_vm.featured.friendlyLength))
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "posts" },
            _vm._l(_vm.items.slice(1), function(item) {
              return _c("div", { key: item.id, staticClass: "posts__item" }, [
                _c(
                  "a",
                  { staticClass: "posts__preview", attrs: { href: item.url } },
                  [
                    _c("v-lazy-image", {
                      staticClass: "posts__pic",
                      attrs: {
                        src: item.image,
                        "src-placeholder":
                          "/public/img/placeholder-600x400.gif",
                        alt: item.title
                      }
                    }),
                    _vm._v(" "),
                    item.formatIcon
                      ? _c("div", {
                          staticClass: "posts__play",
                          domProps: { innerHTML: _vm._s(item.formatIcon) }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "posts__inner" }, [
                  _c(
                    "a",
                    {
                      staticClass: "posts__link link",
                      attrs: {
                        href: "/search/" + item.primaryTopicId + "/" + item.slug
                      }
                    },
                    [_vm._v(_vm._s(item.primaryTopic))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "posts__title h6" }, [
                    _c("a", { attrs: { href: item.url } }, [
                      _vm._v(_vm._s(item.title))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "posts__time" }, [
                    _vm._v("— " + _vm._s(item.friendlyLength))
                  ])
                ])
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "more" },
            [
              _c("expbutton", {
                staticClass: "more__btn",
                attrs: { url: _vm.url, cta: _vm.cta }
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "feature__inner" }, [
      _c("div", { staticClass: "feature__content" }),
      _vm._v(" "),
      _c("div", { staticClass: "feature__time" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "posts__inner" }, [
      _c("div", { staticClass: "posts__title h6" }),
      _vm._v(" "),
      _c("div", { staticClass: "posts__time" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "posts__inner" }, [
      _c("div", { staticClass: "posts__title h6" }),
      _vm._v(" "),
      _c("div", { staticClass: "posts__time" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "posts__inner" }, [
      _c("div", { staticClass: "posts__title h6" }),
      _vm._v(" "),
      _c("div", { staticClass: "posts__time" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }