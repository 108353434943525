var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal micromodal-slide",
      class: { "is-open": _vm.isOpen },
      attrs: { "aria-hidden": _vm.isOpen ? "false" : "true" }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal__overlay",
          attrs: { tabindex: "-1" },
          on: { click: _vm.close }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal__container",
              class: [_vm.type],
              attrs: { role: "dialog", "aria-modal": "true" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.dummy($event)
                }
              }
            },
            [
              _c("header", { staticClass: "modal__header" }, [
                _c("button", {
                  staticClass: "modal__close",
                  attrs: { "aria-label": "Close modal" },
                  on: { click: _vm.close }
                })
              ]),
              _vm._v(" "),
              _vm.type == _vm.types.DEFAULT
                ? _c(
                    "main",
                    { staticClass: "modal__content" },
                    [_vm._t("body")],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == _vm.types.VIDEO
                ? _c("main", {
                    staticClass: "modal__video",
                    domProps: { innerHTML: _vm._s(_vm.html) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showFooter
                ? _c(
                    "footer",
                    { staticClass: "modal__footer" },
                    [
                      _vm._t("button"),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "modal__btn modal__btn-secondary",
                          attrs: { "aria-label": "Close this dialog window" },
                          on: { click: _vm.close }
                        },
                        [_vm._v("Close")]
                      )
                    ],
                    2
                  )
                : _vm._e()
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }