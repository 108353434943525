<template>
    <a class="btn" :href="url" v-on:mouseover="over" v-on:mouseleave="leave">
        <div class="btn__circle" ref="circle"></div>
        <div class="btn__title">{{cta}}</div>
    </a>
</template>
<script>
    import Vue from 'vue'

    export default {
        props: ['url', 'cta'],
        data() {
            return {
                relX: 0,
                relY: 1
            }
        },
        methods: {
            over(e) {
                this.find_mouse(e)

                this.$refs.circle.classList.remove('desplode-circle')
                this.$refs.circle.classList.add('explode-circle')                
            },
            leave(e) {
                this.find_mouse(e)

                this.$refs.circle.classList.remove('explode-circle')
                this.$refs.circle.classList.add('desplode-circle')
            },
            find_mouse(e) {
                var rect = this.$el.getBoundingClientRect()
                this.relX = e.clientX - rect.left
                this.relY = e.clientY - rect.top
                this.$refs.circle.style.left = this.relX + 'px'
                this.$refs.circle.style.top = this.relY + 'px'
            }
        }
    }
</script>