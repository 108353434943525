var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasItems
    ? _c(
        "div",
        {
          staticClass: "section section-overflow-hidden",
          class: _vm.tint ? "section_color" : "section_pt0"
        },
        [
          _c("div", { staticClass: "section__container container" }, [
            _c("div", { staticClass: "heading" }, [
              _c("h2", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
              _vm._v(" "),
              _vm.calendarUrl
                ? _c(
                    "a",
                    { staticClass: "link", attrs: { href: _vm.calendarUrl } },
                    [_vm._v("View full calendar")]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "playlists__carousel flickity-enabled is-draggable"
              },
              [
                _c("button", {
                  ref: "prev",
                  staticClass: "playlists__prev cursor__trigger prev",
                  attrs: { "aria-label": "Previous Image" },
                  on: {
                    click: function($event) {
                      return _vm.previous()
                    }
                  }
                }),
                _vm._v(" "),
                _c("button", {
                  ref: "next",
                  staticClass: "playlists__next cursor__trigger",
                  attrs: { "aria-label": "Next Image" },
                  on: {
                    click: function($event) {
                      return _vm.next()
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "flickity",
                  {
                    ref: "flickity",
                    staticClass: "flickity posts posts_scroll",
                    attrs: { options: _vm.flickityOptions }
                  },
                  _vm._l(_vm.items, function(post) {
                    return _c("Post", {
                      key: post.id,
                      attrs: { post: post, sponsors: true }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "playlists__progress" }, [
              _c("div", {
                ref: "progress",
                staticClass: "playlists__position",
                staticStyle: { width: "0%" }
              })
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }