<template>
    <div class="dates" v-bind:class="{open : open}">
        <div class="select" v-bind:class="{open : open}">
            <button :aria-controls="'date-dropdown'+_uid" :aria-expanded="!!open" class="select__head" v-on:click="open = !open">{{title}}</button>
        </div>
        <div class="dates__dropdown" :id="'date-dropdown'+_uid">
            <div class="dates__fieldset">
                <div class="dates__field field">
                    <flat-pickr v-if="!!open" class="field__input" v-model="from" type="text" placeholder="Start"></flat-pickr>
                </div>
                <div class="dates__field field">
                    <flat-pickr v-if="!!open" class="field__input" v-model="to" type="text" placeholder="End"></flat-pickr>
                </div>
            </div>
            <button v-if="!!open" class="dates__btn btn btn_sm" v-on:click.prevent="submit" v-bind:disabled="!allowDateSearch" aria-label="Submit">Submit</button>
            <button v-if="!!open" class="dates__btn btn btn_sm clear" v-on:click.prevent="clear" aria-label="Clear Dates">Clear Dates</button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { EventBus } from '../vues/event-bus'
    import flatPickr from 'vue-flatpickr-component'
    import moment from 'moment'

    export default {
        props: ['initialto', 'initialfrom'],
        components: {
            flatPickr,
        },
        data() {
            return {
                open: false,
                from: '',
                to: '',
                request: {
                    from: '',
                    to: ''
                }
            }
        },
        computed: {
            allowDateSearch() {
                return this.from != null && moment(this.from).isValid()
                    && this.to != null && moment(this.to).isValid()
            },
            formattedFrom() {
                return moment(this.request.from).format('MMM DD YYYY')
            },
            formattedTo() {
                return moment(this.request.to).format('MMM DD YYYY')
            },
            title() {
                if (this.request.from != '' && this.request.to != '') {
                    return `${this.formattedFrom} - ${this.formattedTo}`
                }
                else {
                    return 'Date'
                }
            }
        },
        methods: {
            submit() {
                var self = this
                self.request.from = self.from
                self.request.to = self.to
                self.open = false
                EventBus.$emit('daterange', { from: self.request.from, to: self.request.to })
            },
            clear() {
                this.from = ''
                this.to = ''
                this.request.from = ''
                this.request.to = ''
                this.open = false
                EventBus.$emit('daterange', { from: this.request.from, to: this.request.to })
            }
        },
        mounted() {
            var self = this
            EventBus.$on('closeDateRangePicker', () => {
                self.open = false
            })
            EventBus.$on('setDateRangePicker', (data) => {
                self.from = data.from
                self.request.from = data.from
                self.to = data.to
                self.request.to = data.to
            })
        }
    }
</script>