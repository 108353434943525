<template>
    <div v-if="hasItems" class="section section-overflow-hidden" :class="(tint ? 'section_color' : 'section_pt0')">
        <div class="section__container container">

            <div class="heading">
              <h2 class="title">{{title}}</h2>
              <a v-if="calendarUrl" class="link" :href="calendarUrl">View full calendar</a>
            </div>

            <div class="playlists__carousel flickity-enabled is-draggable">

                <button ref="prev" class="playlists__prev cursor__trigger prev" @click="previous()" aria-label="Previous Image"></button>
                <button ref="next" class="playlists__next cursor__trigger" @click="next()" aria-label="Next Image"></button>

                <flickity class="flickity posts posts_scroll" ref="flickity" :options="flickityOptions">
                    <Post v-for="post in items" :key="post.id" :post="post" :sponsors="true" />
                </flickity>
            </div>

            <div class="playlists__progress">
                <div ref="progress" class="playlists__position" style="width: 0%;"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Flickity from 'vue-flickity';
    import Post from './Post.vue';
    import { FlickityA11yFix } from '../utils';


    export default {
        props: ['title', 'tint', 'showevents', 'tags', 'pinned', 'condition', 'exclude', 'calendar-url'],
        data() {
            return {
                items: [],
                flickityOptions: {
                    prevNextButtons: false,
                    pageDots: false,
                    wrapAround: true,
                    freeScroll: false,
                    imagesLoaded: true,
                    autoPlay: false,
                    lazyLoad: 2,
                    cellAlign: 'left',
                    on: {
                        ready: function () {
                            FlickityA11yFix(this.element);
                        },
                        change: function () {
                            FlickityA11yFix(this.element);
                        }
                    }
                }
            }
        },
        components: {
            Post,
            Flickity
        },
        computed: {
            hasItems() {
                return (this.countItems > 0);
            },
            countItems() {
                if (this.items) {
                    return this.items.length;
                }
                return 0;
            }
        },
        methods: {
            next() {
                this.$refs.flickity.next(true);
            },
            previous() {
                this.$refs.flickity.previous(true);
            },
            flickityChange(index) {
                this.$refs.progress.style.width = (((index + 1) / this.items.length) * 100) + '%';
            },
            update() {
                var pinnedIds = [];
                if (this.pinned != null) {
                    pinnedIds = this.pinned.map((id) => {
                        return parseInt(id);
                    })
                }

                 var excludeIds = [];
                if (this.exclude != null) {
                    excludeIds = this.exclude.map((id) => {
                        return parseInt(id);
                    })
                }

                var request = {
                    count: this.showevents,
                    tags: this.tags,
                    pinned: pinnedIds,
                    condition: this.condition,
                    exclude: excludeIds
                }
                this.$http.post('/umbraco/surface/events/search', request)
                    .then(({ data }) => {
                        this.items = data;
                        Vue.nextTick(() => {
                            this.$refs.progress.style.width = ((1 / data.length) * 100) + '%'
                        });
                    })
                    .catch((error) => {
                        //console.error("Error loading related events");
                        //console.dir(error);
                     })
            },
            attatchFlicketyOnChange() {
                if (this.$refs.flickity) {

                    this.$refs.flickity.on('change', this.flickityChange);
                    this.customBigCursor(this.$el);

                } else {
                    setTimeout(() => {
                        this.attatchFlicketyOnChange();
                    }, 50);

                    // no idea why nextTick doesn't work
                    // Vue.nextTick(() => {
                    //     this.attatchFlicketyOnChange();
                    // });
                }
            },
            customBigCursor(element) {
                if (element === undefined) element = document;

                const wcursor = document.getElementById('wap__cursor')
                const wtriggers = element.getElementsByClassName('cursor__trigger')

                if (wtriggers.length) {
                    for (var i = 0; i < wtriggers.length; i++) {
                        wtriggers[i].addEventListener('mouseenter', (e) => {
                            wcursor.classList.add('visible')
                            if (e.target.classList.contains('prev')) { wcursor.classList.add('previous') }
                            else { wcursor.classList.remove('previous') }
                        })
                        wtriggers[i].addEventListener('mouseleave', () => {
                            wcursor.classList.remove('visible')
                        })
                        wtriggers[i].addEventListener('mousemove', (e) => {
                            wcursor.style.top = (e.clientY - 50) + 'px'
                            wcursor.style.left = (e.clientX - 50) + 'px'
                        })
                    }
                }
            }
        },
        mounted() {
            this.update();
            this.attatchFlicketyOnChange();
        },
        beforeUnmount() {
            this.$refs.flickity.off('change', this.flickityChange);
        }
    }
</script>
