<template>
    <div class="filters filters__tags" v-clickout="close" v-cloak>
        <div class="filters__title has-groups">Filter by</div>
        <div class="filters__trigger"><span class="red" @click="active = true">Open Filters <font v-if="values.length">({{values.length}})</font></span><span @click="clear" v-if="values.length">Clear</span></div>
        <div class="filters__group" :class="{ active:active }">
            <div class="filters__group_close"><span @click="active = false">&times;</span></div>
            <div class="filters__group_controls">
                <tagfilter v-for="item in groups" :tags="item.tags" :group="item.name" :title="item.title" :key="item.name" @input="changed" @added="changed"></tagfilter>
            </div>
            <div class="filters__group_labels">
                <div class="fgl_i" v-for="item in values" :key="item.id" @click.prevent="remove(item.id, item.group)">{{ item.text }}<span>&times;</span></div>
                <div class="fgl_i clear" v-if="values.length > 1" @click.prevent="clear">Clear All<span>&times;</span></div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { EventBus } from '../vues/event-bus'

    export default {
        props: ['groups'],
        data() {
            return {
                values: [],
                active:false
            }
        },
        computed: {},
        methods: {
            changed(val, key, isEvent) {
                var items = this.values.filter((v) => { return v.group != key })

                if (val.length) {
                    this.values = items.concat(val)
                }
                else {
                    this.values = items
                }
                if (isEvent) { EventBus.$emit('updatedTagFilters', this.values) }
            },
            remove(itemId, itemGroup) {
                EventBus.$emit('clearTag', itemId, itemGroup)
            },
            clear() {
                EventBus.$emit('clearTagFilters')
                this.values = []
                EventBus.$emit('updatedTagFilters', this.values)
            },
            close() {
                if (this.active) {
                    this.active = false
                }
            }
        },
        mounted() {
            EventBus.$on('setTagGroups', (val) => {
                EventBus.$emit('clearTagFilters')
                this.values = []
                val.forEach((el) => {
                    EventBus.$emit('selectTag', el.tagId, el.group)
                })
            })
            //if (this.selected) {
            //    this.values = this.selected
            //}
        }
    }
</script>