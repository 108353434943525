<template>
    <div class="contrib" v-if="authors.length > 0">
        <div class="contrib__title">Featured contributors</div>
        <div class="contrib__list">
            <div class="contrib__item" v-for="author in authors">
                <div class="contrib__preview">
                    <img class="contrib__pic" :src="author.image" :alt="author.name" v-if="author.image">
                    <span class="contrib__intials" v-else>{{author.initials}}</span>
                </div>
                <div class="contrib__name">{{author.name}}</div><a class="contrib__link link" :href="author.url">See all posts</a>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    export default {
        props: ['authorids'],
        data() {
            return {
                authors: []
            }
        },
        watch: {
            authorids: function (newVal, oldVal) { 
                this.update()
            }
        },
        methods: {
            update() {
                var self = this
                var request = {
                    authorIds: self.authorids
                }
                self.$http.post('/umbraco/surface/catalog/contributors', request)
                    .then(({ data }) => {
                        self.authors = data
                    })
                    .catch((error) => { })
            }
        }
    }
</script>