<template>
    <div class="dates" v-bind:class="{open : open}">
        <div class="select" v-bind:class="{open : open}">
            <button :aria-controls="'length-dropdown'+_uid" :aria-expanded="!!open" class="select__head" v-on:click="open = !open">{{title}}</button>
        </div>
        <div class="dates__dropdown" :id="'length-dropdown'+_uid">
            <p class="text-center">Find content around this many minutes in length.</p>
            <vue-slider :use-keyboard="true" v-if="!!open" :dot-attrs="{ 'aria-label': 'Select content length' }" v-model="value" v-bind="options" :tooltip-formatter="'{value} min'"/>
            <button v-if="!!open" class="dates__btn btn btn_sm" v-on:click.prevent="submit" aria-label="Submit">Submit</button>
            <button v-if="!!open" class="dates__btn btn btn_sm clear" v-on:click.prevent="clear" aria-label="Clear">Clear</button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { EventBus } from '../vues/event-bus'
    import VueSlider from 'vue-slider-component'

    export default {
        props: ['initialvalue'],
        components: {
            VueSlider
        },
        data() {
            return {
                options: {
                    dotSize: 14,
                    width: 'auto',
                    height: 4,
                    contained: false,
                    direction: 'ltr',
                    data: null,
                    dataLabel: 'label',
                    dataValue: 'value',
                    min: 0,
                    max: 120,
                    interval: 1,
                    disabled: false,
                    clickable: true,
                    duration: 0.5,
                    adsorb: false,
                    lazy: false,
                    tooltip: 'always',
                    tooltipPlacement: 'top',
                    tooltipFormatter: void 0,
                    useKeyboard: false,
                    keydownHook: null,
                    dragOnClick: false,
                    enableCross: true,
                    fixed: false,
                    minRange: void 0,
                    maxRange: void 0,
                    order: true,
                    marks: false,
                    dotOptions: void 0,
                    dotAttrs: void 0,
                    process: true,
                    dotStyle: void 0,
                    railStyle: void 0,
                    processStyle: void 0,
                    tooltipStyle: void 0,
                    stepStyle: void 0,
                    stepActiveStyle: void 0,
                    labelStyle: void 0,
                    labelActiveStyle: void 0,
                },
                open: false,
                value: 0,
                request: {
                    length: 0
                }
            }
        },
        computed: {
            title() {
                if (this.request.length) {
                    return '+/-' + this.request.length + ' mins'
                }
                else {
                    return 'Length'
                }
            }
        },
        methods: {
            submit() {
                this.open = false
                this.request.length = this.value
                EventBus.$emit('length', { length: this.request.length })
            },
            clear() {
                this.value = 0
                this.request.length = 0
                this.open = false
                EventBus.$emit('length', { length: this.request.length })
            }
        },
        mounted() {
            var self = this
            EventBus.$on('closeLengthPicker', () => {
                self.open = false
            })
            EventBus.$on('setLengthPicker', (data) => {
                self.value = data
                self.request.length = data
            })
        }
    }
</script>