var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items.length > 0
    ? _c("div", { staticClass: "similar" }, [
        _c("div", { staticClass: "similar__title" }, [
          _vm._v(_vm._s(_vm.displayTitle))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "similar__posts posts" },
          _vm._l(_vm.items, function(item, index) {
            return _c("div", { key: index, staticClass: "posts__item" }, [
              _c(
                "a",
                { staticClass: "posts__preview", attrs: { href: item.url } },
                [
                  _c("v-lazy-image", {
                    staticClass: "posts__pic",
                    class: { posts__pic__tall: _vm.tallImage(item.image) },
                    attrs: {
                      src: item.image,
                      "src-placeholder": "/public/img/placeholder-600x400.gif",
                      alt: item.title
                    }
                  }),
                  _vm._v(" "),
                  item.formatIcon
                    ? _c("div", {
                        staticClass: "posts__play",
                        domProps: { innerHTML: _vm._s(item.formatIcon) }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "posts__inner" }, [
                _c(
                  "a",
                  {
                    staticClass: "posts__link link",
                    attrs: {
                      href: "/search/" + item.primaryTopicId + "/" + item.slug
                    }
                  },
                  [_vm._v(_vm._s(item.primaryTopic))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "posts__title h6" }, [
                  _c("a", { attrs: { href: item.url } }, [
                    _vm._v(_vm._s(item.title))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "posts__time" }, [
                  _vm._v("— " + _vm._s(item.friendlyLength))
                ])
              ])
            ])
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }