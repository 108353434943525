<template>
    <svg viewBox="0 0 100 100" class="burger-menu" :class="{'burger-menu-open': open}" :height="height" :width="width">
      <path ref="path1" d="M 92,24 L 8,24">
        <animate
        ref="animate1"
          attributeName="d"
          :dur="this.duration"
          repeatCount="1"
          fill="freeze"
        />
      </path>
      <path ref="path2" d="M 92,50 H 50 8">
        <animate
        ref="animate2"
          attributeName="d"
          :dur="this.duration"
          repeatCount="1"
          fill="freeze"
        />
      </path>
      <path ref="path3" d="M 92,76 L 8,76">
        <animate
        ref="animate3"
          attributeName="d"
          :dur="this.duration"
          repeatCount="1"
          fill="freeze"
        />
      </path>
    </svg>
</template>

<script>
    import Vue from 'vue'

    export default {
        props: {
            height: {
                type: Number,
                default: 32
            },
            width: {
                type: Number,
                default: 32
            },
            open: {
                type: Boolean,
                default: false
            },
            duration: {
                type: String,
                default: "0.2s"
            },
        },
        data() {
            return {
                paths1: [
                    "M 92,24 L 8,24",
                    "M 88,37 L 12,24",
                    "M 84,50 L 16,24",
                    "M 80,63 L 20,24",
                    "M 76,76 L 24,24"
                ],
                paths2: [
                    "M 92,50 H 50 8",
                    "M 75.5,50 H 50 24.5",
                    "M 67,50 H 50 33",
                    "M 58.5,50 H 50 41.5",
                    "M 50.001,50 H 50 49.99"
                ],
                paths3: [
                    "M 92,76 L 8,76",
                    "M 88,63 L 12,76",
                    "M 84,50 L 16,76",
                    "M 80,37 L 20,76",
                    "M 76,24 L 24,76"
                ]
            }
        },
        computed: {
            IsOpen() {
                return !!this.open;
            }
        },
        watch: {
            IsOpen(newValue, oldValue) {
                if (newValue && !oldValue) {
                    this.$refs.animate1.setAttribute("values", this.paths1.join("; "));
                    this.$refs.animate2.setAttribute("values", this.paths2.join("; "));
                    this.$refs.animate3.setAttribute("values", this.paths3.join("; "));
                } else if (!newValue && oldValue) {
                    this.$refs.animate1.setAttribute(
                    "values",
                    this.paths1.slice().reverse().join("; ")
                    );
                    this.$refs.animate2.setAttribute(
                    "values",
                    this.paths2.slice().reverse().join("; ")
                    );
                    this.$refs.animate3.setAttribute(
                    "values",
                    this.paths3.slice().reverse().join("; ")
                    );
                }

                this.$refs.animate1.beginElement();
                this.$refs.animate2.beginElement();
                this.$refs.animate3.beginElement();
                }
        },
        mounted() {
            this.$refs.path1.setAttribute("d", this.paths1[0]);
            this.$refs.path2.setAttribute("d", this.paths2[0]);
            this.$refs.path3.setAttribute("d", this.paths3[0]);
        }
    }
</script>
