var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.performances && _vm.performances.length
    ? _c(
        "div",
        { staticClass: "schedule" },
        [
          _c("div", { staticClass: "schedule_row shead" }, [
            _c("div", { staticClass: "schedule_col sdate" }, [_vm._v("Date")]),
            _vm._v(" "),
            _c("div", { staticClass: "schedule_col stime" }, [_vm._v("Time")]),
            _vm._v(" "),
            _vm.hasTickets
              ? _c("div", { staticClass: "schedule_col saction stan" }, [
                  _vm._v("Tickets")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPackages
              ? _c(
                  "div",
                  {
                    staticClass: "schedule_col saction",
                    class: { sgrey: !_vm.hasTickets }
                  },
                  [
                    _c("span", { staticClass: "sattn" }, [
                      _vm._v("Subscribe & Save")
                    ]),
                    _vm._v("\n            Packages\n        ")
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm._l(_vm.performances, function(item) {
            return _c("div", { staticClass: "schedule_row" }, [
              _c("div", { staticClass: "schedule_col sdate" }, [
                _vm._v(_vm._s(item.localDateFormat))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "schedule_col stime" }, [
                _vm._v(_vm._s(item.localTimeFormat))
              ]),
              _vm._v(" "),
              _vm.hasTickets
                ? _c("div", { staticClass: "schedule_col saction stan" }, [
                    _c("span", [_vm._v("Tickets")]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn_sm btn_sched",
                        attrs: {
                          href: _vm.overrideBooking
                            ? _vm.overrideBookingUrl
                            : item.ticketUrl
                        }
                      },
                      [
                        _c("div", { staticClass: "btn__circle" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "btn__title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.overrideBooking
                                ? _vm.overrideBookingName
                                : _vm.bookingName
                            )
                          )
                        ])
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPackages
                ? _c("div", { staticClass: "schedule_col saction" }, [
                    _c("span", [_vm._v("Packages")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "schedule_options",
                        class: { open: item.id == _vm.activeId }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "so_ttl",
                            on: {
                              click: function($event) {
                                return _vm.togglePackage(item.id)
                              }
                            }
                          },
                          [_vm._v("Options")]
                        ),
                        _vm._v(" "),
                        _c("packageoptions", {
                          attrs: {
                            "performance-id": item.id,
                            active: item.id == _vm.activeId
                          },
                          on: { "on-all-concerts": _vm.packageDetails }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          }),
          _vm._v(" "),
          _c("micromodal", {
            ref: "pkgmodal",
            attrs: { "disable-scroll": true, "show-footer": true },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "schedule_modal" }, [
                        _vm.packageLoaded
                          ? _c("div", { staticClass: "schedule_modal_pkg" }, [
                              _c("div", { staticClass: "smp_title" }, [
                                _vm._v(_vm._s(_vm.modalPkg.title))
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "smp_desc",
                                domProps: {
                                  innerHTML: _vm._s(_vm.modalPkg.description)
                                }
                              }),
                              _vm._v(" "),
                              _vm.modalPkg.concerts.length
                                ? _c(
                                    "div",
                                    { staticClass: "smp_concerts" },
                                    _vm._l(_vm.modalPkg.concerts, function(
                                      item
                                    ) {
                                      return _c(
                                        "div",
                                        { staticClass: "smp_concert" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "smp_concert_img" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    item.productionSeasonListImageUrl,
                                                  alt:
                                                    item.productionSeasonListImageAlt
                                                }
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "smp_concert_content"
                                            },
                                            [
                                              item.concertTypes &&
                                              item.concertTypes.length > 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "smp_concert_type"
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.concertTypes
                                                              ? item
                                                                  .concertTypes[0]
                                                              : ""
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "smp_concert_name"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.description)
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "smp_concert_date"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatDate(
                                                        item.csoLocalTime
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.packageLoaded
                          ? _c("div", { staticClass: "loading" }, [
                              _c("div", { staticClass: "loading_bar" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "loading_text" }, [
                                _vm._v("Loading concerts...")
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.packageLoaded && !_vm.modalPkg.concerts.length
                          ? _c("div", { staticClass: "schedule_modal_nopkg" }, [
                              _vm._v(
                                "\n                    No concerts found. Please "
                              ),
                              _c("strong", [
                                _c("a", { attrs: { href: _vm.modalPkg.url } }, [
                                  _vm._v("visit this link")
                                ])
                              ]),
                              _vm._v(" for further details.\n                ")
                            ])
                          : _vm._e()
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "button",
                  fn: function() {
                    return [
                      _c("a", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.packageLoaded && _vm.modalPkg.concerts.length,
                            expression:
                              "packageLoaded && modalPkg.concerts.length"
                          }
                        ],
                        staticClass: "modal__btn modal__btn-primary",
                        attrs: {
                          href: _vm.modalPkg.url,
                          "aria-label": "Continue"
                        },
                        domProps: { innerHTML: _vm._s(_vm.modalPkg.button) }
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              554562118
            )
          }),
          _vm._v(" "),
          _vm.activeId > 0
            ? _c("div", {
                staticClass: "schedule_underlay",
                on: {
                  click: function($event) {
                    _vm.activeId = 0
                  }
                }
              })
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }