<template>
    <div v-if="hasItems" class="section" :class="(tint ? 'section_color' : 'section_pt0')">
        <div class="section__container container">
            <div class="section__title">{{title}}</div>

            <div class="posts posts_grid events_grid" :class="(countItems > 3 ? 'posts_four' : '')">
                <Post v-for="post in items" :key="post.id" :post="post" />
            </div>
        </div>
    </div>    
</template>

    
<script>
    import Vue from 'vue'
    import Post from './Post.vue';
    
    export default {
        props: ['title', 'tint', 'showevents', 'tags', 'pinned', 'condition', 'exclude'],
        data() {
            return {
                items: []
            }
        },
        components: {
            Post,
        },
        computed: {
            hasItems() {
                return (this.countItems > 0);
            },
            countItems() {
                if (this.items) {
                    return this.items.length;
                }
                return 0;
            }
        },
        methods: {
            update() {
                var pinnedIds = [];
                if (this.pinned != null) {
                    pinnedIds = this.pinned.map((id) => {
                        return parseInt(id);
                    })
                }

                 var excludeIds = [];
                if (this.exclude != null) {
                    excludeIds = this.exclude.map((id) => {
                        return parseInt(id);
                    })
                }

                var request = {
                    count: this.showevents,
                    tags: this.tags,
                    pinned: pinnedIds,
                    condition: this.condition,
                    exclude: excludeIds
                }
                this.$http.post('/umbraco/surface/events/search', request)
                    .then(({ data }) => {
                        this.items = data;                        
                    })
                    .catch((error) => {
                        console.error("Error loading related events");
                        console.dir(error);
                     })
            }
        },
        mounted() {            
            this.update()
        }
    }
</script>
