<template>
    <div class="section">
        <div class="section__container container" v-if="!loaded">
            <div class="head">
                <div class="head__type">{{pretitle}}</div>
                <div class="head__title">{{title}}</div>
            </div>
            <div class="feature">
                <a class="feature__preview">
                    <v-lazy-image class="feature__pic" src="/public/img/placeholder-783x472.gif" src-placeholder="/public/img/placeholder-783x472.gif"></v-lazy-image>
                </a>
                <div class="feature__inner">
                    <div class="feature__content">
                    </div>
                    <div class="feature__time"></div>
                </div>
            </div>
            <div class="posts">
                <div class="posts__item">
                    <a class="posts__preview">
                        <v-lazy-image class="posts__pic" src="/public/img/placeholder-600x400.gif" src-placeholder="/public/img/placeholder-600x400.gif"></v-lazy-image>
                    </a>
                    <div class="posts__inner">
                        <div class="posts__title h6"></div>
                        <div class="posts__time"></div>
                    </div>
                </div>
                <div class="posts__item">
                    <a class="posts__preview">
                        <v-lazy-image class="posts__pic" src="/public/img/placeholder-600x400.gif" src-placeholder="/public/img/placeholder-600x400.gif"></v-lazy-image>
                    </a>
                    <div class="posts__inner">
                        <div class="posts__title h6"></div>
                        <div class="posts__time"></div>
                    </div>
                </div>
                <div class="posts__item">
                    <a class="posts__preview">
                        <v-lazy-image class="posts__pic" src="/public/img/placeholder-600x400.gif" src-placeholder="/public/img/placeholder-600x400.gif"></v-lazy-image>
                    </a>
                    <div class="posts__inner">
                        <div class="posts__title h6"></div>
                        <div class="posts__time"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section__container container" v-if="items.length > 0 && loaded">
            <div class="head">
                <div class="head__type">{{pretitle}}</div>
                <div class="head__title">{{title}}</div>
            </div>
            <div class="feature">
                <a class="feature__preview" :href="featured.url">
                    <v-lazy-image class="feature__pic" :src="featured.featureImage" src-placeholder="/public/img/placeholder-783x472.gif" :alt="featured.title" v-bind:key="featured.id"></v-lazy-image>
                    <div class="feature__play" v-if="featured.formatIcon" v-html="featured.formatIcon"></div>
                </a>
                <div class="feature__inner">
                    <a class="feature__link link" :href="'/search/'+featured.primaryTopicId+'/'+featured.slug">{{featured.primaryTopic}}</a>
                    <div class="feature__title"><a :href="featured.url">{{featured.title}}</a></div>
                    <div class="feature__content">
                        <p>{{featured.metaDescription}}</p>
                    </div>
                    <div class="feature__time">— {{featured.friendlyLength}}</div>
                </div>
            </div>
            <div class="posts">
                <div class="posts__item" v-for="item in items.slice(1)" v-bind:key="item.id">
                    <a class="posts__preview" :href="item.url">
                        <v-lazy-image class="posts__pic" :src="item.image" src-placeholder="/public/img/placeholder-600x400.gif" :alt="item.title"></v-lazy-image>
                        <div class="posts__play" v-if="item.formatIcon" v-html="item.formatIcon"></div>
                    </a>
                    <div class="posts__inner">
                        <a class="posts__link link" :href="'/search/'+item.primaryTopicId+'/'+item.slug">{{item.primaryTopic}}</a>
                        <div class="posts__title h6"><a :href="item.url">{{item.title}}</a></div>
                        <div class="posts__time">— {{item.friendlyLength}}</div>
                    </div>
                </div>
            </div>
            <div class="more">
                <expbutton :url="url" :cta="cta" class="more__btn"></expbutton>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    export default {
        props: ['contentgroupid', 'pretitle', 'title', 'url', 'cta'],
        data() {
            return {
                items: [],
                loaded: false
            }
        },
        computed: {
            featured() {
                return this.items[0]
            }
        },
        mounted() {
            var self = this
            var request = {
                contentGroupId: this.contentgroupid,
                page: 1,
                pageSize: 4,
                format: 'All',
                from: '',
                to: '',
                length: 0
            }
            self.$http.post('/umbraco/surface/catalog/contentGroup', request)
                .then(({ data }) => {
                    self.items = data.items
                    self.loaded = true
                })
                .catch((error) => {
                    self.loaded = true
                })
        }
    }
</script>