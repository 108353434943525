var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasItems
    ? _c(
        "div",
        {
          staticClass: "section",
          class: _vm.tint ? "section_color" : "section_pt0"
        },
        [
          _c("div", { staticClass: "section__container container" }, [
            _c("div", { staticClass: "section__title" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "posts posts_grid events_grid",
                class: _vm.countItems > 3 ? "posts_four" : ""
              },
              _vm._l(_vm.items, function(post) {
                return _c("Post", { key: post.id, attrs: { post: post } })
              }),
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }