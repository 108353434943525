import Vue from '../config.js'
import VueScrollTo from 'vue-scrollto'
import { EventBus } from './event-bus'

if (document.getElementById('calendar-vm')) {

    var vm = new Vue({
        el: '#calendar-vm',
        data: {
            calendarRefData: CalendarRefData,
            overlayOpen: false
        },
        computed: {
        },
        methods: {
            setOverlay(value) {
                this.overlayOpen = value;
            }
        },
        mounted() {          
            this.$nextTick(() => {
                this.$el.parentElement.classList.remove("prerender");
            });            
        }
    })
}