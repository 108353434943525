var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasItems
    ? _c(
        "div",
        {
          staticClass: "section",
          class: _vm.tint ? "section_color" : "section_pt0"
        },
        [
          _c("div", { staticClass: "section__container container" }, [
            _c("div", { staticClass: "head" }, [
              _vm.subtitle
                ? _c("div", { staticClass: "head__type" }, [
                    _vm._v(_vm._s(_vm.subtitle))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.title
                ? _c("div", { staticClass: "head__title" }, [
                    _vm._v(_vm._s(_vm.title))
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "posts posts_default" },
              _vm._l(_vm.items, function(item) {
                return _c("div", { key: item.id, staticClass: "posts__item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "posts__preview",
                      attrs: { href: item.url }
                    },
                    [
                      _c("v-lazy-image", {
                        key: item.id,
                        staticClass: "posts__pic",
                        attrs: {
                          src: item.image,
                          "src-placeholder":
                            "/public/img/placeholder-783x472.gif",
                          alt: item.title
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "posts__inner" }, [
                    _c("div", { staticClass: "posts__title h6" }, [
                      _c("a", { attrs: { href: item.url } }, [
                        _vm._v(_vm._s(item.title))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "posts__content" }, [
                      _vm._v(_vm._s(item.metaDescription))
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "posts__link link",
                        attrs: { href: item.url }
                      },
                      [_vm._v("Explore more")]
                    )
                  ])
                ])
              }),
              0
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }