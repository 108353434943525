<template>
    <div v-if="hasItems" class="section" :class="(tint ? 'section_color' : 'section_pt0')">
        <div class="section__container container">
            <div class="head">
                <div v-if="subtitle" class="head__type">{{ subtitle }}</div>
                <div v-if="title" class="head__title">{{ title }}</div>
            </div>

            <div class="posts posts_default">
                <div class="posts__item" v-for="item in items" :key="item.id">
                    <a class="posts__preview" :href="item.url">
                        <v-lazy-image class="posts__pic" :src="item.image" src-placeholder="/public/img/placeholder-783x472.gif" :alt="item.title" v-bind:key="item.id" />
                    </a>
                    <div class="posts__inner">
                        <div class="posts__title h6">
                            <a :href="item.url">{{item.title}}</a>
                        </div>
                        <div class="posts__content">{{ item.metaDescription }}</div>
                        <a class="posts__link link" :href="item.url">Explore more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    export default {
        props: ['pinned', 'topic', 'contenttype', 'ensemble', 'artist', 'conductor', 'composer', 'genre', 'title', 'limit', 'tint', 'subtitle'],
        data() {
            return {
                items: []
            }
        },
        computed: {
            displayTitle() {
                if (this.title) {
                    return this.title
                }
                else {
                    return 'Related'
                }
            },
            articleCount() {
                const limit = parseInt(this.limit);
                if (isNaN(limit)) {
                    return 0;
                }
                return limit;
            },
            hasItems() {
                return (this.countItems > 0);
            },
            countItems() {
                if (this.items) {
                    return this.items.length;
                }
                return 0;
            }
        },
        methods: {
            update() {
                var request = {
                    pinned: this.pinned,
                    topic: this.topic,
                    contenttype: this.contenttype,
                    ensemble: this.ensemble,
                    artist: this.artist,
                    conductor: this.conductor,
                    composer: this.composer,
                    genre: this.genre,
                    limit: this.articleCount
                }
                this.$http.post('/umbraco/surface/catalog/relatedsearch', request)
                    .then(({ data }) => {
                        this.items = data
                    })
                    .catch((error) => { })
            }
        },
        mounted() {
            this.update()
        }
    }
</script>