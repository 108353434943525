var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "burger-menu",
      class: { "burger-menu-open": _vm.open },
      attrs: { viewBox: "0 0 100 100", height: _vm.height, width: _vm.width }
    },
    [
      _c("path", { ref: "path1", attrs: { d: "M 92,24 L 8,24" } }, [
        _c("animate", {
          ref: "animate1",
          attrs: {
            attributeName: "d",
            dur: this.duration,
            repeatCount: "1",
            fill: "freeze"
          }
        })
      ]),
      _vm._v(" "),
      _c("path", { ref: "path2", attrs: { d: "M 92,50 H 50 8" } }, [
        _c("animate", {
          ref: "animate2",
          attrs: {
            attributeName: "d",
            dur: this.duration,
            repeatCount: "1",
            fill: "freeze"
          }
        })
      ]),
      _vm._v(" "),
      _c("path", { ref: "path3", attrs: { d: "M 92,76 L 8,76" } }, [
        _c("animate", {
          ref: "animate3",
          attrs: {
            attributeName: "d",
            dur: this.duration,
            repeatCount: "1",
            fill: "freeze"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }