var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filters__group_tag" },
    [
      _c("multiselect", {
        class: _vm.css,
        attrs: {
          options: _vm.tags,
          multiple: true,
          "close-on-select": false,
          "clear-on-select": true,
          "preserve-search": true,
          placeholder: _vm.placeholder,
          "preselect-first": false,
          "track-by": "text",
          label: "text",
          selectLabel: "+",
          selectedLabel: "",
          deselectLabel: "×"
        },
        on: {
          input: _vm.valueChanged,
          open: function($event) {
            _vm.isOpen = true
          },
          close: function($event) {
            _vm.isOpen = false
          }
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var values = ref.values
              var search = ref.search
              var isOpen = ref.isOpen
              return [
                values.length
                  ? _c(
                      "span",
                      {
                        staticClass: "multiselect__single",
                        class: { msi: isOpen }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.group) + " (" + _vm._s(values.length) + ")"
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }
          }
        ]),
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }