var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "select",
      class: { open: _vm.open },
      attrs: { role: "combobox", "aria-label": _vm.title }
    },
    [
      _c(
        "button",
        {
          staticClass: "select__head",
          on: {
            keydown: [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                ) {
                  return null
                }
                _vm.open = false
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k(
                    $event.keyCode,
                    "escape",
                    undefined,
                    $event.key,
                    undefined
                  )
                ) {
                  return null
                }
                _vm.open = false
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown"
                  ])
                ) {
                  return null
                }
                $event.preventDefault()
                return _vm.down($event)
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp"
                  ])
                ) {
                  return null
                }
                $event.preventDefault()
                return _vm.up($event)
              }
            ],
            click: function($event) {
              _vm.open = !_vm.open
            }
          }
        },
        [_vm._v(_vm._s(_vm.currentTitle))]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "select__drop", attrs: { role: "listbox" } },
        [
          _c(
            "div",
            {
              staticClass: "select__option",
              class: { select__option_selected: _vm.isAllSelected() },
              attrs: { "aria-selected": _vm.isAllSelected(), role: "option" },
              on: { click: _vm.clear }
            },
            [_vm._v("All")]
          ),
          _vm._v(" "),
          _vm._l(_vm.options, function(option) {
            return _c(
              "div",
              {
                key: option.name,
                staticClass: "select__option",
                class: { select__option_selected: _vm.isSelected(option) },
                attrs: {
                  "aria-selected": _vm.isSelected(option),
                  role: "option"
                },
                on: {
                  click: function($event) {
                    return _vm.select(option)
                  }
                }
              },
              [_vm._v(_vm._s(option.name))]
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }