<template>
    <div class="modal micromodal-slide" :class="{ 'is-open': isOpen }" :aria-hidden="isOpen ? 'false' : 'true'">
        <div class="modal__overlay" tabindex="-1" v-on:click="close">
            <div class="modal__container" :class="[type]" role="dialog" aria-modal="true" v-on:click.stop="dummy">
                <header class="modal__header">                  
                    <button class="modal__close" aria-label="Close modal" v-on:click="close"></button>
                </header>
                <main class="modal__content" v-if="type == types.DEFAULT">
                    <slot name="body"></slot>
                </main>
                <main class="modal__video" v-if="type == types.VIDEO" v-html="html"></main>
                <footer class="modal__footer" v-if="showFooter">
                    <slot name="button"></slot>
                    <button class="modal__btn modal__btn-secondary" aria-label="Close this dialog window" v-on:click="close">Close</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    export default {
        props: ['disableScroll', 'showFooter'],
        data() {
            return {
                types: {
                    VIDEO: 'video',
                    ALERT: 'alert',
                    DEFAULT: 'default'
                },
                type: 'default',
                html: '',
                isOpen: false
            }
        },
        computed: {
        },
        methods: {
            open(content, modaltype) {
                var self = this
                if (modaltype == 'video') {
                    this.type = modaltype
                    self.$http.post('/umbraco/surface/catalog/video', { videoUrl: content, width: 800, height: 400 })
                        .then(({ data }) => {
                            self.html = data.html
                        })
                        .catch((error) => { })
                }
                else {
                    this.type = 'default'
                }
                this.isOpen = true
                this.scrollBehaviour('disable')
            },
            close() {
                this.html = ''
                this.isOpen = false
                this.scrollBehaviour('enable')
            },
            scrollBehaviour(toggle) {
                if (!this.disableScroll) return
                const body = document.querySelector('body')
                switch (toggle) {
                    case 'enable':
                        Object.assign(body.style, { overflow: '' })
                        break
                    case 'disable':
                        Object.assign(body.style, { overflow: 'hidden' })
                        break
                    default:
                }
            },
            dummy() {
                //catches event propagation up to modal__overlay
            }
        },
        mounted() {
            var self = this
        }
    }
</script>