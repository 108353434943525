<template>
    <div class="event-summary">
        <div v-for="item in items" :key="item.id" class="event-summary-item">
            <div class="event-summary-item-c">
                <div class="event-summary-item-title" v-html="getTitle(item)" />
                <div class="event-summary-item-date">{{ getDate(item) }}</div>
            </div>
            <a :href="getUrl(item)" v-if="item.state == SALE_STATE_ANNOUNCEMENT" class="btn btn_sm btn_secondary">{{ getAnnoucementText(item) }}</a>
            <a :href="getUrl(item)" v-if="item.state == SALE_STATE_SUBSCRIPTION" class="btn btn_sm">{{ item.orderText || 'Subscribe' }}</a>
            <a :href="getUrl(item)" v-if="item.state == SALE_STATE_PRESALE" class="btn btn_sm">{{ item.orderText || 'Access presale' }}</a>
            <a :href="getUrl(item)" v-if="item.state == SALE_STATE_SALE" class="btn btn_sm">{{ item.orderText || 'Order now' }}</a>
            <div v-else class="placeholder"></div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import moment from 'moment-timezone';

    export default {
        props: ['performance-season', 'show-performances'],
        data() {
            return {
                items: [],
                SALE_STATE_PRIVATE: 0,
                SALE_STATE_ANNOUNCEMENT: 1,
                SALE_STATE_SUBSCRIPTION: 2,
                SALE_STATE_PRESALE: 3,
                SALE_STATE_SALE: 4,
                tz: "America/Chicago",
            }
        },
        computed: {
            hasItems() {
                return (this.countItems > 0);
            },
            countItems() {
                if (this.items) {
                    return this.items.length;
                }
                return 0;
            }
        },
        methods: {
            getAnnoucementText(item) {
                if (this.showPerformances) {
                    return "Performance Details";
                } else {
                    return "Performance Details";
                }
            },
            getTitle(item) {
                if (item.performanceTitle) {
                    return item.performanceTitle;
                } else if (item.description) {
                    return item.description;
                }
                return "";
            },
            getSubscribeUrl(item) {
                if (item.subscribeUrl) {
                    return item.subscribeUrl;
                }
                return this.getUrl(item);
            },
            getUrl(item) {
                if (item.state == this.SALE_STATE_ANNOUNCEMENT && item.contentUrl) {
                    return item.contentUrl;
                } else if (item.bookUrl) {
                    return item.bookUrl;
                } else if (item.contentUrl) {
                    return item.contentUrl;
                } else if (item.url) {
                    return item.url;
                }
                return "";
            },
            getDate(item) {
                if (item.performanceDates) {
                    return item.performanceDates;
                } else if (item.performanceDateLocal) {
                    return moment(item.performanceDateLocal).format("MMM D, YYYY");
                }
                return "";
            },

            update() {
                var request = {
                    id: this.performanceSeason,
                    summary: !this.showPerformances
                }
                this.$http.post('/umbraco/surface/events/summary', request)
                    .then(({ data }) => {
                        this.items = data;
                    })
                    .catch((error) => {
                        console.error("Error loading event summaries");
                     })
            }
        },
        mounted() {
            this.update()
        }
    }
</script>
