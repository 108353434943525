import Vue from '../config.js'

require('viewport-units-buggyfill').init();

if (document.getElementById('header-vm')) {
    var vm = new Vue({
        el: '#header-vm',
        data: {
            open: false,
            mobile: false,
            htmlNode: null
        },
        methods: {
            openMobile() {
                this.mobile = !this.mobile

                if (this.mobile) {
                    this.htmlNode.classList.add('navigating')
                }
                else {
                    this.htmlNode.classList.remove('navigating')
                }
            },
            toggleSearch() {
                this.open = !this.open

                if (this.open) {
                    Vue.nextTick(() => {
                        this.$refs.searchField.focus()
                    })
                }
            }
        },
        mounted() {
            this.htmlNode = document.getElementsByTagName('html')[0]
        }
    })
}