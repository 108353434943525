<template>
    <div class="tune__streams streams">
        <div class="streams__container container">
            <div class="streams__title">Now streaming:</div>
            <div class="streams__list">
                <a class="streams__item" :href="item.url" v-for="item in firstItems">
                    <div class="play"></div>{{item.shortTitle}}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    export default {
        data() {
            return {
                items: []
            }
        },
        computed: {
            firstItems() {
                var size = 4
                if (this.items.length > size) {
                    return this.items.slice(0, size)
                }
                return this.items
            }
        },
        mounted() {
            var self = this
            var request = {}
            self.$http.post('/umbraco/surface/catalog/homeradiofeed', request)
                .then(({ data }) => {
                    self.items = data
                })
                .catch((error) => { })
        }
    }
</script>