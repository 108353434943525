
export const setSessionStorageWithExpiry = (key, value, ttl) => {

    const now = new Date()

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	}
	sessionStorage.setItem(key, JSON.stringify(item))
}

export const getSessionStorageWithExpiry = (key) => {

	const itemStr = sessionStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		sessionStorage.removeItem(key)
		return null
	}
	return item.value
}

export const FlickityA11yFix = (node) => {
	if (!!node) {
		var items = node.querySelectorAll(":scope [aria-hidden='true']");
		items.forEach(element => {
			element.setAttribute('aria-hidden', false);
		});
	}
}

export const IsVisible = (node) => {
	if (!!node) {
		return !!( node.offsetWidth || node.offsetHeight || node.getClientRects().length );
	}
	return false;
}

export const IsDesktop = (window) => {
	return window.matchMedia('only screen and (min-width: 1260px)').matches;
}
