var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "sidebar__title" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "sidebar__articles articles" },
      _vm._l(_vm.items, function(item, index) {
        return _c("div", { key: index, staticClass: "articles__item" }, [
          _c(
            "a",
            { staticClass: "articles__preview", attrs: { href: item.url } },
            [
              _c("img", {
                staticClass: "articles__pic",
                attrs: { src: item.image, alt: item.title }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "articles__inner" }, [
            _c(
              "a",
              { staticClass: "articles__title", attrs: { href: item.url } },
              [_vm._v(_vm._s(item.title))]
            ),
            _vm._v(" "),
            item.authorNames && item.authorNames.length
              ? _c(
                  "a",
                  {
                    staticClass: "articles__author",
                    attrs: { href: item.authorUrls[0] }
                  },
                  [_vm._v("—" + _vm._s(item.authorNames[0]))]
                )
              : _vm._e()
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }