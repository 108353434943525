import Vue from '../config.js'
import VueScrollTo from 'vue-scrollto'
import { EventBus } from './event-bus'

if (document.getElementById('home-vm')) {

    var vm = new Vue({
        el: '#home-vm',
        data: {
            playing: false,
            vidhtml: ''
        },
        computed: {},
        methods: {
            preview(content) {
                var self = this

                self.$http.post('/umbraco/surface/catalog/video', { videoUrl: content, width: 800, height: 400 })
                    .then(({ data }) => {
                        self.vidhtml = data.html
                        self.playing = true
                    })
                    .catch((error) => { })
            },
            modal(content, modaltype) {
                this.$refs.csomodal.open(content, modaltype)
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.$el.parentElement.classList.remove("prerender");
            });            
        }
    })
}