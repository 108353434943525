<template>
    <div class="filters__group_tag">
        <multiselect v-model="value"
                     @input="valueChanged"
                     @open="isOpen = true"
                     @close="isOpen = false"
                     :options="tags"
                     :multiple="true"
                     :close-on-select="false"
                     :clear-on-select="true"
                     :preserve-search="true"
                     :placeholder="placeholder"
                     :class="css"
                     :preselect-first="false"
                     track-by="text"
                     label="text"
                     selectLabel="+"
                     selectedLabel=""
                     deselectLabel="×">
            <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single" :class="{ msi:isOpen }" v-if="values.length">{{group}} ({{ values.length }})</span>
            </template>
        </multiselect>
        <!--<code>Multiselect value: {{ value }}</code>-->
    </div>
</template>

<script>
    import Vue from 'vue'
    import { EventBus } from '../vues/event-bus'

    export default {
        props: {
            tags: {
                type: Array,
                required: true
            },
            group: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                isOpen: false,
                value: []
            }
        },
        computed: {
            css() {
                if (this.isOpen) { return ['gray'] } //red also an option
                else if (this.value.length > 0) { return ['dark'] }
                return []
            },
            placeholder() {
                if (this.isOpen) { return 'Type to filter ' + this.title + ':' }
                return this.title
            }
        },
        methods: {
            valueChanged() {
                this.$emit('input', this.value, this.group, true)
            },
            remove(id) {
                var items = this.value.filter((v) => { return v.id != id })
                this.value = items
                this.valueChanged()
            },
            clear() {
                this.value = []
                this.valueChanged()
            }
        },
        mounted() {
            var self = this

            EventBus.$on('clearTagFilters', () => {
                self.value = []
            })
            EventBus.$on('clearTag', (id, grp) => {
                if (grp == self.group) {
                    self.remove(id)
                }
            })
            EventBus.$on('selectTag', (id, grp) => {
                if (grp == self.group) {
                    var selectedTag = self.tags.find((el, idx) => { return el.id == id })
                    if (selectedTag) {
                        self.value.push(selectedTag)
                        this.$emit('input', self.value, self.group, false)
                    }
                }
            })

            // hack to fix 2.1.6 version of vue-multiselect (2.1.7 has fix but doesn't actually work!)
            this.$el.querySelectorAll(":scope input[autocomplete='nope']").forEach(element => {
                element.setAttribute("autocomplete", "off");
            });
        }
    }
</script>