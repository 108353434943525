<template>
    <div class="similar" v-if="items.length > 0">
        <div class="similar__title">{{displayTitle}}</div>
        <div class="similar__posts posts">
            <div class="posts__item" v-for="(item, index) in items" :key="index">
                <a class="posts__preview" :href="item.url">
                    <v-lazy-image class="posts__pic" :src="item.image" src-placeholder="/public/img/placeholder-600x400.gif" :alt="item.title" :class="{'posts__pic__tall' : tallImage(item.image)}"></v-lazy-image>
                    <div class="posts__play" v-if="item.formatIcon" v-html="item.formatIcon"></div>
                </a>
                <div class="posts__inner">
                    <a class="posts__link link" :href="'/search/'+item.primaryTopicId+'/'+item.slug">{{item.primaryTopic}}</a>
                    <div class="posts__title h6"><a :href="item.url">{{item.title}}</a></div>
                    <div class="posts__time">— {{item.friendlyLength}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    export default {
        props: ['articleid', 'title'],
        data() {
            return {
                items: []
            }
        },
        computed: {
            displayTitle() {
                if (this.title) {
                    return this.title
                }
                else {
                    return 'You may like'
                }
            }
        },
        methods: {
            update() {
                var self = this
                var request = {
                    articleId: self.articleid
                }
                self.$http.post('/umbraco/surface/catalog/related', request)
                    .then(({ data }) => {
                        self.items = data
                    })
                    .catch((error) => { })
            },
            tallImage(image) {
                if (image.indexOf('width=') == -1) {
                    return true
                }
                return false
            }
        },
        mounted() {
            this.update()
        }
    }
</script>