<template>
    <div>
        <div class="sidebar__title">{{ title }}</div>
        <a class="sidebar__watch" :href="item.url" v-for="item in items">
            <div class="play"></div>{{item.title}}
        </a>
        <!--<div class="sidebar__articles articles">
            <div class="articles__item" v-for="item in items">
                <a class="articles__preview" :href="item.url"><img class="articles__pic" :src="item.image" :alt="item.title"></a>
                <div class="articles__inner">
                    <a class="articles__title" :href="item.url">{{item.title}}</a>
                    <a class="articles__author" :href="item.authorUrls[0]">{{item.authorNames[0]}}</a>
                </div>
            </div>
        </div>-->
    </div>
</template>

<script>
    import Vue from 'vue'

    export default {
        props: ['title'],
        data() {
            return {
                items: []
            }
        },
        mounted() {
            var self = this
            var request = {}
            self.$http.post('/umbraco/surface/catalog/latestcsoradio', request)
                .then(({ data }) => {
                    self.items = data
                })
                .catch((error) => { })
        }
    }
</script>