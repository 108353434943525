var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calendarpicker" }, [
    _c("div", { staticClass: "calendarpicker__header" }, [
      _c("div", {
        staticClass: "previous handle",
        attrs: { tabindex: "0", title: "Previous Month" },
        on: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.previous($event)
          },
          click: _vm.previous
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "month-title" }, [
        _vm._v(_vm._s(_vm.viewMonthTitle))
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "next handle",
        attrs: { tabindex: "0", title: "Next Month" },
        on: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.next($event)
          },
          click: _vm.next
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "calendarpicker__body" },
      [
        _c(
          "div",
          { staticClass: "row weekdays" },
          _vm._l(_vm.weekdays, function(weekday, weekdayIndex) {
            return _c(
              "div",
              { key: "w" + weekdayIndex, staticClass: "cell weekday" },
              [_vm._v(_vm._s(weekday))]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm._l(_vm.weeksInViewMonth, function(week) {
          return _c(
            "div",
            { key: "week" + week, staticClass: "row week" },
            _vm._l(_vm.weekdays.length, function(day) {
              return _c(
                "div",
                {
                  key: "w" + week + "-d" + day,
                  staticClass: "day",
                  class: _vm.getClassForWeekDay(week, day),
                  attrs: { tabindex: "0" },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.clicked(week, day)
                    },
                    click: function($event) {
                      return _vm.clicked(week, day)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.getDateForWeekDay(week, day).date.date()) +
                      "\n            "
                  )
                ]
              )
            }),
            0
          )
        })
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "calendarpicker__footer" }, [_vm._t("footer")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }