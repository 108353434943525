<template>
    <div v-if="hasItems" class="series__carousel posts">

        <flickity class="flickity" ref="flickity" :options="flickityOptions">
            <Post v-for="post in items" :key="post.id" :post="post" :tags="tags" />
        </flickity>

        <div class="playlists__progress" :class="['itemcount' + this.countItems]" v-show="(this.countItems > 1)">
            <div ref="progressBar" class="playlists__position" style="width: 0%;"></div>
        </div>

        <div class="series__carousel_btns" v-show="(this.countItems > 3)">
            <div class="cursor__trigger prev" @click="previous()">
                <button ref="prev" class="series__prev" aria-label="Previous series">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 32 21">
                        <path fill="#fff" d="M21.958.25l.06.052L31.66 9.51c.438.418.454 1.112.036 1.55-.043.045-.09.085-.137.12-.034.046-.074.092-.118.134h0l-9.644 9.205-.06.052c-.44.364-1.09.33-1.49-.088-.418-.438-.402-1.13.036-1.55h0l7.895-7.536H1.096C.49 11.397 0 10.907 0 10.3c0-.58.452-1.056 1.024-1.094l.072-.002h27.072L20.504 1.89c-.438-.418-.454-1.112-.036-1.55.4-.42 1.052-.452 1.49-.088z" />
                    </svg>
                </button>
            </div>
            <div class="cursor__trigger next" @click="next()">
                <button ref="next" class="series__next" aria-label="Next series">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 32 21">
                        <path fill="#fff" d="M21.958.25l.06.052L31.66 9.51c.438.418.454 1.112.036 1.55-.043.045-.09.085-.137.12-.034.046-.074.092-.118.134h0l-9.644 9.205-.06.052c-.44.364-1.09.33-1.49-.088-.418-.438-.402-1.13.036-1.55h0l7.895-7.536H1.096C.49 11.397 0 10.907 0 10.3c0-.58.452-1.056 1.024-1.094l.072-.002h27.072L20.504 1.89c-.438-.418-.454-1.112-.036-1.55.4-.42 1.052-.452 1.49-.088z" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Post from './Post.vue';
    import Flickity from 'vue-flickity';

    export default {
        props: ['eventCount', 'tags', 'condition', 'seasonId'],
        data() {
            return {
                items: [],
                flickityOptions: {
                    prevNextButtons: false,
                    pageDots: false,
                    wrapAround: false,
                    freeScroll: false,
                    imagesLoaded: true,
                    autoPlay: false,
                    lazyLoad: 2,
                    cellAlign: 'left',
                }
            }
        },
        components: {
            Post,
            Flickity
        },
        computed: {
            hasItems() {
                return (this.countItems > 0);
            },
            countItems() {
                if (this.items) {
                    return this.items.length;
                }
                return 0;
            }
        },
        methods: {
            next() {
                this.$refs.flickity.next(true);
            },
            previous() {
                this.$refs.flickity.previous(true);
            },
            flickityChange(index) {
                if (this.hasItems) {
                    this.$refs.progressBar.style.width = (((index + 1) / this.items.length) * 100) + '%';
                }
            },
            update() {
                var request = {
                    count: this.eventCount,
                    tags: this.tags,
                    pinned: [],
                    condition: this.condition,
                    exclude: [],
                    season: this.seasonId
                }
                var self = this

                this.$http.post('/umbraco/surface/events/search', request)
                    .then(({ data }) => {
                        self.items = data;

                        Vue.nextTick(() => {
                            self.flickityChange(0)
                            self.customBigCursor(this.$el)
                        });
                    })
                    .catch((error) => {
                        console.error("Error loading related events");
                        console.dir(error);
                    })
            },
            attachFlicketyOnChange() {
                if (this.$refs.flickity) {

                    this.$refs.flickity.on('change', this.flickityChange);

                } else {
                    setTimeout(() => {
                        this.attachFlicketyOnChange();
                    }, 50);

                    // no idea why nextTick doesn't work
                    // Vue.nextTick(() => {
                    //     this.attachFlicketyOnChange();
                    // });        
                }
            },
            customBigCursor(element) {
                if (element === undefined) element = document;

                const wcursor = document.getElementById('wap__cursor')
                const wtriggers = element.getElementsByClassName('cursor__trigger')

                if (wtriggers.length) {
                    for (var i = 0; i < wtriggers.length; i++) {
                        wtriggers[i].addEventListener('mouseenter', (e) => {
                            wcursor.classList.add('visible')
                            if (e.target.classList.contains('prev')) { wcursor.classList.add('previous') }
                            else { wcursor.classList.remove('previous') }
                        })
                        wtriggers[i].addEventListener('mouseleave', () => {
                            wcursor.classList.remove('visible')
                        })
                        wtriggers[i].addEventListener('mousemove', (e) => {
                            wcursor.style.top = (e.clientY - 50) + 'px'
                            wcursor.style.left = (e.clientX - 50) + 'px'
                        })
                    }
                }
            }
        },
        mounted() {
            var self = this
            this.update();
            this.attachFlicketyOnChange();
        }
    }
</script>