var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasItems
    ? _c(
        "div",
        { staticClass: "series__carousel posts" },
        [
          _c(
            "flickity",
            {
              ref: "flickity",
              staticClass: "flickity",
              attrs: { options: _vm.flickityOptions }
            },
            _vm._l(_vm.items, function(post) {
              return _c("Post", {
                key: post.id,
                attrs: { post: post, tags: _vm.tags }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: this.countItems > 1,
                  expression: "(this.countItems > 1)"
                }
              ],
              staticClass: "playlists__progress",
              class: ["itemcount" + this.countItems]
            },
            [
              _c("div", {
                ref: "progressBar",
                staticClass: "playlists__position",
                staticStyle: { width: "0%" }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: this.countItems > 3,
                  expression: "(this.countItems > 3)"
                }
              ],
              staticClass: "series__carousel_btns"
            },
            [
              _c(
                "div",
                {
                  staticClass: "cursor__trigger prev",
                  on: {
                    click: function($event) {
                      return _vm.previous()
                    }
                  }
                },
                [
                  _c(
                    "button",
                    {
                      ref: "prev",
                      staticClass: "series__prev",
                      attrs: { "aria-label": "Previous series" }
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "24",
                            viewBox: "0 0 32 21"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              fill: "#fff",
                              d:
                                "M21.958.25l.06.052L31.66 9.51c.438.418.454 1.112.036 1.55-.043.045-.09.085-.137.12-.034.046-.074.092-.118.134h0l-9.644 9.205-.06.052c-.44.364-1.09.33-1.49-.088-.418-.438-.402-1.13.036-1.55h0l7.895-7.536H1.096C.49 11.397 0 10.907 0 10.3c0-.58.452-1.056 1.024-1.094l.072-.002h27.072L20.504 1.89c-.438-.418-.454-1.112-.036-1.55.4-.42 1.052-.452 1.49-.088z"
                            }
                          })
                        ]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cursor__trigger next",
                  on: {
                    click: function($event) {
                      return _vm.next()
                    }
                  }
                },
                [
                  _c(
                    "button",
                    {
                      ref: "next",
                      staticClass: "series__next",
                      attrs: { "aria-label": "Next series" }
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "24",
                            viewBox: "0 0 32 21"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              fill: "#fff",
                              d:
                                "M21.958.25l.06.052L31.66 9.51c.438.418.454 1.112.036 1.55-.043.045-.09.085-.137.12-.034.046-.074.092-.118.134h0l-9.644 9.205-.06.052c-.44.364-1.09.33-1.49-.088-.418-.438-.402-1.13.036-1.55h0l7.895-7.536H1.096C.49 11.397 0 10.907 0 10.3c0-.58.452-1.056 1.024-1.094l.072-.002h27.072L20.504 1.89c-.438-.418-.454-1.112-.036-1.55.4-.42 1.052-.452 1.49-.088z"
                            }
                          })
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }