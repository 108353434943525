var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.active
    ? _c(
        "div",
        { staticClass: "schedule_packages" },
        [
          _vm._l(_vm.packages, function(item) {
            return _c("div", { staticClass: "schedule_packages_row" }, [
              _c("div", { staticClass: "spr_title" }, [
                _vm._v(_vm._s(item.webTitle))
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "spr_info",
                domProps: { innerHTML: _vm._s(item.shortDescription) }
              }),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "btn btn_sm", attrs: { href: item.url } },
                [
                  _c("div", { staticClass: "btn__circle" }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "btn__title",
                    domProps: { innerHTML: _vm._s(item.buttonText) }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "spr_more",
                  on: {
                    click: function($event) {
                      return _vm.getConcerts(item.id, item.url)
                    }
                  }
                },
                [_vm._v("See all concerts in this package [+]")]
              )
            ])
          }),
          _vm._v(" "),
          !_vm.loaded
            ? _c("div", { staticClass: "loading" }, [
                _c("div", { staticClass: "loading_bar" }),
                _vm._v(" "),
                _c("div", { staticClass: "loading_text" }, [
                  _vm._v("Loading Packages...")
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.loaded && !_vm.packages.length
            ? _c("div", { staticClass: "loading" }, [
                _c("div", { staticClass: "loading_text" }, [
                  _vm._v("No options found.")
                ])
              ])
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }