<template>
    <div class="posts__item">
        <a class="posts__preview" :href="url">
            <img class="posts__pic" :src="imageUrl" :alt="imageAlt" />
        </a>
        <div class="posts__inner">
            <div class="posts__sponsor" v-if="sponsors && post.contentSponsorImage"><img :src="post.contentSponsorImage" :alt="post.contentSponsorName" /></div>
            <a class="posts__link link" :href="concertTypeUrl">{{ concertType }}</a>
            <div class="posts__title h6"><a :href="url" v-html="title"></a></div>
            <div class="posts__time">{{ performanceDates }} </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    export default {
        props: ['post', 'tags', 'sponsors'],
        computed: {
            url() {
                //return (this.items && this.items.length > 0);
                if (this.post && this.post.url) {
                    return this.post.url;
                }
                return "#";
            },
            imageUrl() {
                if (this.post && this.post.listImageUrl) {
                    return this.post.listImageUrl;
                }
                return "";
            },
            imageAlt() {
                if (this.post && this.post.listImageAlt) {
                    return this.post.listImageAlt;
                }
                return "";
            },
            concertType() {
                if (this.post && this.post.concertTypes && this.post.concertTypes.length > 0) {
                    return this.matchConcertType(this.post.concertTypes[0]);
                }
                return "";
            },
            concertTypeUrl() {
                if (this.concertType != "") {
                    return "/concerts-tickets/whats-on?concert-types="+this.concertType;
                }
                return "#";
            },
            title() {
                if (this.post && this.post.contentTitle) {
                    return this.post.contentTitle;
                }
                return this.post.performanceTitle;
            },
            performanceDates() {
                if (this.post && this.post.performanceDates) {
                    return this.post.performanceDates;
                }
                return "";
            },
        },
        methods: {
            matchConcertType(defaultValue) {
                var self = this,
                    returnValue = defaultValue
                if (self.tags && self.tags.length > 0 && self.post.concertTypes.length > 1) {
                    self.post.concertTypes.forEach(function (ct) {
                        self.tags.forEach(function (tg) {
                            if (tg.toLowerCase() == ct.toLowerCase()) {
                                returnValue = ct
                            }
                        })
                    })
                }
                return returnValue
            }
        }
    }
</script>
