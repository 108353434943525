var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "posts__item" }, [
    _c("a", { staticClass: "posts__preview", attrs: { href: _vm.url } }, [
      _c("img", {
        staticClass: "posts__pic",
        attrs: { src: _vm.imageUrl, alt: _vm.imageAlt }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "posts__inner" }, [
      _vm.sponsors && _vm.post.contentSponsorImage
        ? _c("div", { staticClass: "posts__sponsor" }, [
            _c("img", {
              attrs: {
                src: _vm.post.contentSponsorImage,
                alt: _vm.post.contentSponsorName
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "posts__link link",
          attrs: { href: _vm.concertTypeUrl }
        },
        [_vm._v(_vm._s(_vm.concertType))]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "posts__title h6" }, [
        _c("a", {
          attrs: { href: _vm.url },
          domProps: { innerHTML: _vm._s(_vm.title) }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "posts__time" }, [
        _vm._v(_vm._s(_vm.performanceDates) + " ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }