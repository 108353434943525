var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.authors.length > 0
    ? _c("div", { staticClass: "contrib" }, [
        _c("div", { staticClass: "contrib__title" }, [
          _vm._v("Featured contributors")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "contrib__list" },
          _vm._l(_vm.authors, function(author) {
            return _c("div", { staticClass: "contrib__item" }, [
              _c("div", { staticClass: "contrib__preview" }, [
                author.image
                  ? _c("img", {
                      staticClass: "contrib__pic",
                      attrs: { src: author.image, alt: author.name }
                    })
                  : _c("span", { staticClass: "contrib__intials" }, [
                      _vm._v(_vm._s(author.initials))
                    ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "contrib__name" }, [
                _vm._v(_vm._s(author.name))
              ]),
              _c(
                "a",
                {
                  staticClass: "contrib__link link",
                  attrs: { href: author.url }
                },
                [_vm._v("See all posts")]
              )
            ])
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }