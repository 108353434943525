<template>
    <div>
        <div class="sidebar__title">{{ title }}</div>
        <div class="sidebar__articles articles">
            <div class="articles__item" v-for="(item, index) in items" :key="index">
                <a class="articles__preview" :href="item.url"><img class="articles__pic" :src="item.image" :alt="item.title"></a>
                <div class="articles__inner">
                    <a class="articles__title" :href="item.url">{{item.title}}</a>
                    <a class="articles__author" :href="item.authorUrls[0]" v-if="item.authorNames && item.authorNames.length">&mdash;{{item.authorNames[0]}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    export default {
        props: ['pinned', 'topic', 'contenttype', 'ensemble', 'artist', 'conductor', 'composer', 'genre', 'title', 'limit'],
        data() {
            return {
                items: []
            }
        },
        computed: {
            displayTitle() {
                if (this.title) {
                    return this.title
                }
                else {
                    return 'Related'
                }
            },
            articleCount() {
                const limit = parseInt(this.limit);
                if (isNaN(limit)) {
                    return 0;
                }
                return limit;
            }
        },
        methods: {
            update() {
                var request = {
                    pinned: this.pinned,
                    topic: this.topic,
                    contenttype: this.contenttype,
                    ensemble: this.ensemble,
                    artist: this.artist,
                    conductor: this.conductor,
                    composer: this.composer,
                    genre: this.genre,
                    limit: this.articleCount
                }
                this.$http.post('/umbraco/surface/catalog/relatedsearch', request)
                    .then(({ data }) => {
                        this.items = data
                    })
                    .catch((error) => { })
            }
        },
        mounted() {
            this.update()
        }
    }
</script>