import Vue from '../config.js'
import VueScrollTo from 'vue-scrollto'
import { EventBus } from './event-bus'

if (document.getElementById('generic-vm')) {

    var vm = new Vue({
        el: '#generic-vm',
        data: {
            loaded: false
        },
        computed: {
        },
        methods: {
            modal(content, modaltype) {
                this.$refs.csomodal.open(content, modaltype)
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.$el.parentElement.classList.remove("prerender");
                this.loaded = true;
            });
        }
    })
}