var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "Container", staticClass: "calendar-container" }, [
    _vm.allowfilters
      ? _c(
          "div",
          { staticClass: "params", class: _vm.paramsOpen ? " open" : "" },
          [
            _c(
              "button",
              {
                staticClass: "params__btn btn",
                attrs: { "aria-label": "Refine your search" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.paramsOpen = !_vm.paramsOpen
                  }
                }
              },
              [
                _c("div", { staticClass: "btn__circle" }),
                _vm._v(" "),
                _c("div", { staticClass: "btn__title" }, [
                  _vm.filtersSummary && _vm.filtersSummary.length > 0
                    ? _c(
                        "div",
                        { staticClass: "filter-summary" },
                        _vm._l(_vm.filtersSummary, function(filter) {
                          return _c(
                            "div",
                            { key: filter, staticClass: "filter" },
                            [_vm._v(_vm._s(filter))]
                          )
                        }),
                        0
                      )
                    : _c("div", [
                        _vm._v("\n        Refine your search\n        ")
                      ]),
                  _vm._v(" "),
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "12",
                        height: "12",
                        viewBox: "0 0 12 12"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "#fff",
                          d:
                            "M4.94.75v8.38L1.28 5.47c-.29-.29-.77-.29-1.06 0s-.29.76 0 1.06l4.94 4.94c.29.29.76.29 1.06 0l4.95-4.93c.29-.29.29-.76 0-1.06s-.77-.29-1.06 0L6.44 9.13V.75c0-.41-.34-.75-.75-.75s-.75.34-.75.75z"
                        }
                      })
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "params__drop" }, [
              _c("div", { staticClass: "params__sections" }, [
                _c("div", { staticClass: "params__section" }, [
                  _c("div", { staticClass: "params__category" }, [
                    _vm._v("Concert Type")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "params__inner" },
                    _vm._l(_vm.concerttypes, function(ct) {
                      return _c("toggleswitch", {
                        key: ct,
                        attrs: {
                          tabIndex: _vm.paramsOpen ? 0 : -1,
                          label: ct,
                          checked: _vm.filterConcertTypes[ct]
                        },
                        on: { "on-change": _vm.concertTypeChange }
                      })
                    }),
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "params__section" }, [
                  _c("div", { staticClass: "params__category" }, [
                    _vm._v("Genre")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "params__inner" },
                    _vm._l(_vm.genres, function(x) {
                      return _c("toggleswitch", {
                        key: x,
                        attrs: {
                          tabIndex: _vm.paramsOpen ? 0 : -1,
                          label: x,
                          checked: _vm.filterGenres[x]
                        },
                        on: { "on-change": _vm.genreChange }
                      })
                    }),
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "params__section" }, [
                  _c("div", { staticClass: "params__category" }, [
                    _vm._v("Venue")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "params__inner" },
                    _vm._l(_vm.venues, function(x) {
                      return _c("toggleswitch", {
                        key: x,
                        attrs: {
                          tabIndex: _vm.paramsOpen ? 0 : -1,
                          label: x,
                          checked: _vm.filterVenues[x]
                        },
                        on: { "on-change": _vm.venueChange }
                      })
                    }),
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "params__section" }, [
                  _c("div", { staticClass: "params__category" }, [
                    _vm._v("Platform")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "params__inner" },
                    _vm._l(_vm.platforms, function(x) {
                      return _c("toggleswitch", {
                        key: x,
                        attrs: {
                          tabIndex: _vm.paramsOpen ? 0 : -1,
                          label: x,
                          checked: _vm.filterPlatforms[x]
                        },
                        on: { "on-change": _vm.platformChange }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _vm.seasonsList && _vm.seasonsList.length > 0
                    ? _c("div", { staticClass: "params__inner_section" }, [
                        _c("div", { staticClass: "params__category" }, [
                          _vm._v("Season")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "params__inner" },
                          [
                            _vm._l(_vm.seasonsList, function(x) {
                              return [
                                _c("toggleswitch", {
                                  key: x,
                                  attrs: {
                                    tabIndex: _vm.paramsOpen ? 0 : -1,
                                    label: x,
                                    checked: _vm.filterSeasons[x]
                                  },
                                  on: { "on-change": _vm.seasonChange }
                                })
                              ]
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "params__foot" }, [
                _c(
                  "button",
                  {
                    staticClass: "params__reset",
                    attrs: {
                      tabIndex: _vm.paramsOpen ? 0 : -1,
                      "aria-label": "Clear filters"
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.clearFilters($event)
                      },
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.clearFilters($event)
                      }
                    }
                  },
                  [_vm._v("Clear filters")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "params__submit btn",
                    attrs: {
                      tabIndex: _vm.paramsOpen ? 0 : -1,
                      "aria-label": "Find events"
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.findEvents($event)
                      },
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.findEvents($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "btn__circle" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "btn__title" }, [
                      _vm._v("Find events")
                    ])
                  ]
                )
              ])
            ])
          ]
        )
      : _c("div", { staticClass: "no-params" }),
    _vm._v(" "),
    _c("div", { staticClass: "calendar calendar_results" }, [
      _c("div", { staticClass: "calendar__row__container" }, [
        _c("div", { staticClass: "calendar__row" }, [
          _c(
            "div",
            { staticClass: "calendar__col" },
            [
              _c("div", { staticClass: "calendar__col__row" }, [
                _c(
                  "div",
                  { staticClass: "calendar__col__col" },
                  [
                    _vm.allowpaidfilter
                      ? _c("div", { staticClass: "calendar__nav" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "form__inner umbraco-forms-field-wrapper"
                            },
                            [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paidFilterSelect,
                                      expression: "paidFilterSelect"
                                    }
                                  ],
                                  staticClass: "input-validation-valid valid",
                                  attrs: { "aria-label": "Paid/Free" },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.paidFilterSelect = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "paid" } }, [
                                    _vm._v("Paid")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "free" } }, [
                                    _vm._v("Free")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("All events")
                                  ])
                                ]
                              )
                            ]
                          )
                        ])
                      : !_vm.allowfilters &&
                        _vm.seasonsList &&
                        _vm.seasonsList.length > 0
                      ? [
                          _c(
                            "div",
                            { staticClass: "series__filter" },
                            [
                              _vm.seasonfilter && _vm.seasonfilter.FilterLabel
                                ? _c("label", [
                                    _vm._v(_vm._s(_vm.seasonfilter.FilterLabel))
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(_vm.seasonsList, function(x) {
                                return [
                                  _c("toggleswitch", {
                                    key: x,
                                    attrs: {
                                      label: x,
                                      checked: _vm.filterSeasons[x]
                                    },
                                    on: {
                                      "on-change": _vm.seasonChangeAndUpdate
                                    }
                                  })
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      : [
                          _vm.totalResults > 0
                            ? _c("div", { staticClass: "calendar__results" }, [
                                _vm._v(
                                  "Showing " +
                                    _vm._s(_vm.shownResults) +
                                    " of " +
                                    _vm._s(_vm.totalResults) +
                                    " Results:"
                                )
                              ])
                            : _vm._e()
                        ]
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "calendar__col__col calendar__controls" },
                  [
                    _vm.allowgroup
                      ? _c("div", { staticClass: "calendar__nav" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "form__inner umbraco-forms-field-wrapper"
                            },
                            [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.groupProductionsSelect,
                                      expression: "groupProductionsSelect"
                                    }
                                  ],
                                  staticClass: "input-validation-valid valid",
                                  attrs: { "aria-label": "By Program/Date" },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.groupProductionsSelect = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "productions" } },
                                    [_vm._v("View by program")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { attrs: { value: "performances" } },
                                    [_vm._v("View by date")]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "calendar__col__col calendar__controls calendar__controls__calendar"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "calendar-handle",
                        on: { click: _vm.toggleCalendar }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              version: "1.1",
                              xmlns: "http://www.w3.org/2000/svg",
                              "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              width: "16",
                              height: "16",
                              viewBox: "0 0 16 16"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "#1b1b1b",
                                d:
                                  "M5 6h2v2h-2zM8 6h2v2h-2zM11 6h2v2h-2zM2 12h2v2h-2zM5 12h2v2h-2zM8 12h2v2h-2zM5 9h2v2h-2zM8 9h2v2h-2zM11 9h2v2h-2zM2 9h2v2h-2zM13 0v1h-2v-1h-7v1h-2v-1h-2v16h15v-16h-2zM14 15h-13v-11h13v11z"
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("Calendar")])
                      ]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              !this.groupProductions
                ? [
                    !_vm.loading &&
                    (!_vm.eventDays || _vm.eventDays.length == 0)
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "calendar__posts posts posts_calendar empty-calendar"
                          },
                          [
                            _c("div", { staticClass: "posts__title h6" }, [
                              _vm._v("No matching events were found")
                            ]),
                            _vm._v(" "),
                            _vm.allowfilters
                              ? _c("p", [
                                  _vm._v("Try widening your "),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          _vm.paramsOpen = true
                                        }
                                      }
                                    },
                                    [_vm._v("search criteria")]
                                  ),
                                  _vm._v(".")
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.eventDays, function(day) {
                      return _c(
                        "div",
                        { key: day, staticClass: "calendar__section" },
                        [
                          _c("div", { staticClass: "calendar__date" }, [
                            _vm._v(_vm._s(_vm.eventDayTitles[day]))
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "calendar__posts posts posts_calendar"
                            },
                            _vm._l(_vm.eventsByDay[day], function(performance) {
                              return _c(
                                "div",
                                {
                                  key: performance.Id,
                                  staticClass: "posts__item"
                                },
                                [
                                  _vm.eventData[performance.eventId]
                                    ? [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "posts__preview",
                                            attrs: {
                                              href:
                                                _vm.eventData[
                                                  performance.eventId
                                                ].contentUrl
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "posts__pic",
                                              attrs: {
                                                src:
                                                  _vm.eventData[
                                                    performance.eventId
                                                  ]
                                                    .productionSeasonListImageUrl,
                                                alt:
                                                  _vm.eventData[
                                                    performance.eventId
                                                  ].productionSeasonListImageAlt
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.seasons[
                                              _vm.eventData[performance.eventId]
                                                .seasonId
                                            ] &&
                                            _vm.seasons[
                                              _vm.eventData[performance.eventId]
                                                .seasonId
                                            ].showBadge
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "posts__season_badge"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.seasons[
                                                          _vm.eventData[
                                                            performance.eventId
                                                          ].seasonId
                                                        ].badgeText
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "posts__inner" },
                                          [
                                            _vm.eventData[performance.eventId]
                                              .concertTypes &&
                                            _vm.eventData[performance.eventId]
                                              .concertTypes.length > 0
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "posts__link link",
                                                    attrs: {
                                                      href: _vm.eventData[
                                                        performance.eventId
                                                      ].concertTypes
                                                        ? "/concerts-tickets/whats-on?concert-types=" +
                                                          _vm.eventData[
                                                            performance.eventId
                                                          ].concertTypes[0]
                                                        : ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.eventData[
                                                          performance.eventId
                                                        ].concertTypes
                                                          ? _vm.eventData[
                                                              performance
                                                                .eventId
                                                            ].concertTypes[0]
                                                          : ""
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.eventData[performance.eventId]
                                              .priceRange == "Free"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "posts__badge"
                                                  },
                                                  [_vm._v("Free Event")]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "posts__title h6"
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        _vm.eventData[
                                                          performance.eventId
                                                        ].contentUrl
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                " +
                                                        _vm._s(
                                                          _vm.eventData[
                                                            performance.eventId
                                                          ].contentTitle ||
                                                            _vm.eventData[
                                                              performance
                                                                .eventId
                                                            ].description ||
                                                            performance.description
                                                        ) +
                                                        "\n                            "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "posts__meta" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "posts__detail"
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      {
                                                        attrs: {
                                                          xmlns:
                                                            "http://www.w3.org/2000/svg",
                                                          width: "14",
                                                          height: "15"
                                                        }
                                                      },
                                                      [
                                                        _c("path", {
                                                          attrs: {
                                                            fill: "#b22222",
                                                            "fill-rule":
                                                              "evenodd",
                                                            d:
                                                              "M7 .62a7 7 0 0 1 7 6.98 7 7 0 0 1-7 6.98A7 7 0 0 1 0 7.6 7 7 0 0 1 7 .62zm0 1.5A5.5 5.5 0 0 0 1.5 7.6 5.5 5.5 0 0 0 7 13.08a5.5 5.5 0 0 0 5.5-5.48A5.5 5.5 0 0 0 7 2.12zM7 3.8c.38 0 .69.31.69.69h0v3.48l1.84 1.33c.31.23.37.66.15.96h0l-.07.08c-.1.1-.23.17-.38.19a.7.7 0 0 1-.11.01c-.14 0-.27-.04-.39-.12h0 0l-.01-.01-2.4-1.74h.01-.01V4.48c0-.38.31-.69.69-.69z"
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm
                                                          .moment(
                                                            performance.performanceDate
                                                          )
                                                          .tz(_vm.tz)
                                                          .format("h:mm A")
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                performance.venues &&
                                                performance.venues.length > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "posts__detail"
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            attrs: {
                                                              xmlns:
                                                                "http://www.w3.org/2000/svg",
                                                              width: "13",
                                                              height: "16"
                                                            }
                                                          },
                                                          [
                                                            _c("path", {
                                                              attrs: {
                                                                fill: "#b22222",
                                                                "fill-rule":
                                                                  "evenodd",
                                                                d:
                                                                  "M6.53.06a5.9 5.9 0 0 1 4.28 1.91c1.12 1.17 1.77 2.69 1.77 4.17 0 2.13-1.64 4.94-4.87 8.36h0l-.22.23c-.26.27-.61.41-.96.41-.29 0-.58-.1-.83-.29h0l-.01-.01-.1-.09C2.2 11.2.48 8.31.48 6.13c0-1.47.64-2.99 1.77-4.17A5.9 5.9 0 0 1 6.53.06zm0 1.52c-2.35 0-4.57 2.22-4.57 4.56 0 1.65 1.54 4.12 4.57 7.35 3.03-3.22 4.57-5.7 4.57-7.35 0-2.34-2.22-4.56-4.57-4.56zm0 1.42a3.1 3.1 0 0 1 3.1 3.09 3.1 3.1 0 0 1-3.1 3.09 3.1 3.1 0 0 1-3.1-3.09A3.1 3.1 0 0 1 6.53 3h0zm0 1.47c-.89 0-1.62.73-1.62 1.62S5.63 7.7 6.53 7.7s1.62-.73 1.62-1.62-.73-1.62-1.62-1.62z"
                                                              }
                                                            })
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                            " +
                                                            _vm._s(
                                                              performance
                                                                .venues[0]
                                                            ) +
                                                            "\n                            "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.eventData[performance.eventId]
                                              .contentMetaDescription &&
                                            _vm.eventData[performance.eventId]
                                              .contentMetaDescription.length > 0
                                              ? _c("div", {
                                                  staticClass:
                                                    "posts__content posts__content_extended_3",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.eventData[
                                                        performance.eventId
                                                      ].contentMetaDescription
                                                    )
                                                  }
                                                })
                                              : [
                                                  _vm.eventData[
                                                    performance.eventId
                                                  ]
                                                    .productionSeasonDescriptionLong &&
                                                  _vm.eventData[
                                                    performance.eventId
                                                  ]
                                                    .productionSeasonDescriptionLong
                                                    .length > 0
                                                    ? _c("div", {
                                                        staticClass:
                                                          "posts__content posts__content_extended_3",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.eventData[
                                                              performance
                                                                .eventId
                                                            ]
                                                              .productionSeasonDescriptionLong
                                                          )
                                                        }
                                                      })
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "posts__content"
                                                        },
                                                        [
                                                          performance.text1 &&
                                                          performance.text1
                                                            .length > 0
                                                            ? _c("p", [
                                                                _vm._v(
                                                                  "\n                                " +
                                                                    _vm._s(
                                                                      performance.text1
                                                                    ) +
                                                                    "\n                            "
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          performance.text2 &&
                                                          performance.text2
                                                            .length > 0
                                                            ? _c("p", [
                                                                _vm._v(
                                                                  "\n                                " +
                                                                    _vm._s(
                                                                      performance.text2
                                                                    ) +
                                                                    "\n                            "
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]
                                                      )
                                                ],
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "posts__foot" },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "posts__more",
                                                    attrs: {
                                                      href:
                                                        _vm.eventData[
                                                          performance.eventId
                                                        ].contentUrl
                                                    }
                                                  },
                                                  [_vm._v("Event details »")]
                                                ),
                                                _vm._v(" "),
                                                performance.state > 1
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "posts__divider"
                                                      },
                                                      [_vm._v("|")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.eventData[
                                                  performance.eventId
                                                ].contentBookUrlOverride
                                                  ? [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "posts__more",
                                                          attrs: {
                                                            href:
                                                              _vm.eventData[
                                                                performance
                                                                  .eventId
                                                              ]
                                                                .contentBookUrlOverride
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.eventData[
                                                                performance
                                                                  .eventId
                                                              ]
                                                                .contentBookUrlNameOverride
                                                            ) + " »"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  : [
                                                      performance.state == 2
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "posts__more",
                                                              attrs: {
                                                                href:
                                                                  performance.subscribeUrl
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Subscribe »"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      performance.state == 3
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "posts__more",
                                                              attrs: {
                                                                href:
                                                                  performance.url
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Access presale »"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      performance.state > 3
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "posts__more",
                                                              attrs: {
                                                                href:
                                                                  performance.url
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Order now »"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                              ],
                                              2
                                            )
                                          ],
                                          2
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ]
                      )
                    })
                  ]
                : this.groupProductions
                ? [
                    !_vm.loading && (!this.events || _vm.events.length == 0)
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "calendar__posts posts posts_calendar empty-calendar"
                          },
                          [
                            _c("div", { staticClass: "posts__title h6" }, [
                              _vm._v("No matching events were found")
                            ]),
                            _vm._v(" "),
                            _vm.allowfilters
                              ? _c("p", [
                                  _vm._v("Try widening your "),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          _vm.paramsOpen = true
                                        }
                                      }
                                    },
                                    [_vm._v("search criteria")]
                                  ),
                                  _vm._v(".")
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "calendar__section" }, [
                      _c(
                        "div",
                        { staticClass: "calendar__posts posts posts_calendar" },
                        _vm._l(_vm.sortedEvents, function(eventData) {
                          return _c(
                            "div",
                            { key: eventData.Id, staticClass: "posts__item" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "posts__preview",
                                  attrs: { href: eventData.contentUrl }
                                },
                                [
                                  _c("img", {
                                    staticClass: "posts__pic",
                                    attrs: {
                                      src:
                                        eventData.productionSeasonListImageUrl,
                                      alt:
                                        eventData.productionSeasonListImageAlt
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.seasons[eventData.seasonId] &&
                                  _vm.seasons[eventData.seasonId].showBadge
                                    ? _c(
                                        "div",
                                        { staticClass: "posts__season_badge" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.seasons[eventData.seasonId]
                                                .badgeText
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "posts__inner" },
                                [
                                  eventData.concertTypes &&
                                  eventData.concertTypes.length > 0
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "posts__link link",
                                          attrs: {
                                            href: eventData.concertTypes
                                              ? "/concerts-tickets/whats-on?concert-types=" +
                                                eventData.concertTypes[0]
                                              : ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              eventData.concertTypes
                                                ? eventData.concertTypes[0]
                                                : ""
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  eventData.priceRange == "Free"
                                    ? _c(
                                        "div",
                                        { staticClass: "posts__badge" },
                                        [_vm._v("Free Event")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "posts__title h6" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: eventData.contentUrl }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                eventData.contentTitle ||
                                                  eventData.description
                                              ) +
                                              "\n                            "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "posts__meta" }, [
                                    _c(
                                      "div",
                                      { staticClass: "posts__detail" },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "14",
                                              height: "15"
                                            }
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                fill: "#b22222",
                                                "fill-rule": "evenodd",
                                                d:
                                                  "M7 .62a7 7 0 0 1 7 6.98 7 7 0 0 1-7 6.98A7 7 0 0 1 0 7.6 7 7 0 0 1 7 .62zm0 1.5A5.5 5.5 0 0 0 1.5 7.6 5.5 5.5 0 0 0 7 13.08a5.5 5.5 0 0 0 5.5-5.48A5.5 5.5 0 0 0 7 2.12zM7 3.8c.38 0 .69.31.69.69h0v3.48l1.84 1.33c.31.23.37.66.15.96h0l-.07.08c-.1.1-.23.17-.38.19a.7.7 0 0 1-.11.01c-.14 0-.27-.04-.39-.12h0 0l-.01-.01-2.4-1.74h.01-.01V4.48c0-.38.31-.69.69-.69z"
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(
                                          _vm._s(eventData.performanceDates)
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    eventData.venues &&
                                    eventData.venues.length > 0
                                      ? _c(
                                          "div",
                                          { staticClass: "posts__detail" },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  width: "13",
                                                  height: "16"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    fill: "#b22222",
                                                    "fill-rule": "evenodd",
                                                    d:
                                                      "M6.53.06a5.9 5.9 0 0 1 4.28 1.91c1.12 1.17 1.77 2.69 1.77 4.17 0 2.13-1.64 4.94-4.87 8.36h0l-.22.23c-.26.27-.61.41-.96.41-.29 0-.58-.1-.83-.29h0l-.01-.01-.1-.09C2.2 11.2.48 8.31.48 6.13c0-1.47.64-2.99 1.77-4.17A5.9 5.9 0 0 1 6.53.06zm0 1.52c-2.35 0-4.57 2.22-4.57 4.56 0 1.65 1.54 4.12 4.57 7.35 3.03-3.22 4.57-5.7 4.57-7.35 0-2.34-2.22-4.56-4.57-4.56zm0 1.42a3.1 3.1 0 0 1 3.1 3.09 3.1 3.1 0 0 1-3.1 3.09 3.1 3.1 0 0 1-3.1-3.09A3.1 3.1 0 0 1 6.53 3h0zm0 1.47c-.89 0-1.62.73-1.62 1.62S5.63 7.7 6.53 7.7s1.62-.73 1.62-1.62-.73-1.62-1.62-1.62z"
                                                  }
                                                })
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  eventData.venues.join(", ")
                                                ) +
                                                "\n                            "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  eventData.contentMetaDescription &&
                                  eventData.contentMetaDescription.length > 0
                                    ? _c("div", {
                                        staticClass:
                                          "posts__content posts__content_extended_3",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            eventData.contentMetaDescription
                                          )
                                        }
                                      })
                                    : [
                                        eventData.productionSeasonDescriptionLong &&
                                        eventData
                                          .productionSeasonDescriptionLong
                                          .length > 0
                                          ? _c("div", {
                                              staticClass:
                                                "posts__content posts__content_extended_3",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  eventData.productionSeasonDescriptionLong
                                                )
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "posts__foot" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "posts__more",
                                          attrs: { href: eventData.contentUrl }
                                        },
                                        [_vm._v("Event details »")]
                                      ),
                                      _vm._v(" "),
                                      eventData.state > 1
                                        ? _c(
                                            "span",
                                            { staticClass: "posts__divider" },
                                            [_vm._v("|")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      eventData.contentBookUrlOverride
                                        ? [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "posts__more",
                                                attrs: {
                                                  href:
                                                    eventData.contentBookUrlOverride
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    eventData.contentBookUrlNameOverride
                                                  ) + " »"
                                                )
                                              ]
                                            )
                                          ]
                                        : [
                                            eventData.performanceDates.includes(
                                              " - "
                                            )
                                              ? [
                                                  eventData.state == 2
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "posts__more",
                                                          attrs: {
                                                            href:
                                                              eventData.subscribeUrl
                                                          }
                                                        },
                                                        [_vm._v("Subscribe »")]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  eventData.state == 3
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "posts__more",
                                                          attrs: {
                                                            href:
                                                              eventData.contentUrl +
                                                              "#schedule"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Access presale »"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  eventData.state > 3
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "posts__more",
                                                          attrs: {
                                                            href:
                                                              eventData.contentUrl +
                                                              "#schedule"
                                                          }
                                                        },
                                                        [_vm._v("Order now »")]
                                                      )
                                                    : _vm._e()
                                                ]
                                              : [
                                                  eventData.state == 2
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "posts__more",
                                                          attrs: {
                                                            href:
                                                              eventData.subscribeUrl
                                                          }
                                                        },
                                                        [_vm._v("Subscribe »")]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  eventData.state == 3
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "posts__more",
                                                          attrs: {
                                                            href:
                                                              eventData.bookUrl
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Access presale »"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  eventData.state > 3
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "posts__more",
                                                          attrs: {
                                                            href:
                                                              eventData.bookUrl
                                                          }
                                                        },
                                                        [_vm._v("Order now »")]
                                                      )
                                                    : _vm._e()
                                                ]
                                          ]
                                    ],
                                    2
                                  )
                                ],
                                2
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.loading
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "loading" }),
                      _vm._v(" "),
                      _vm._l(_vm.loadingSkeletons, function(index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "calendar__section skeleton-container"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "calendar__date skeleton" },
                              [_vm._v(" ")]
                            ),
                            _vm._v(" "),
                            _vm._m(0, true)
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "calendar__col calendar__col__container",
              class: _vm.calendarOpen ? "open" : ""
            },
            [
              _c("calendarpicker", {
                ref: "CalendarPicker",
                attrs: {
                  initialSelectedDate: _vm.selectedDate,
                  events: _vm.calendarEvents
                },
                on: {
                  onSelectDay: _vm.onSelectDay,
                  onChangeViewDate: _vm.onChangeViewDate
                },
                scopedSlots: _vm._u([
                  {
                    key: "footer",
                    fn: function() {
                      return [_vm._v(" ")]
                    },
                    proxy: true
                  }
                ])
              }),
              _vm._v(" "),
              _c("div", { staticClass: "calendar__col__buttons mobile-only" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.setToday()
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "btn__circle" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "btn__title" }, [_vm._v("Today")])
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.linkbuttons && _vm.linkbuttons.length > 0
                ? _c(
                    "div",
                    { staticClass: "calendar__col__buttons" },
                    _vm._l(_vm.linkbuttons, function(linkbutton) {
                      return _c(
                        "a",
                        {
                          key: linkbutton.name,
                          staticClass: "btn",
                          attrs: {
                            target: linkbutton.target ? linkbutton.target : "",
                            href: linkbutton.url
                          }
                        },
                        [
                          _c("div", { staticClass: "btn__circle" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "btn__title" }, [
                            _vm._v(_vm._s(linkbutton.name))
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "calendar__foot" }, [
          _vm.canShowMore
            ? _c(
                "a",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.nextPage($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "btn__circle" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "btn__title" }, [
                    _vm._v("Show more events")
                  ])
                ]
              )
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "calendar__posts posts posts_calendar" }, [
      _c("div", { staticClass: "posts__item" }, [
        _c("div", { staticClass: "posts__preview skeleton" }, [
          _c("div", { staticClass: "posts__pic skeleton" }, [_vm._v(" ")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "posts__inner" }, [
          _c("div", { staticClass: "posts__link skeleton" }, [_vm._v(" ")]),
          _vm._v(" "),
          _c("div", { staticClass: "posts__title skeleton h6" }, [_vm._v(" ")]),
          _vm._v(" "),
          _c("div", { staticClass: "posts__meta skeleton" }, [
            _c("div", { staticClass: "posts__detail" }, [_vm._v(" ")]),
            _vm._v(" "),
            _c("div", { staticClass: "posts__detail skeleton" }, [_vm._v(" ")])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "posts__content posts__content_extended skeleton" },
            [
              _c("p", [_vm._v(" ")]),
              _vm._v(" "),
              _c("p", [_vm._v(" ")]),
              _vm._v(" "),
              _c("p", [_vm._v(" ")])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "posts__foot skeleton" }, [
            _vm._v(
              "\n                                 \n                            "
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }