var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accordion__item" },
    [
      _c(
        "button",
        {
          staticClass: "accordion__trigger",
          class: { accordion__trigger_active: _vm.visible },
          on: { click: _vm.open }
        },
        [_vm._t("accordion-trigger")],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { name: "accordion" },
          on: {
            enter: _vm.start,
            "after-enter": _vm.end,
            "before-leave": _vm.start,
            "after-leave": _vm.end
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible"
                }
              ],
              staticClass: "accordion__content"
            },
            [_vm._t("accordion-content")],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }