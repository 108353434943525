var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "event-summary" },
    _vm._l(_vm.items, function(item) {
      return _c("div", { key: item.id, staticClass: "event-summary-item" }, [
        _c("div", { staticClass: "event-summary-item-c" }, [
          _c("div", {
            staticClass: "event-summary-item-title",
            domProps: { innerHTML: _vm._s(_vm.getTitle(item)) }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "event-summary-item-date" }, [
            _vm._v(_vm._s(_vm.getDate(item)))
          ])
        ]),
        _vm._v(" "),
        item.state == _vm.SALE_STATE_ANNOUNCEMENT
          ? _c(
              "a",
              {
                staticClass: "btn btn_sm btn_secondary",
                attrs: { href: _vm.getUrl(item) }
              },
              [_vm._v(_vm._s(_vm.getAnnoucementText(item)))]
            )
          : _vm._e(),
        _vm._v(" "),
        item.state == _vm.SALE_STATE_SUBSCRIPTION
          ? _c(
              "a",
              { staticClass: "btn btn_sm", attrs: { href: _vm.getUrl(item) } },
              [_vm._v(_vm._s(item.orderText || "Subscribe"))]
            )
          : _vm._e(),
        _vm._v(" "),
        item.state == _vm.SALE_STATE_PRESALE
          ? _c(
              "a",
              { staticClass: "btn btn_sm", attrs: { href: _vm.getUrl(item) } },
              [_vm._v(_vm._s(item.orderText || "Access presale"))]
            )
          : _vm._e(),
        _vm._v(" "),
        item.state == _vm.SALE_STATE_SALE
          ? _c(
              "a",
              { staticClass: "btn btn_sm", attrs: { href: _vm.getUrl(item) } },
              [_vm._v(_vm._s(item.orderText || "Order now"))]
            )
          : _c("div", { staticClass: "placeholder" })
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }