var Flickity = require('flickity')
require('flickity-imagesloaded')
var ClipboardJS = require('clipboard')
import { SplitText } from './gsap-SplitText3'
import ScrollBooster from 'scrollbooster'
import { FlickityA11yFix } from './utils'

window.addEventListener('touchstart', function onFirstTouch() {
    document.body.classList.add('user-touched');
    window.USER_TOUCHED = true;
    window.removeEventListener('touchstart', onFirstTouch, false);
}, false);

var carouselArray = []

$(document).ready(function () {
    smoothScrollToNamedAnchors()
    animateButtons()
    peopleCarousels()
    carousels()
    articleToggle()
    tweetBlocks()
    instaBlocks()
    playlists()
    customBigCursor()
    svgAnim()
    heroVideo()
    initTextAnims()
    initPortraitAnims()
    notification()
    new ClipboardJS('.clipboardcopy')
    videoMuteControl()
    subnavScroll()
    handleUmbForms()
    fixHeaders()
})

function subnavScroll() {
    var s = document.querySelector('.subnav .primary')
    if (s != null) {
        window.setTimeout(function () {
            var sb = new ScrollBooster({
                viewport: s,
                content: s.querySelector('.scrollable'),
                scrollMode: 'native',
                direction: 'horizontal',
            })
        }, 500)
    }
}

function videoMuteControl() {
    $('.video-control').click(function () {
        $('.main.video video').each(function () {
            var vid = this
            vid.muted = !vid.muted
            if (vid.muted) {
                vid.setAttribute('muted', '')
            } else {
                vid.removeAttribute('muted')
                vid.volume = 0.5
            }
        })
    })
}

function animateButtons() {
    //manipulate rte buttons
    var rteBtns = $('.rteRedButton')
    if (rteBtns.length) {
        rteBtns.each(function () {
            var b = $(this),
                bh = '<div class="btn__circle" ></div><div class="btn__title">' + this.innerHTML + '</div>'
            b.addClass('btn')
            b.html(bh)
        })
    }

    $(".btn").mouseenter(function (e) {
        var _this = $(this),
            circle = _this.find('.btn__circle'),
            parentOffset = $(this).offset(),
            relX = e.pageX - parentOffset.left,
            relY = e.pageY - parentOffset.top;

        circle.css({ "left": relX, "top": relY });
        circle.removeClass("desplode-circle");
        circle.addClass("explode-circle");
    });

    $(".btn").mouseleave(function (e) {
        var _this = $(this),
            circle = _this.find('.btn__circle'),
            parentOffset = $(this).offset(),
            relX = e.pageX - parentOffset.left,
            relY = e.pageY - parentOffset.top;

        circle.css({ "left": relX, "top": relY });
        circle.removeClass("explode-circle");
        circle.addClass("desplode-circle");
    });
}

function articleToggle() {
    var articleSection = $('#articleSection');
    if (articleSection.length) {
        $('.article-view-toggler').on('click', function (e) {
            e.preventDefault()
            articleSection.toggleClass('expanded')

            if (carouselArray.length) {
                carouselArray.forEach(element => element.resize())
            }
        })
    }
}

function peopleCarousels() {
    $('.people-filters .tabs').each(function () {
        var el = $(this),
            wrap = el.find('.tabs__container'),
            count = wrap.find('.persons').length,
            isloaded = true

        var flkty = new Flickity(wrap[0], {
            freeScroll: false,
            wrapAround: true,
            pageDots: false,
            autoPlay: false,
            fullscreen: false,
            adaptiveHeight: true,
            prevNextButtons: false,
            imagesLoaded: true,
            cellSelector: '.persons',
            cellAlign: 'left',
            on: {
                ready: function () {
                    FlickityA11yFix(this.element);
                },
                change: function () {
                    FlickityA11yFix(this.element);
                }
            }
        });

        var tabButtons = el.find('.tabs__nav .tabs__btns .tabs__btn');
        $(tabButtons[0]).addClass('active');

        flkty.on('change', function (index) {
            $(tabButtons).removeClass('active');
            $(tabButtons[index]).addClass('active');
        });

        tabButtons.each(function (index) {
            $(this).on('click', function (e) {
                flkty.select(index);
            });
        });

    });
}

function initPortraitAnims() {

    var postSessions = $('.posts_sessions');
    if (postSessions) postSessions = postSessions[0];
    if (postSessions) {
        ScrollTrigger.create({
            trigger: ".posts_sessions",
            start: "top bottom",
            end: "bottom bottom",
            onEnter: function (trigger) {
                if (trigger.progress == 1 || trigger.isActive) {
                    postSessions.classList.add("active");
                }
            },
            onToggle: function (trigger) {
                if (trigger.direction < 0 && !trigger.isActive) {
                    postSessions.classList.remove("active");
                }
            }
        });
    }

    var announcer = $('.announcer');
    if (announcer && announcer.length) {
        announcer = announcer[0];

        ScrollTrigger.create({
            trigger: ".announcer",
            start: "top 60%",
            end: "bottom bottom",
            onEnter: function (trigger) {
                if (trigger.progress == 1 || trigger.isActive) {
                    announcer.classList.add("active");
                }
            },
            onToggle: function (trigger) {
                if (trigger.direction < 0 && !trigger.isActive) {
                    announcer.classList.remove("active");
                }
            }
        });

    }
}

function initTextAnims() {
    var height = window.innerHeight
    var mob = height < 700
    $('.text-anim').each(function () {
        var el = this
        var $el = $(this)
        var stagger = parseFloat($el.data('stagger')) || 0.003
        var delay = parseFloat($el.data('delay')) || 0
        var duration = parseFloat($el.data('duration')) || .9
        var x = parseInt($el.data('x')) || 0
        var y = mob ? 20 : parseInt($el.data('y')) || 50

        const splitTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: el,
                start: 'top 90%',
                end: 'bottom 0%',
                onToggle: self => gsap.to(el, { opacity: self.isActive ? 1 : 0 }),
                toggleActions: 'restart pause restart none',
            }
        })

        const split = new SplitText(el, { type: 'words,chars' })
        splitTimeline.from(split.chars, {
            duration: duration,
            delay: delay,
            opacity: 0,
            x: x,
            y: y,
            ease: 'cubic-bezier(0, 0.1, 0, 1)',
            yoyo: true,
            stagger: stagger
        })
    })
}

function carousels() {
    $('.carousel').each(function () {

        var el = $(this),
            wrap = el.find('.carousel__items'),
            count = wrap.find('.carousel__item').length,
            nextEl = el.find('.carousel__next')[0],
            isloaded = false

        var cellAlign = 'left';
        // if (el.hasClass('media-carousel')) {
        //     cellAlign = 'left';
        // }

        var flkty = new Flickity(wrap[0], {
            wrapAround: (count > 2),
            pageDots: false,
            autoPlay: false,
            prevNextButtons: false,
            cellSelector: '.carousel__item',
            cellAlign: cellAlign,
            lazyLoad: 2,
            on: {
                ready: function () {
                    FlickityA11yFix(this.element);
                },
                change: function () {
                    FlickityA11yFix(this.element);
                }
            }
        })

        // enable prev/next buttons at 768px
        //if (matchMedia('screen and (min-width: 768px)').matches) {
        //    options.prevNextButtons = false;
        //}

        carouselArray.push(flkty)

        var progress = el.find('.carousel__position')[0]
        flkty.on('change', function (index) {
            progress.style.width = (((index + 1) / count) * 100) + '%'
            nextEl.style.left = (Math.ceil(flkty.selectedElement.offsetWidth) + 5) + 'px'
            
            if (el.hasClass('media-carousel')) {
                if (flkty.selectedElement.classList.contains('carousel__item_video')) {
                    el.addClass('media-carousel-is-video')
                } else {
                    el.removeClass('media-carousel-is-video')
                }
            }

            addHoverCaption(flkty.selectedElement)
        })

        var prev = el.find('.carousel__prev')[0]
        if (prev) {
            prev.addEventListener('click', function () {
                flkty.previous(true)
            })
        }
        var next = el.find('.carousel__next')[0]
        next.addEventListener('click', function () {
            flkty.next(true)
        })

        prev = el.find('.carousel__btns__prev')[0]
        if (prev) {
            prev.addEventListener('click', function () {
                flkty.previous(true)
            })
        }

        next = el.find('.carousel__btns__next')[0]
        if (next) {
            next.addEventListener('click', function () {
                flkty.next(true)
            })
        }

        //positioning and progress initialization for video as first cell
        if (el.hasClass('media-carousel') && flkty.selectedElement.classList.contains('carousel__item_video')) {
            isloaded = true
            el.addClass('media-carousel-is-video')
            nextEl.style.left = (Math.ceil(flkty.selectedElement.offsetWidth) + 10) + 'px'
            progress.style.width = ((1 / count) * 100) + '%'
        }

        //positioning and progress initialization for lazy loaded image as first cell
        flkty.on('lazyLoad', function (event, cellElement) {
            if (!isloaded && (cellElement == flkty.selectedElement)) {
                isloaded = true
                nextEl.style.left = (Math.ceil(flkty.selectedElement.offsetWidth) + 5) + 'px'
                addHoverCaption(flkty.selectedElement)
                progress.style.width = ((1 / count) * 100) + '%'
            }
        });
    })
}

function playlists() {
    $('.playlists__carousel').each(function () {
        var el = $(this),
            pt = el.parent(),
            count = el.find('.playlists__item').length,
            initIndex = (window.innerWidth > 1280) ? 1 : 0

        var flkty = new Flickity(this, {
            pageDots: false,
            autoPlay: false,
            prevNextButtons: false,
            cellSelector: '.playlists__item',
            cellAlign: 'center',
            initialIndex: initIndex,
            lazyLoad: 2,
            on: {
                ready: function () {
                    FlickityA11yFix(this.element);
                },
                change: function () {
                    FlickityA11yFix(this.element);
                }
            }
        })

        var progress = pt.find('.playlists__position')[0]
        progress.style.width = (((initIndex + 1) / count) * 100) + '%'

        flkty.on('change', function (index) {
            progress.style.width = (((index + 1) / count) * 100) + '%'
        })

        var prev = el.find('.playlists__prev')[0]
        prev.addEventListener('click', function () {
            flkty.previous(true)
        })
        var next = el.find('.playlists__next')[0]
        next.addEventListener('click', function () {
            flkty.next(true)
        })
    })
}

function smoothScrollToNamedAnchors() {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            let anchorName = this.getAttribute('href');
            if (anchorName != '#') {
                var el = document.querySelector(anchorName);
                if (el) {
                    el.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            }
        });
    });

    let goTopEl = document.getElementById('go-top')
    //observe where the body is to show back to top anchor
    let pixio = new IntersectionObserver(entry => {
        if (!entry[0].isIntersecting) {
            goTopEl.classList.add('pinned')
        }
        else {
            goTopEl.classList.remove('pinned')
        }
    })
    pixio.observe(document.getElementById('scroll-pixel'))
}

function addHoverCaption(el) {
    var cptn_container = el.querySelector('.carousel__caption'),
        cptn = el.querySelector('.cptn')

    if (cptn && !cptn_container.classList.contains('overflown')) {

        if (cptn.scrollWidth > cptn.clientWidth) {
            cptn.classList.add('overflown')
            var hvr_cptn = $(cptn).clone()
            hvr_cptn.addClass('hover__caption').removeClass('cptn overflown')
            $(cptn_container).append(hvr_cptn)
        }
    }
}

function customBigCursor(element) {
    if (element === undefined) element = document;

    const wcursor = document.getElementById('wap__cursor')
    const wtriggers = element.getElementsByClassName('cursor__trigger')

    if (wtriggers.length) {
        for (var i = 0; i < wtriggers.length; i++) {
            wtriggers[i].addEventListener('mouseenter', (e) => {
                wcursor.classList.add('visible')
                if (e.target.classList.contains('prev')) { wcursor.classList.add('previous') }
                else { wcursor.classList.remove('previous') }
            })
            wtriggers[i].addEventListener('mouseleave', () => {
                wcursor.classList.remove('visible')
            })
            wtriggers[i].addEventListener('mousemove', (e) => {
                wcursor.style.top = (e.clientY - 50) + 'px'
                wcursor.style.left = (e.clientX - 50) + 'px'
            })
        }
    }
}

function tweetBlocks() {
    $('.tweet-block').each(function () {
        var id = $(this).data('tweet-id')
        twttr.widgets.createTweet(id, this,
            {
                conversation: 'none',
                cards: 'hidden',
                linkColor: '#C8102E',
                theme: 'light',
            }).then(el => {
                el.setAttribute('style', '')
            })
    })
}

function instaBlocks() {
    $('.insta-block').each(function () {
        var $block = $(this)
        var postUrl = $block.data('url')
        var url = `https://api.instagram.com/oembed/?omitscript=1&url=${postUrl}`
        $.get(url, function (data) {
            $block.html(data.html)
            instgrm.Embeds.process()
        })
    })
}

function svgAnim() {
    $('.logo-svg').each(function () {
        var svg = this,
            trg = $('#header-vm')
        ScrollTrigger.create({
            trigger: trg,
            start: "top top+=100px",//"top 50%",
            end: "300",//"bottom 40%",
            onEnter: function (progress, direction, isActive) {
                $(svg).removeClass('pre')
            },
            onLeave: function (progress, direction, isActive) {
                $(svg).addClass('out')
            },
            onEnterBack: function (progress, direction, isActive) {
                $(svg).removeClass('out')
            },
            onLeaveBack: function (progress, direction, isActive) {
                $(svg).addClass('pre')
            },
        })
    })
}

function heroVideo() {
    $('.main.video video').each(function () {
        var $elem = $(this)
        var vid = this
        window.setTimeout(function () {
            //optional logic for better site speed metrics on mobile
            //if (window.innerWidth > 768) { }
            $elem.attr('preload', 'auto')
            vid.autoplay = true
            vid.load()
            vid.play()
        }, 2500)
    })
}

function notification() {
    $('.alert[data-alert-key]').each(function () {

        let key = this.dataset['alertKey'];

        // check for dismissed in localStorage and if not present display
        // sessionStorage flagged if link clicked - localStorage if actually closed
        if ((window.localStorage && window.localStorage.getItem(key)) ||
            (window.sessionStorage && window.sessionStorage.getItem(key))) {
            // hide - remove
            this.remove();
        } else {
            // show
            this.classList.add("show");
        }

        // attach close handler - thats sets localStorage dismissed key
        $('.alert[data-alert-key] .alert__close').click(function () {

            if (window.localStorage) window.localStorage.setItem(key, 1);

            this.classList.add("closed");

            setTimeout(function () {
                this.remove();
            }.bind(this), 1000);

        }.bind(this));

        // also when anchor clicked!
        $('.alert[data-alert-key] a').click(function () {

            if (window.sessionStorage) window.sessionStorage.setItem(key, 1);

        }.bind(this));
    })
}

function handleUmbForms() {
    var ufforms = $(".umbraco-forms-form form")
    var ufsubmits = ufforms.find('input[type=submit]:not(.cancel)')

    ufforms.on('submit', function (e) {
        if ($(this).valid()) {
            ufsubmits.attr("disabled", true)
            ufsubmits.parents('.umb__form_submit_row').addClass('awaiting')
        }
    })
}

function fixHeaders() {
    // hack to fix header levels
    var level=0, plevel=[0];
    $("main h1, main h2, main h3, main h4, main h5, main h6").each(function() {
      var c_level=$(this)[0].tagName.substr(1);
      var p_level=plevel[plevel.length-1];

      while (c_level<p_level) {
        plevel.pop();
        level--;
        p_level=plevel[plevel.length-1];
      }

      if (c_level>p_level) {
        level++;
        plevel.push(c_level);
      }

      if (c_level!=level) {

        // create replacement
        var replacement = document.createElement("h" + level);
        replacement.innerHTML = $(this).html();

        // copy attributes
        var attributes = $(this).prop("attributes");
        $.each(attributes, function() {
            $(replacement).attr(this.name, this.value);
        });

        // and add hx class of original level - to retain styling
        replacement.classList.add("h"+c_level);

        $(this).replaceWith(replacement);
    }
   });
}

