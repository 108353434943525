<template>
    <div class="calendarpicker">
        <div class="calendarpicker__header">
            <div tabindex="0" class="previous handle" @keyup.enter="previous" @click="previous" title="Previous Month">
            </div>
            <div class="month-title">{{ viewMonthTitle }}</div>
            <div tabindex="0" class="next handle" @keyup.enter="next" @click="next" title="Next Month">
            </div>
        </div>
        <div class="calendarpicker__body">

            <div class="row weekdays">
                <div :key="('w' + weekdayIndex)" v-for="(weekday, weekdayIndex) in weekdays" class="cell weekday">{{ weekday }}</div>
            </div>

            <div :key="'week'+week" v-for="week in weeksInViewMonth" class="row week">

                <div tabindex="0" :key="('w'+week + '-d' + day)" v-for="day in weekdays.length" @keyup.enter="clicked(week, day)" @click="clicked(week, day)" class="day" :class="getClassForWeekDay(week, day)">
                    {{ getDateForWeekDay(week, day).date.date() }}
                </div>
            </div>


        </div>
        <div class="calendarpicker__footer"><slot name="footer"></slot></div>
    </div>
</template>

<script>
    import Vue from 'vue'
    //import moment from 'moment';
    import moment from 'moment-timezone';

    export default {
        props: {
            todaysDate: {
                type: Date,
                default: function() { return moment.tz(this.tz).startOf('day').toDate(); }
            },
            initialViewDate: {
                type: Date,
                default: function() { return moment.tz(this.tz).startOf('day').toDate(); }
            },
            initialSelectedDate: {
                type: Date,
                default: null
            },
            events: {
                type: Array,
                default: []
            }
        },
        data() {
            return {
                selectedMoment: moment(this.initialSelectedDate),
                viewDate: this.initialViewDate,
                weekdays: ['S', 'M', 'T', 'W', 'TH', 'F', 'S'],
                tz: "America/Chicago",
                moment: moment
            }
        },
        computed: {
            todaysMoment() {
                return moment.tz(this.todaysDate, this.tz);
            },
            viewMoment() {
                return moment.tz(this.viewDate, 'UTC').date(1).tz(this.tz);
            },
            viewMonthTitle() {
                return this.viewMoment.tz('UTC').format("MMM YYYY");
            },
            daysInViewMonth() {
                return this.viewMoment.tz('UTC').daysInMonth();
            },
            firstDayOfViewMonth() {
                return this.viewMoment.clone().date(1).day();
            },
            weeksInViewMonth() {
                let daysToDisplay = this.firstDayOfViewMonth + this.daysInViewMonth;
                let rows =  Math.floor(daysToDisplay / 7);
                if (daysToDisplay % 7 > 0) {
                    rows = rows + 1;
                }
                return rows;
            }
        },
        methods: {
            updateDate(selected) {
                this.selectedMoment = moment(selected);
                this.viewDate = selected;
            },
            getClassForWeekDay(week, day) {
                let classes = "";
                var data = this.getDateForWeekDay(week, day);

                if (data.inMonth) classes = classes + " in-month";
                if (data.selected) classes = classes + " selected";
                if (data.today) classes = classes + " today";
                if (data.isPast) classes = classes + " past";

                // check against event list for a match
                if (this.events && this.events.length > 0) {
                    let event = this.events.find(function(e) {
                        return data.date.isSame(e.date, 'day');
                    });
                    if (event && event.class) {
                        classes = classes + " event " + event.class;
                    } else if (event) {
                        classes = classes + " event";
                    }
                }

                return classes;
            },
            getDateForWeekDay(week, day) {
                let weekIndex = week - 1;
                let dayIndex = day - 1;
                let dayInMonth = (weekIndex * 7) + dayIndex;
                let inMonth = true;
                let dayOfMonth = null;
                let d = null;

                if (this.firstDayOfViewMonth > dayInMonth) {
                    // before start of month
                    inMonth = false;
                    dayOfMonth = ((dayInMonth+1) - this.firstDayOfViewMonth) % this.daysInViewMonth;
                    d = this.viewMoment.clone().date(dayOfMonth);

                } else if (this.daysInViewMonth < ((dayInMonth + 1) - this.firstDayOfViewMonth)) {
                    // after the end of the month
                    inMonth = false;
                    dayOfMonth = ((dayInMonth+1) - this.firstDayOfViewMonth) % this.daysInViewMonth;
                    d = this.viewMoment.clone().date(dayOfMonth).add(1, 'months')
                } else {
                    // current month
                    dayOfMonth = 1 + ((dayInMonth - this.firstDayOfViewMonth) % this.daysInViewMonth);
                    d = this.viewMoment.clone().date(dayOfMonth);
                }

                return {
                    today: d.isSame(this.todaysMoment),
                    selected: d.isSame(this.selectedMoment, 'day'),
                    date: d.clone(),
                    dow: d.day(),
                    inMonth: inMonth,
                    isPast: d.isBefore(this.todaysMoment)
                };
            },
            previous: function() {
                let vm = this.viewMoment.subtract(1, 'months');
                this.viewDate = vm.toDate();
                //this.$emit('onChangeViewDate', vm);
                this.selectedMoment = vm.clone().tz('UTC');
                this.$emit('onSelectDay', this.selectedMoment, false);
            },
            next: function() {
                let vm = this.viewMoment.add(1, 'months');
                this.viewDate = vm.toDate();
                //this.$emit('onChangeViewDate', vm);
                this.selectedMoment = vm.clone().tz('UTC');
                this.$emit('onSelectDay', this.selectedMoment, false);
            },
            clicked: function(week, day) {
                let data = this.getDateForWeekDay(week, day);
                if (!data.inMonth) {
                    this.viewDate = data.date.clone().date(1);
                }
                let date = data.date.tz(this.tz).clone();
                this.selectedMoment = date;
                this.$emit('onSelectDay', this.selectedMoment, true);
            }
        },

        mounted() {



        }
    }
</script>
