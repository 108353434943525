import Vue from 'vue'

import VueResource from 'vue-resource'
Vue.use(VueResource)

import VLazyImage from 'v-lazy-image/v2'
import Multiselect from 'vue-multiselect'
import ImageZoom from 'js-image-zoom'

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

import { EventBus } from './vues/event-bus'

import { babelPolyfill } from 'babel-polyfill'

Vue.config.silent = false
Vue.config.devtools = true

// components
Vue.component('accordion', require('./components/Accordion.vue').default)
Vue.component('accordion-item', require('./components/AccordionItem.vue').default)
Vue.component('dropdown', require('./components/Dropdown.vue').default)
Vue.component('expbutton', require('./components/ExplodeButton.vue').default)
Vue.component('pager', require('./components/Pager.vue').default)
Vue.component('contributors', require('./components/Contributors.vue').default)
Vue.component('related', require('./components/Related.vue').default)
Vue.component('latest-cso-radio', require('./components/LatestCSORadio.vue').default)
Vue.component('now-streaming', require('./components/NowStreaming.vue').default)
Vue.component('date-range-picker', require('./components/DateRangePicker.vue').default)
Vue.component('length-picker', require('./components/LengthPicker.vue').default)
Vue.component('curated-group', require('./components/CuratedGroup.vue').default)
Vue.component('popular', require('./components/Popular.vue').default)
Vue.component('relatedsearch', require('./components/RelatedSearch.vue').default)
Vue.component('relatedevents', require('./components/RelatedEvents.vue').default)
Vue.component('calendar', require('./components/Calendar.vue').default)
Vue.component('calendarpicker', require('./components/CalendarPicker.vue').default)
Vue.component('toggleswitch', require('./components/ToggleSwitch.vue').default)
Vue.component('scriptembedblock', require('./components/ScriptEmbedBlock.vue').default)
Vue.component('eventsummary', require('./components/EventSummary.vue').default)
Vue.component('upcomingevent', require('./components/UpcomingEvent.vue').default)
Vue.component('relatedspotlight', require('./components/RelatedSpotlight.vue').default)
Vue.component('eventscarousel', require('./components/EventsCarousel.vue').default)
Vue.component('seriescarousel', require('./components/SeriesCarousel.vue').default)
Vue.component('micromodal', require('./components/Modal.vue').default)
Vue.component('schedule', require('./components/Schedule.vue').default)
Vue.component('packageoptions', require('./components/PackageOptions.vue').default)
Vue.component('relatedperformances', require('./components/RelatedPerformances.vue').default)
Vue.component('tagfiltergroup', require('./components/TagFilterGroup.vue').default)
Vue.component('tagfilter', require('./components/TagFilter.vue').default)
Vue.component('v-lazy-image', VLazyImage)
Vue.component('multiselect', Multiselect)
Vue.component('burger-menu', require('./components/BurgerMenu.vue').default)

Vue.mixin({
    methods: {
        getQs() {
            return window.location.search
        },
        setQs(value) {
            if (!value) {
                value = '?'
            }
            window.history.pushState('', '', value)
        },
        imgLoaded(e) {
            if (e.parentElement.closest('.article') != null && e.parentElement.closest('.imagery.zoom') != null) {
                var options = {
                    fillContainer: true,
                    zoomPosition: 'original',
                }
                new ImageZoom(e.parentElement, options)
            }
        }
    }
})

Vue.directive('clickout', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
})

export default Vue