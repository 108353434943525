<template>
    <div ref="parentNode" class="script-embed-block" v-html="rawContent">
    </div>
</template>

<script>
    export default {
        props: {
            scripts: {
                type: String,
                required: true
            },
            inlinescripts: {
                type: Boolean,
                default: false
            },
            firewindowload: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                rawContent: '',
                extractedScripts: []
            }
        },
        mounted: function () {

            // can't add async function as a vue method, fine here as only called from mounted
            this.mountScript = async (script) => { // returns promise once loaded

                var promise = new Promise((resolve, reject) => {

                    // either add to this component or the body
                    var parentNode = this.$refs.parentNode;
                    if (this.inlinescripts == false) {
                        parentNode = document.getElementsByTagName('body')[0];
                    }

                    // setting script as innerHTML doesn't work, but it does allow
                    // us ot get the properties out and build manually as a Script
                    const div = document.createElement("div");
                    div.innerHTML = script;

                    const scriptEl = document.createElement("script");
                    for (var i = div.firstChild.attributes.length - 1; i > -1; --i) {
                        var a = div.firstChild.attributes[i];
                        scriptEl.setAttribute(a.name, a.value);
                    }

                    if (scriptEl.src && scriptEl.src.length > 0 && scriptEl.src != window.location) {

                        scriptEl.addEventListener("load", function () {
                            resolve();
                        });
                        parentNode.append(scriptEl);

                    } else {
                        // can't just set innerHTML, create fragment instead
                        const node = document.createRange().createContextualFragment(script);
                        parentNode.append(node);

                        resolve();
                    }
                });

                return promise;
            };

            // can't add async function as a vue method, fine here as only called from mounted
            this.mountScripts = async (scripts) => {

                // sequentially add scripts
                for (let i = 0; i < scripts.length; i++) {
                    await this.mountScript(scripts[i]);
                }

                // wait for all scripts to be in the page
                if (this.firewindowload) {
                    const loadEvent = new Event('load');
                    window.dispatchEvent(loadEvent);
                }
            };

            // find the script tags
            const regexScripts = new RegExp('<script.+?<\/\s?script>', 'gi');
            const matches = this.scripts.matchAll(regexScripts);

            // extract the scripts and remove from original content
            var nonScripts = this.scripts;
            for (const match of matches) {
                this.extractedScripts.push(match[0]);
                nonScripts = nonScripts.replace(match[0], '<!-- extracted script -->');
            }

            // render the non-script content
            this.rawContent = nonScripts;

            // on next tick render script tags
            this.$nextTick(() => {
                this.mountScripts(this.extractedScripts);
            });
        }
    };

</script>
