var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "sidebar__title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _vm._l(_vm.items, function(item) {
        return _c(
          "a",
          { staticClass: "sidebar__watch", attrs: { href: item.url } },
          [
            _c("div", { staticClass: "play" }),
            _vm._v(_vm._s(item.title) + "\n    ")
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }