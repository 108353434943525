<template>
    <div class="accordion">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: ["initial-active"],
        data() {            
            return {
                Accordion: {
                    count: 0,
                    active: this.initialActive
                }
            };
        },
        provide() {
            return { Accordion: this.Accordion };
        }
    };
</script>