<template>
    <div class="schedule" v-if="performances && performances.length">
        <div class="schedule_row shead">
            <div class="schedule_col sdate">Date</div>
            <div class="schedule_col stime">Time</div>
            <div class="schedule_col saction stan" v-if="hasTickets">Tickets</div>
            <div class="schedule_col saction" :class="{sgrey:!hasTickets}" v-if="hasPackages">
                <span class="sattn">Subscribe &amp; Save</span>
                Packages
            </div>
        </div>
        <div class="schedule_row" v-for="item in performances">
            <div class="schedule_col sdate">{{item.localDateFormat}}</div>
            <div class="schedule_col stime">{{item.localTimeFormat}}</div>
            <div class="schedule_col saction stan" v-if="hasTickets">
                <span>Tickets</span>
                <a class="btn btn_sm btn_sched" :href="overrideBooking ? overrideBookingUrl : item.ticketUrl">
                    <div class="btn__circle"></div>
                    <div class="btn__title">{{overrideBooking ? overrideBookingName : bookingName}}</div>
                </a>
            </div>
            <div class="schedule_col saction" v-if="hasPackages">
                <span>Packages</span>
                <div class="schedule_options" :class="{ open: item.id == activeId }">
                    <span class="so_ttl" v-on:click="togglePackage(item.id)">Options</span>
                    <packageoptions :performance-id="item.id" :active="item.id == activeId" @on-all-concerts="packageDetails"></packageoptions>
                </div>
            </div>
        </div>

        <micromodal ref="pkgmodal" :disable-scroll="true" :show-footer="true">
            <template v-slot:body>
                <div class="schedule_modal">
                    <div class="schedule_modal_pkg" v-if="packageLoaded">
                        <div class="smp_title">{{modalPkg.title}}</div>
                        <div class="smp_desc" v-html="modalPkg.description"></div>
                        <div class="smp_concerts" v-if="modalPkg.concerts.length">
                            <div class="smp_concert" v-for="item in modalPkg.concerts">
                                <div class="smp_concert_img"><img :src="item.productionSeasonListImageUrl" :alt="item.productionSeasonListImageAlt" /></div>
                                <div class="smp_concert_content">
                                    <div v-if="item.concertTypes && item.concertTypes.length > 0" class="smp_concert_type"><span>{{ item.concertTypes ? item.concertTypes[0] : '' }}</span></div>
                                    <div class="smp_concert_name">{{item.description}}</div>
                                    <div class="smp_concert_date">{{formatDate(item.csoLocalTime)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="loading" v-if="!packageLoaded">
                        <div class="loading_bar"></div>
                        <div class="loading_text">Loading concerts...</div>
                    </div>
                    <div class="schedule_modal_nopkg" v-if="packageLoaded && !modalPkg.concerts.length">
                        No concerts found. Please <strong><a :href="modalPkg.url">visit this link</a></strong> for further details.
                    </div>
                </div>
            </template>
            <template v-slot:button>
                <a :href="modalPkg.url" class="modal__btn modal__btn-primary" aria-label="Continue" v-show="packageLoaded && modalPkg.concerts.length" v-html="modalPkg.button"></a>
            </template>
        </micromodal>

        <div class="schedule_underlay" v-if="activeId > 0" v-on:click="activeId = 0"></div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import moment from 'moment-timezone';

    export default {
        props: {
            performanceSeason: {
                type: Number,
                default: 0
            },
            bookingName: {
                type: String,
                default: ''
            },
            promo: {
                type: String,
                default: ''
            },
            overrideBooking: {
                type: Boolean,
                default: false
            },
            overrideBookingUrl: {
                type: String,
                default: ''
            },
            overrideBookingName: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                hasTickets: false,
                hasPackages: false,
                performances: [],
                activeId: 0,
                loaded: false,
                packageLoaded: false,
                modalPkg: {
                    id: 0,
                    url: '',
                    title: '',
                    description: '',
                    button: '',
                    concerts: []
                }
            }
        },
        methods: {
            togglePackage(aid) {
                if (this.activeId == aid) { this.activeId = 0 }
                else { this.activeId = aid }
            },
            packageDetails(pkgId, pkgUrl) {
                var self = this
                this.packageLoaded = false
                this.$refs.pkgmodal.open(null, 'default')

                var prq = {
                    packageId: pkgId
                }
                self.$http.post('/umbraco/surface/events/PerformancesPlusInPackage', prq)
                    .then(({ data }) => {
                        self.modalPkg.id = pkgId
                        self.modalPkg.url = pkgUrl
                        self.modalPkg.title = data.title
                        self.modalPkg.description = data.description
                        self.modalPkg.button = data.button
                        self.modalPkg.concerts = data.performances
                        self.packageLoaded = true
                    })
                    .catch((error) => {
                        self.packageLoaded = true
                    })
            },
            formatDate(dte) {
                return moment(dte).format("MMM D, YYYY");
            }
        },
        mounted() {
            var self = this

            var request = {
                eventId: this.performanceSeason,
                promo: this.promo
            }
            self.$http.post('/umbraco/surface/events/ScheduleForEvent', request)
                .then(({ data }) => {
                    self.performances = data.performances
                    self.hasTickets = data.hasTickets
                    self.hasPackages = data.hasPackages
                    self.loaded = true
                })
                .catch((error) => {
                    self.loaded = true
                })

            //setTimeout(function () {
            //    self.packageDetails(2549)
            //}, 1000)
        }
    }
</script>