var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.totalpages > 1
    ? _c(
        "div",
        { staticClass: "pager" },
        [
          _c(
            "a",
            {
              staticClass: "pager__prev",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.prev($event)
                }
              }
            },
            [_vm._v("Prev")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "pager__btn",
              class: { active: 1 == _vm.currentPage },
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.select(1)
                }
              }
            },
            [_vm._v("1")]
          ),
          _vm._v(" "),
          _vm.currentPage > 4
            ? _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("...")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.pages, function(page) {
            return _c(
              "a",
              {
                staticClass: "pager__btn",
                class: { active: page == _vm.currentPage },
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.select(page)
                  }
                }
              },
              [_vm._v(_vm._s(page))]
            )
          }),
          _vm._v(" "),
          _vm.currentPage < _vm.totalpages - 3
            ? _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("...")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "pager__btn",
              class: { active: _vm.totalpages == _vm.currentPage },
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.select(_vm.totalpages)
                }
              }
            },
            [_vm._v(_vm._s(_vm.totalpages))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "pager__next",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.next($event)
                }
              }
            },
            [_vm._v("Next")]
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }