var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tune__streams streams" }, [
    _c("div", { staticClass: "streams__container container" }, [
      _c("div", { staticClass: "streams__title" }, [_vm._v("Now streaming:")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "streams__list" },
        _vm._l(_vm.firstItems, function(item) {
          return _c(
            "a",
            { staticClass: "streams__item", attrs: { href: item.url } },
            [
              _c("div", { staticClass: "play" }),
              _vm._v(_vm._s(item.shortTitle) + "\n            ")
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }