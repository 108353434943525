import Vue from '../config.js'
import { IsVisible, IsDesktop } from '../utils.js'

if (document.getElementById('cso-header-vm')) {
    var vm = new Vue({
        el: '#cso-header-vm',
        data: {
            open: false,
            mobile: false,
            htmlNode: null
        },
        methods: {
            IsDesktop: IsDesktop,
            handleHrefClick(e) {
                // handler allows default but prevents propagation
                // this.closeAllSubmenus();
                // e.preventDefault();
            },
            toggleMobile() {
                this.mobile = !this.mobile

                if (this.mobile) {
                    this.htmlNode.classList.add('navigating')
                    this.$refs.toggleMenu.classList.add('active');
                    this.$refs.headerInner.classList.add('visible');
                } else {
                    this.closeAllSubmenus();
                    this.htmlNode.classList.remove('navigating')
                    this.$refs.toggleMenu.classList.remove('active');
                    this.$refs.headerInner.classList.remove('visible');
                }
            },
            closeMobile() {
                this.mobile = false;
                this.htmlNode.classList.remove('navigating')
                this.$refs.toggleMenu.classList.remove('active');
                this.$refs.headerInner.classList.remove('visible');
            },
            menuDown(event) {
                let dropdownEl = null;
                let el = event.target.closest('.nav__action');
                if (el) {
                    dropdownEl = el.nextElementSibling;
                } else {
                    dropdownEl = event.target.closest('.nav__dropdown');
                }

                if (dropdownEl) {
                    event.preventDefault();

                    // get current state if we have a submenu
                    let isVisible = dropdownEl.classList.contains('visible');

                    // get child elements
                    let items = Array.slice(dropdownEl.querySelectorAll(":scope a,button"));

                    if (isVisible) {
                        // if open move to next item if there is one
                        let currentFocusIndex = items.indexOf(document.activeElement);
                        if (currentFocusIndex + 1 < items.length) {
                            items[currentFocusIndex + 1].focus();
                        }

                    } else {
                        // open and select first item if not already open
                        this.openSubmenu(event);
                        if (items && items.length > 0) {
                            items[0].focus();
                        }
                    }
                }
            },
            menuUp(event) {
                let dropdownEl = null;
                let el = event.target.closest('.nav__action');
                if (el) {
                    dropdownEl = el.nextElementSibling;
                } else {
                    dropdownEl = event.target.closest('.nav__dropdown');
                }

                if (dropdownEl) {
                    event.preventDefault();

                    // get current state if we have a submenu
                    let isVisible = dropdownEl.classList.contains('visible');

                    // get child elements
                    let items = Array.slice(dropdownEl.querySelectorAll(":scope a,button"));

                    if (isVisible) {
                        // if open move to previous item if there is one, or close
                        let currentFocusIndex = items.indexOf(document.activeElement);
                        if (currentFocusIndex > 0) {
                            items[currentFocusIndex - 1].focus();
                        } else {
                            // find the handle
                            let handle = dropdownEl.previousElementSibling;
                            handle.classList.remove('active');
                            handle.parentElement.classList.remove('open');
                            handle.focus();
                            dropdownEl.classList.remove('visible');
                        }

                    } else {
                        // open and select first item if not already open
                        this.openSubmenu(event);
                        if (items && items.length > 0) {
                            items[0].focus();
                        }
                    }
                }
            },
            menuLeft(event) {
                let el = event.target.closest('.nav__action');
                if (el) {
                    // move to next nav_section
                    let navSection = event.target.closest('.nav__section');
                    if (navSection.previousElementSibling) {
                        let prevItem = navSection.previousElementSibling.querySelectorAll(":scope .nav__action");
                        if (prevItem && prevItem.length) {
                            prevItem[0].focus();
                            let currentMenu = navSection.querySelectorAll(":scope .nav__dropdown");
                            if (currentMenu && currentMenu.length > 0 && currentMenu[0].classList.contains('visible')) {
                                this.openSubmenu({target: prevItem[0]});
                            } else {
                                this.closeAllSubmenus();
                            }
                        }
                    }
                } else {
                    let dropdownEl = event.target.closest('.nav__dropdown');
                    if (dropdownEl && document.activeElement) {
                        let columns = Array.slice(dropdownEl.querySelectorAll(":scope .nav__cell"));
                        let currentColumn = document.activeElement.closest('.nav__cell');
                        if (currentColumn) {
                            let currentcolumnIndex = columns.indexOf(currentColumn);
                            if (currentcolumnIndex > 0) {
                                let links = columns[currentcolumnIndex - 1].querySelectorAll(":scope a,button");
                                links[0].focus();
                            }
                        }
                    }
                }
            },
            menuRight(event) {
                let el = event.target.closest('.nav__action');
                if (el) {
                    // move to next nav_section
                    let navSection = event.target.closest('.nav__section');
                    if (navSection.nextElementSibling) {
                        let nextItem = navSection.nextElementSibling.querySelectorAll(":scope .nav__action");
                        if (nextItem && nextItem.length) {
                            nextItem[0].focus();
                            let currentMenu = navSection.querySelectorAll(":scope .nav__dropdown");
                            if (currentMenu && currentMenu.length > 0 && currentMenu[0].classList.contains('visible')) {
                                this.openSubmenu({target: nextItem[0]});
                            } else {
                                this.closeAllSubmenus();
                            }
                        }
                    }
                } else {
                    let dropdownEl = event.target.closest('.nav__dropdown');
                    if (dropdownEl && document.activeElement) {
                        let columns = Array.slice(dropdownEl.querySelectorAll(":scope .nav__cell"));
                        let currentColumn = document.activeElement.closest('.nav__cell');
                        if (currentColumn) {
                            let currentcolumnIndex = columns.indexOf(currentColumn);
                            if (currentcolumnIndex + 1 < columns.length) {
                                let links = columns[currentcolumnIndex + 1].querySelectorAll(":scope a,button");
                                links[0].focus();
                            }
                        }
                    }
                }
            },
            openSubmenu(event) {
                let el = event.target.closest('.nav__action');
                if (el) {
                    var direct = el.dataset.directOnDesktop
                    if (direct && window.innerWidth >= 1260) {
                        window.location.href = el.href
                    } else {
                        let sibling = el.nextElementSibling;
                        if (sibling) {
                            if (event.preventDefault) event.preventDefault();

                            // get current state if we have a submenu
                            let isVisible = sibling.classList.contains('visible');

                            // close all submenus
                            let menu = el.closest('.nav');
                            if (menu) {
                                let submenuhandles = menu.getElementsByClassName('nav__action');
                                for (let i = 0; i < submenuhandles.length; i++) {
                                    submenuhandles[i].classList.remove('active');
                                }
                                let submenus = menu.getElementsByClassName('nav__dropdown');
                                for (let i = 0; i < submenus.length; i++) {
                                    submenus[i].classList.remove('visible');
                                }
                            }

                            // toggle state
                            if (isVisible) {
                                el.classList.remove('active');
                                el.parentElement.classList.remove('open');
                                sibling.classList.remove('visible');
                            } else {
                                el.classList.add('active');
                                el.parentElement.classList.add('open');
                                sibling.classList.add('visible');
                            }
                        }
                    }
                }
            },
            closeSubmenu(event) {
                let el = event.target.closest('.nav__action');
                if (!el) {
                    let dropdownEl = event.target.closest('.nav__dropdown');
                    if (dropdownEl) {
                        el = dropdownEl.previousElementSibling;
                    }
                }
                if (el) {
                    var direct = el.dataset.directOnDesktop
                    if (direct && window.innerWidth >= 1260) {
                        window.location.href = el.href
                    } else {
                        let sibling = el.nextElementSibling;
                        if (sibling) {
                            if (event.preventDefault) event.preventDefault();

                            // get current state if we have a submenu
                            let isVisible = sibling.classList.contains('visible');

                            // close all submenus
                            let menu = el.closest('.nav');
                            if (menu) {
                                let submenuhandles = menu.getElementsByClassName('nav__action');
                                for (let i = 0; i < submenuhandles.length; i++) {
                                    submenuhandles[i].classList.remove('active');
                                }
                                let submenus = menu.getElementsByClassName('nav__dropdown');
                                for (let i = 0; i < submenus.length; i++) {
                                    submenus[i].classList.remove('visible');
                                }
                            }

                            el.classList.remove('active');
                            el.parentElement.classList.remove('open');
                            sibling.classList.remove('visible');
                            el.focus();
                        }
                    }
                }
            },
            documentPointerEvent(event) {
                // ignore if click was within menus
                if (this.navNode.contains(event.target) ||
                    this.headerLinksNode.contains(event.target) ||
                    this.headerSearchNode.contains(event.target)) {
                    return;
                }

                // also ignore if this was the toggle menu
                if (this.$refs.toggleMenu.contains(event.target)) {
                    return;
                }

                this.closeAllSubmenus();
                this.closeMobile();
            },
            documentOnKeyUp(event) {
                if (event.key === "Escape") {
                    this.closeAllSubmenus();
                    this.closeMobile();
                }
            },
            closeAllSubmenus() {
                let submenus = this.navNode.getElementsByClassName('nav__dropdown');
                for (let i = 0; i < submenus.length; i++) {
                    submenus[i].classList.remove('visible');
                    submenus[i].parentElement.classList.remove('open');
                }
                let submenuhandles = this.navNode.getElementsByClassName('nav__action');
                for (let i = 0; i < submenuhandles.length; i++) {
                    submenuhandles[i].classList.remove('active');
                    submenuhandles[i].parentElement.classList.remove('open');
                }
            },
            closeMobileSubmenu(event) {
                let el = event.target.closest('.nav__back');
                if (el) {
                    el.parentNode.classList.remove('visible');
                    this.closeAllSubmenus();
                }
            },
            desktopOpen(event) {

            },
            toggleSearch() {
                this.open = !this.open

                if (this.open) {
                    Vue.nextTick(() => {
                        this.$refs.searchField.focus()
                    })
                }

            }
        },
        mounted() {
            this.htmlNode = document.getElementsByTagName('html')[0];
            let navNodes = this.$el.getElementsByClassName('nav');
            if (navNodes.length > 0) this.navNode = navNodes[0];

            let headerLinksNode = this.$el.getElementsByClassName('header__links');
            if (headerLinksNode.length > 0) this.headerLinksNode = headerLinksNode[0];

            let headerSearchNode = this.$el.getElementsByClassName('header__search');
            if (headerSearchNode.length > 0) this.headerSearchNode = headerSearchNode[0];

            if (this.navNode) {
                document.addEventListener('mousedown', this.documentPointerEvent, false);
                //document.addEventListener('touchend', this.documentPointerEvent, false);
                document.addEventListener('keyup', this.documentOnKeyUp);
            }
            const observer = new IntersectionObserver(
                ([e]) => e.target.classList.toggle("is_pinned", e.intersectionRatio < 1),
                { threshold: [1] }
            )
            observer.observe(this.$el)
        },
        beforeUnmount() {
            if (this.navNode) {
                document.removeEventListener('mousedown', this.documentPointerEvent, false);
                //document.removeEventListener('touchend', this.documentPointerEvent, false);
                document.removeEventListener('keyup', this.documentOnKeyUp);
            }
        }
    })
}