<template>
    <div class="calendar-container" ref="Container">

    <div v-if="allowfilters" class="params" :class="paramsOpen ? ' open' : ''">
        <button class="params__btn btn" @click.prevent="paramsOpen = !paramsOpen" aria-label="Refine your search">
        <div class="btn__circle"></div>
        <div class="btn__title">
            <div v-if="filtersSummary && filtersSummary.length > 0" class="filter-summary">
                <div class="filter" v-for="filter in filtersSummary" :key="filter">{{ filter }}</div>
            </div>
            <div v-else>
            Refine your search
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path fill="#fff" d="M4.94.75v8.38L1.28 5.47c-.29-.29-.77-.29-1.06 0s-.29.76 0 1.06l4.94 4.94c.29.29.76.29 1.06 0l4.95-4.93c.29-.29.29-.76 0-1.06s-.77-.29-1.06 0L6.44 9.13V.75c0-.41-.34-.75-.75-.75s-.75.34-.75.75z"/></svg>
        </div>
        </button>
        <div class="params__drop">
        <div class="params__sections">
            <div class="params__section">
            <div class="params__category">Concert Type</div>
            <div class="params__inner">
                <toggleswitch :tabIndex="paramsOpen ? 0 : -1" v-for="ct in concerttypes" :key="ct" :label="ct" :checked="filterConcertTypes[ct]" @on-change="concertTypeChange" />
            </div>
            </div>
            <div class="params__section">
            <div class="params__category">Genre</div>
            <div class="params__inner">
                <toggleswitch :tabIndex="paramsOpen ? 0 : -1" v-for="x in genres" :key="x" :label="x" :checked="filterGenres[x]" @on-change="genreChange" />
            </div>
            </div>
            <div class="params__section">
            <div class="params__category">Venue</div>
            <div class="params__inner">
                <toggleswitch :tabIndex="paramsOpen ? 0 : -1" v-for="x in venues" :key="x" :label="x" :checked="filterVenues[x]" @on-change="venueChange" />
            </div>
            </div>
            <div class="params__section">
                <div class="params__category">Platform</div>
                <div class="params__inner">
                    <toggleswitch :tabIndex="paramsOpen ? 0 : -1" v-for="x in platforms" :key="x" :label="x" :checked="filterPlatforms[x]" @on-change="platformChange" />
                </div>
                <div v-if="seasonsList && seasonsList.length > 0" class="params__inner_section">
                    <div class="params__category">Season</div>
                    <div class="params__inner">
                        <template v-for="x in seasonsList">
                        <toggleswitch :tabIndex="paramsOpen ? 0 : -1" :key="x" :label="x" :checked="filterSeasons[x]" @on-change="seasonChange" />
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="params__foot">
            <button :tabIndex="paramsOpen ? 0 : -1" class="params__reset" @keyup.enter="clearFilters" @click.prevent="clearFilters" aria-label="Clear filters">Clear filters</button>
            <button :tabIndex="paramsOpen ? 0 : -1" class="params__submit btn" @keyup.enter="findEvents" @click.prevent="findEvents" aria-label="Find events">
            <div class="btn__circle"></div>
            <div class="btn__title">Find events</div>
            </button>
        </div>
        </div>
    </div>
    <div v-else class="no-params">
    </div>

    <div class="calendar calendar_results">
        <div class="calendar__row__container">
            <div class="calendar__row">
            <div class="calendar__col">

                <div class="calendar__col__row">
                    <div class="calendar__col__col">
                    <div v-if="allowpaidfilter" class="calendar__nav">
                        <!--
                        <a href="#" @click.prevent="paidFilterChange('paid')" :class="paidFilter == 'paid' ? 'active' : ''">Paid</a>
                        <a href="#" @click.prevent="paidFilterChange('free')" :class="paidFilter == 'free' ? 'active' : ''">Free</a>
                        <a href="#" @click.prevent="paidFilterChange('')" :class="paidFilter == '' ? 'active' : ''">All Events</a>
                        -->
                        <div class="form__inner umbraco-forms-field-wrapper">
                            <select aria-label="Paid/Free" v-model="paidFilterSelect" class="input-validation-valid valid">
                                <option value="paid">Paid</option>
                                <option value="free">Free</option>
                                <option value="">All events</option>
                            </select>
                        </div>
                    </div>
                    <template v-else-if="!allowfilters && seasonsList && seasonsList.length > 0">

                        <div class="series__filter">
                            <label v-if="seasonfilter && seasonfilter.FilterLabel">{{ seasonfilter.FilterLabel }}</label>
                            <template v-for="x in seasonsList">
                                <toggleswitch :key="x" :label="x" :checked="filterSeasons[x]" @on-change="seasonChangeAndUpdate" />
                            </template>
                        </div>

                    </template>
                    <template v-else>
                        <div v-if="totalResults > 0" class="calendar__results">Showing {{ shownResults }} of {{ totalResults }} Results:</div>
                    </template>
                    </div>
                    <div class="calendar__col__col calendar__controls">

                        <!-- <toggleswitch v-if="allowgroup" :label="groupProductionsLabel" :checked="groupProductions" @on-change="groupProductionsChange()" /> -->
                        <div v-if="allowgroup" class="calendar__nav">
                            <!--
                            <a href="#" @click.prevent="groupProductionsChange()" :class="groupProductions ? 'active' : ''">Productions</a>
                            <a href="#" @click.prevent="groupProductionsChange()" :class="!groupProductions ? 'active' : ''">Performances</a>
                            -->
                            <div class="form__inner umbraco-forms-field-wrapper">
                                <select aria-label="By Program/Date" v-model="groupProductionsSelect" class="input-validation-valid valid">
                                    <option value="productions">View by program</option>
                                    <option value="performances">View by date</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="calendar__col__col calendar__controls calendar__controls__calendar">

                        <div class="calendar-handle" @click="toggleCalendar">
                             <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
                                <path fill="#1b1b1b" d="M5 6h2v2h-2zM8 6h2v2h-2zM11 6h2v2h-2zM2 12h2v2h-2zM5 12h2v2h-2zM8 12h2v2h-2zM5 9h2v2h-2zM8 9h2v2h-2zM11 9h2v2h-2zM2 9h2v2h-2zM13 0v1h-2v-1h-7v1h-2v-1h-2v16h15v-16h-2zM14 15h-13v-11h13v11z"></path>
                            </svg>
                            <span>Calendar</span>
                        </div>

                    </div>
                </div>

                <template v-if="!this.groupProductions">
                    <div v-if="!loading && (!eventDays || eventDays.length == 0)" class="calendar__posts posts posts_calendar empty-calendar">
                        <div class="posts__title h6">No matching events were found</div>
                        <p v-if="allowfilters">Try widening your <a href="#" @click.prevent="paramsOpen = true">search criteria</a>.</p>
                    </div>
                    <div v-for="day in eventDays" :key="day" class="calendar__section">
                    <div class="calendar__date">{{ eventDayTitles[day] }}</div>
                    <!-- posts-->
                    <div class="calendar__posts posts posts_calendar">
                        <div v-for="performance in eventsByDay[day]" :key="performance.Id" class="posts__item">
                            <template v-if="eventData[performance.eventId]">
                            <a class="posts__preview" :href="eventData[performance.eventId].contentUrl">
                                <img class="posts__pic" :src="eventData[performance.eventId].productionSeasonListImageUrl" :alt="eventData[performance.eventId].productionSeasonListImageAlt">
                                <div v-if="seasons[eventData[performance.eventId].seasonId] && seasons[eventData[performance.eventId].seasonId].showBadge" class="posts__season_badge">{{ seasons[eventData[performance.eventId].seasonId].badgeText }}</div>
                            </a>
                            <div class="posts__inner">
                                <a v-if="eventData[performance.eventId].concertTypes && eventData[performance.eventId].concertTypes.length > 0" class="posts__link link" :href="eventData[performance.eventId].concertTypes ? '/concerts-tickets/whats-on?concert-types=' + eventData[performance.eventId].concertTypes[0] : ''">{{ eventData[performance.eventId].concertTypes ? eventData[performance.eventId].concertTypes[0] : '' }}</a>
                                <div v-if="eventData[performance.eventId].priceRange == 'Free'" class="posts__badge">Free Event</div>
                            <div class="posts__title h6">
                                <a :href="eventData[performance.eventId].contentUrl">
                                    <!-- Redman, Mehldau, McBride, Blade A MoodSwing Reunion -->
                                    {{ eventData[performance.eventId].contentTitle ||
                                    eventData[performance.eventId].description ||
                                    performance.description }}
                                </a>
                            </div>
                            <div class="posts__meta">
                                <div class="posts__detail"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="15"><path fill="#b22222" fill-rule="evenodd" d="M7 .62a7 7 0 0 1 7 6.98 7 7 0 0 1-7 6.98A7 7 0 0 1 0 7.6 7 7 0 0 1 7 .62zm0 1.5A5.5 5.5 0 0 0 1.5 7.6 5.5 5.5 0 0 0 7 13.08a5.5 5.5 0 0 0 5.5-5.48A5.5 5.5 0 0 0 7 2.12zM7 3.8c.38 0 .69.31.69.69h0v3.48l1.84 1.33c.31.23.37.66.15.96h0l-.07.08c-.1.1-.23.17-.38.19a.7.7 0 0 1-.11.01c-.14 0-.27-.04-.39-.12h0 0l-.01-.01-2.4-1.74h.01-.01V4.48c0-.38.31-.69.69-.69z"/></svg>{{ moment(performance.performanceDate).tz(tz).format("h:mm A") }}</div>
                                <div v-if="performance.venues && performance.venues.length > 0" class="posts__detail">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16"><path fill="#b22222" fill-rule="evenodd" d="M6.53.06a5.9 5.9 0 0 1 4.28 1.91c1.12 1.17 1.77 2.69 1.77 4.17 0 2.13-1.64 4.94-4.87 8.36h0l-.22.23c-.26.27-.61.41-.96.41-.29 0-.58-.1-.83-.29h0l-.01-.01-.1-.09C2.2 11.2.48 8.31.48 6.13c0-1.47.64-2.99 1.77-4.17A5.9 5.9 0 0 1 6.53.06zm0 1.52c-2.35 0-4.57 2.22-4.57 4.56 0 1.65 1.54 4.12 4.57 7.35 3.03-3.22 4.57-5.7 4.57-7.35 0-2.34-2.22-4.56-4.57-4.56zm0 1.42a3.1 3.1 0 0 1 3.1 3.09 3.1 3.1 0 0 1-3.1 3.09 3.1 3.1 0 0 1-3.1-3.09A3.1 3.1 0 0 1 6.53 3h0zm0 1.47c-.89 0-1.62.73-1.62 1.62S5.63 7.7 6.53 7.7s1.62-.73 1.62-1.62-.73-1.62-1.62-1.62z"/></svg>
                                {{ performance.venues[0] }}
                                </div>
                            </div>
                            <div v-if="eventData[performance.eventId].contentMetaDescription && eventData[performance.eventId].contentMetaDescription.length > 0" class="posts__content posts__content_extended_3" v-html="eventData[performance.eventId].contentMetaDescription"></div>
                            <template v-else>
                            <div v-if="eventData[performance.eventId].productionSeasonDescriptionLong && eventData[performance.eventId].productionSeasonDescriptionLong.length > 0" class="posts__content posts__content_extended_3" v-html="eventData[performance.eventId].productionSeasonDescriptionLong"></div>
                            <div v-else class="posts__content">
                                <p v-if="performance.text1 && performance.text1.length > 0">
                                    {{ performance.text1 }}
                                </p>
                                <p v-if="performance.text2 && performance.text2.length > 0">
                                    {{ performance.text2 }}
                                </p>
                            </div> 
                            </template>
                            <div class="posts__foot">
                                <a class="posts__more" :href="eventData[performance.eventId].contentUrl">Event details »</a>
                                <span v-if="performance.state > 1" class="posts__divider">|</span>
                                <template v-if="eventData[performance.eventId].contentBookUrlOverride">
                                    <a class="posts__more" :href="eventData[performance.eventId].contentBookUrlOverride">{{ eventData[performance.eventId].contentBookUrlNameOverride }} »</a>
                                </template>
                                <template v-else>
                                    <a v-if="performance.state == 2" class="posts__more" :href="performance.subscribeUrl">Subscribe »</a>
                                    <a v-if="performance.state == 3" class="posts__more" :href="performance.url">Access presale »</a>
                                    <a v-if="performance.state > 3" class="posts__more" :href="performance.url">Order now »</a>
                                </template>
                            </div>
                            </div>
                            </template>
                        </div>
                    </div>
                    </div>
                </template>

                <template v-else-if="this.groupProductions">
                    <div v-if="!loading && (!this.events || events.length == 0)" class="calendar__posts posts posts_calendar empty-calendar">
                        <div class="posts__title h6">No matching events were found</div>
                        <p v-if="allowfilters">Try widening your <a href="#" @click.prevent="paramsOpen = true">search criteria</a>.</p>
                    </div>
                    <div class="calendar__section">
                    <!-- posts-->
                    <div class="calendar__posts posts posts_calendar">
                        <div v-for="eventData in sortedEvents" :key="eventData.Id" class="posts__item">
                            <a class="posts__preview" :href="eventData.contentUrl">
                                <img class="posts__pic" :src="eventData.productionSeasonListImageUrl" :alt="eventData.productionSeasonListImageAlt">
                                <div v-if="seasons[eventData.seasonId] && seasons[eventData.seasonId].showBadge" class="posts__season_badge">{{ seasons[eventData.seasonId].badgeText }}</div>
                            </a>
                            <div class="posts__inner">
                                <a v-if="eventData.concertTypes && eventData.concertTypes.length > 0" class="posts__link link" :href="eventData.concertTypes ? '/concerts-tickets/whats-on?concert-types=' + eventData.concertTypes[0] : ''">{{ eventData.concertTypes ? eventData.concertTypes[0] : '' }}</a>
                                <div v-if="eventData.priceRange == 'Free'" class="posts__badge">Free Event</div>
                            <div class="posts__title h6">
                                <a :href="eventData.contentUrl">
                                    <!-- Redman, Mehldau, McBride, Blade A MoodSwing Reunion -->
                                    {{ eventData.contentTitle ||
                                    eventData.description }}
                                </a>
                            </div>
                            <div class="posts__meta">
                                <div class="posts__detail"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="15"><path fill="#b22222" fill-rule="evenodd" d="M7 .62a7 7 0 0 1 7 6.98 7 7 0 0 1-7 6.98A7 7 0 0 1 0 7.6 7 7 0 0 1 7 .62zm0 1.5A5.5 5.5 0 0 0 1.5 7.6 5.5 5.5 0 0 0 7 13.08a5.5 5.5 0 0 0 5.5-5.48A5.5 5.5 0 0 0 7 2.12zM7 3.8c.38 0 .69.31.69.69h0v3.48l1.84 1.33c.31.23.37.66.15.96h0l-.07.08c-.1.1-.23.17-.38.19a.7.7 0 0 1-.11.01c-.14 0-.27-.04-.39-.12h0 0l-.01-.01-2.4-1.74h.01-.01V4.48c0-.38.31-.69.69-.69z"/></svg>{{ eventData.performanceDates }}</div>
                                <div v-if="eventData.venues && eventData.venues.length > 0" class="posts__detail">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16"><path fill="#b22222" fill-rule="evenodd" d="M6.53.06a5.9 5.9 0 0 1 4.28 1.91c1.12 1.17 1.77 2.69 1.77 4.17 0 2.13-1.64 4.94-4.87 8.36h0l-.22.23c-.26.27-.61.41-.96.41-.29 0-.58-.1-.83-.29h0l-.01-.01-.1-.09C2.2 11.2.48 8.31.48 6.13c0-1.47.64-2.99 1.77-4.17A5.9 5.9 0 0 1 6.53.06zm0 1.52c-2.35 0-4.57 2.22-4.57 4.56 0 1.65 1.54 4.12 4.57 7.35 3.03-3.22 4.57-5.7 4.57-7.35 0-2.34-2.22-4.56-4.57-4.56zm0 1.42a3.1 3.1 0 0 1 3.1 3.09 3.1 3.1 0 0 1-3.1 3.09 3.1 3.1 0 0 1-3.1-3.09A3.1 3.1 0 0 1 6.53 3h0zm0 1.47c-.89 0-1.62.73-1.62 1.62S5.63 7.7 6.53 7.7s1.62-.73 1.62-1.62-.73-1.62-1.62-1.62z"/></svg>
                                {{ eventData.venues.join(', ') }}
                                </div>
                            </div>
                            <div v-if="eventData.contentMetaDescription && eventData.contentMetaDescription.length > 0" class="posts__content posts__content_extended_3" v-html="eventData.contentMetaDescription"></div>
                            <template v-else>
                            <div v-if="eventData.productionSeasonDescriptionLong && eventData.productionSeasonDescriptionLong.length > 0" class="posts__content posts__content_extended_3" v-html="eventData.productionSeasonDescriptionLong"></div>
                            </template>
                            <div class="posts__foot">
                                <a class="posts__more" :href="eventData.contentUrl">Event details »</a>
                                <span v-if="eventData.state > 1" class="posts__divider">|</span>
                                <template v-if="eventData.contentBookUrlOverride">
                                    <a class="posts__more" :href="eventData.contentBookUrlOverride">{{ eventData.contentBookUrlNameOverride }} »</a>
                                </template>
                                <template v-else>
                                    <template v-if="eventData.performanceDates.includes(' - ')">
                                        <a v-if="eventData.state == 2" class="posts__more" :href="eventData.subscribeUrl">Subscribe »</a>
                                        <a v-if="eventData.state == 3" class="posts__more" :href="eventData.contentUrl+'#schedule'">Access presale »</a>
                                        <a v-if="eventData.state > 3" class="posts__more" :href="eventData.contentUrl+'#schedule'">Order now »</a>
                                    </template>
                                    <template v-else>
                                        <a v-if="eventData.state == 2" class="posts__more" :href="eventData.subscribeUrl">Subscribe »</a>
                                        <a v-if="eventData.state == 3" class="posts__more" :href="eventData.bookUrl">Access presale »</a>
                                        <a v-if="eventData.state > 3" class="posts__more" :href="eventData.bookUrl">Order now »</a>
                                    </template>
                                </template>                                
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </template>

                <div v-if="loading">
                <div class="loading"></div>
                <div v-for="index in loadingSkeletons" :key="index" class="calendar__section skeleton-container">
                    <div class="calendar__date skeleton">&nbsp;</div>
                    <div class="calendar__posts posts posts_calendar">
                        <div class="posts__item">
                            <div class="posts__preview skeleton">
                                <div class="posts__pic skeleton">&nbsp;</div>
                            </div>
                            <div class="posts__inner">
                                <div class="posts__link skeleton">&nbsp;</div>
                                <div class="posts__title skeleton h6">&nbsp;</div>
                                <div class="posts__meta skeleton">
                                    <div class="posts__detail">&nbsp;</div>
                                    <div class="posts__detail skeleton">&nbsp;</div>
                                </div>
                                <div class="posts__content posts__content_extended skeleton">
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                </div>
                                <div class="posts__foot skeleton">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="calendar__col calendar__col__container" :class="calendarOpen ? 'open' : ''">
                <calendarpicker ref="CalendarPicker" :initialSelectedDate="selectedDate" :events="calendarEvents" @onSelectDay="onSelectDay" @onChangeViewDate="onChangeViewDate">
                    <template v-slot:footer>&nbsp;</template>
                </calendarpicker>
                <div class="calendar__col__buttons mobile-only">
                <a href="#" @click.prevent="setToday()" class="btn">
                    <div class="btn__circle"></div>
                    <div class="btn__title">Today</div>
                </a>
                </div>
                <div v-if="linkbuttons && linkbuttons.length > 0" class="calendar__col__buttons">
                    <a v-for="linkbutton in linkbuttons" :key="linkbutton.name" :target="linkbutton.target ? linkbutton.target : ''" class="btn" :href="linkbutton.url">
                        <div class="btn__circle"></div>
                        <div class="btn__title">{{ linkbutton.name }}</div>
                    </a>
                </div>
            </div>
        </div>

        <div class="calendar__foot"><a v-if="canShowMore" class="btn" @click.prevent="nextPage">
                <div class="btn__circle"></div>
                <div class="btn__title">Show more events</div></a>
            </div>
        </div>
    </div>
    </div>
</template>


<script>
    import Vue from 'vue'
    //import moment from 'moment';
    import moment from 'moment-timezone';
    import * as SessionStoreWithExpiry from '../utils';

    export default {
        props: ['seasonfilter', 'allowfilters', 'allowpaidfilter', 'allowgroup', 'presetfilters', 'linkbuttons', 'concerttypes', 'platforms', 'venues', 'genres', 'seasons'],
        data() {

            // create empty filters
            let filterConcertTypes = this.createEmptyFilterObj(this.concerttypes);
            let filterGenres = this.genres.reduce((obj, key) => Object.assign(obj, {[key]: false}), {});
            let filterVenues = this.venues.reduce((obj, key) => Object.assign(obj, {[key]: false}), {});
            let filterPlatforms = this.platforms.reduce((obj, key) => Object.assign(obj, {[key]: false}), {});
            let groupProductions = false

            // seasons are an object not an array as more complex, unpack into array for filter
            let seasonsList = Object.keys(this.seasons).filter(key => this.seasons[key].showFilter).map(key => this.seasons[key].filterText);
            let filterSeasons = seasonsList.reduce((obj, key) => Object.assign(obj, {[key]: false}), {});

            // set defaults if we have them
            if (this.presetfilters) {
                filterConcertTypes = Object.assign(filterConcertTypes, this.createDefaultFilterObj(this.presetfilters['concert-types']));
                filterGenres = Object.assign(filterGenres, this.createDefaultFilterObj(this.presetfilters['genres']));
                filterVenues = Object.assign(filterVenues, this.createDefaultFilterObj(this.presetfilters['venues']));
                filterPlatforms = Object.assign(filterPlatforms, this.createDefaultFilterObj(this.presetfilters['platforms']));
                filterSeasons = Object.assign(filterSeasons, this.createDefaultFilterObj(this.presetfilters['seasons']));
                if (this.presetfilters['groupProductions'] && this.presetfilters['groupProductions'].length > 0) {
                    groupProductions = this.presetfilters['groupProductions'][0] == "true";
                }
            }

            let tz = "America/Chicago";
            return {
                seasonsList: seasonsList,
                selectedDate: moment.tz(tz).startOf('day').toDate(),
                paramsOpen: false,
                paidFilter: '',
                filterConcertTypes: filterConcertTypes,
                filterGenres: filterGenres,
                filterVenues: filterVenues,
                filterPlatforms: filterPlatforms,
                filterSeasons: filterSeasons,
                groupProductions: groupProductions,
                events: [],
                eventDates: [],
                eventData: {},
                resultsPage: 0,
                totalResults: 0,
                tz: tz,
                moment: moment,
                loading: true,
                loadingSkeletons: 2,
                calendarOpen: false
            }
        },
        computed: {
            sortedEvents() {
                // sort based on the current view date and performances
                let viewDate = moment(this.selectedDate, "YYYY-MM-DD").utc().format("YYYYMMDD");

                // get the next performance for each event on/after the selected date
                let eventsWithNextAfterSelectedDate = this.events.map(x => {

                    let nextAfterSelectedDate = "X"; // moment(x.nextPerformanceDate, "YYYY-MM-DD").format("YYYYMMDD");
                    let performanceDates = x.performanceDatesCsv.split(',');
                    if (performanceDates?.length > 0) {
                        performanceDates = performanceDates.filter(x => x >= viewDate).sort();
                        if (performanceDates?.length > 0) {
                            nextAfterSelectedDate = performanceDates[0];
                        }
                    }

                    return Object.assign(x, {
                        nextAfterSelectedDate: nextAfterSelectedDate
                    });
                });

                // sort by that next performance date
                return eventsWithNextAfterSelectedDate.sort((a, b) => {
                    if (a.nextAfterSelectedDate < b.nextAfterSelectedDate) return -1;
                    if (a.nextAfterSelectedDate > b.nextAfterSelectedDate) return 1;
                    return 0;
                });
            },
            paidFilterSelect: {
                get: function() {
                    return this.paidFilter;
                },
                set: function(newVal) {
                    this.paidFilterChange(newVal);
                }
            },
            groupProductionsSelect: {
                get: function() {
                    if (this.groupProductions) {
                        return "productions";
                    }
                    return "performances"
                },
                set: function(newVal) {
                    if (newVal == "productions") {
                        this.groupProductionsChange(true);
                    } else {
                        this.groupProductionsChange(false);
                    }
                }
            },
            groupProductionsLabel() {
                if (this.groupProductions) {
                    return "Productions";
                }
                return "Performances";
            },
            filtersSummary() {

                var cts = Object.keys(this.filterConcertTypes).filter(x => this.filterConcertTypes[x]).map(x => x);
                var gs = Object.keys(this.filterGenres).filter(x => this.filterGenres[x]).map(x => x);
                var vns = Object.keys(this.filterVenues).filter(x => this.filterVenues[x]).map(x => x);
                var pfms = Object.keys(this.filterPlatforms).filter(x => this.filterPlatforms[x]).map(x => x);
                var seasns = Object.keys(this.filterSeasons).filter(x => this.filterSeasons[x]).map(x => x);

                return [cts, gs, vns, pfms, seasns].flat();
            },
            calendarEvents() {
                return this.eventDates.map((e) => {
                    return {
                        date: moment.tz(e, this.tz),
                        class: ''
                    };
                });
            },
            shownResults() {
                return this.events.length;
            },
            canShowMore() {
                return (this.events && this.events.length < this.totalResults);
            },
            eventDays() {
                // an array of dates for which we have performances
                return Object.keys(this.eventsByDay);
            },
            eventDayTitles() {
                let titles = {};
                Object.keys(this.eventsByDay).forEach((key) => {
                    let m = moment(key, "YYYY-MM-DD");
                    if (m.isSame(moment(), 'day')) {
                        titles[key] = "Today, " + m.format("MMMM D");
                    } else {
                        titles[key] = m.format("dddd, MMMM D");
                    }
                });
                return titles;
            },
            eventsByDay() {
                // an object with property for each day containing array of performances
                let byDay = {};
                if (this.events) {
                    this.events.forEach((e) => {
                        let eventKey = moment(e.performanceDate).tz(this.tz).format("YYYY-MM-DD");
                        if (!byDay[eventKey]) {
                            byDay[eventKey] = [];
                        }
                        byDay[eventKey].push(e);
                    });
                }
                return byDay;
            }
        },
        watch: {
            paramsOpen(value) {
                this.$root.setOverlay(value);
            }
        },
        methods: {
            documentPointerEvent(event) {

                // ignore if click was within modal
                if (this.calendarNode.contains(event.target)) {
                    return;
                }

                 this.calendarOpen = false;
            },
            documentOnKeyUp(event) {
                if(event.key === "Escape") {
                    this.calendarOpen = false;
                }
            },
            groupProductionsChange: function(newVal) {
                this.groupProductions = newVal;
                this.writeFilterToUrl();
                this.scrollToTop();
                this.firstPage();
            },
            setToday: function() {
                this.onSelectDay(moment(), true);
            },
            toggleCalendar: function() {
                 this.calendarOpen = !this.calendarOpen;
             },
             createEmptyFilterObj: function(keysArray) {
                 if (!keysArray || keysArray.length == 0) return {};
                 return keysArray.reduce((obj, key) => Object.assign(obj, {[key]: false}), {});
             },
             createDefaultFilterObj: function(setKeysArray) {
                 if (!setKeysArray || setKeysArray.length == 0) return {};
                 return setKeysArray.reduce((obj, key) => Object.assign(obj, {[key]: true}), {});
             },
             setUrlFromArray: function(searchParams, parameter, values) {
                 if (values && values.length > 0) {
                     searchParams.set(parameter, values.join(","));
                 } else {
                     searchParams.delete(parameter);
                 }
             },
             writeFilterToUrl: function() {
                const url = new URL(window.location);

                // url.searchParams.set('date', moment(this.selectedDate).format("YYYY-MM-DD"));
                let UTCDate = moment(this.selectedDate).clone().utc();
                url.searchParams.set('date', UTCDate.format("YYYY-MM-DD"));

                url.searchParams.set('paid', this.paidFilter);
                url.searchParams.set('group', this.groupProductions);

                var cts = Object.keys(this.filterConcertTypes).filter(x => this.filterConcertTypes[x]).map(x => x);
                this.setUrlFromArray(url.searchParams, 'concert-types', cts);

                var gs = Object.keys(this.filterGenres).filter(x => this.filterGenres[x]).map(x => x);
                this.setUrlFromArray(url.searchParams, 'genres', gs);

                var vns = Object.keys(this.filterVenues).filter(x => this.filterVenues[x]).map(x => x);
                this.setUrlFromArray(url.searchParams, 'venues', vns);

                var pfms = Object.keys(this.filterPlatforms).filter(x => this.filterPlatforms[x]).map(x => x);
                this.setUrlFromArray(url.searchParams, 'platforms', pfms);

                var seasns = Object.keys(this.filterSeasons).filter(x => this.filterSeasons[x]).map(x => x);
                this.setUrlFromArray(url.searchParams, 'seasons', seasns);

                window.history.pushState({}, '', url);
             },
             createFilterObject: function(allValues, setValues) {
                let unsetFilters = allValues.reduce((obj, key) => Object.assign(obj, {[key]: false}), {});
                let setFilters = setValues.reduce((obj, key) => Object.assign(obj, {[key]: true}), {});
                return Object.assign(unsetFilters, setFilters);
             },
             getFilterObjectFromUrl: function(searchParams, key, items) {
                 if (searchParams.has(key) && searchParams.get(key)) {
                    return this.createFilterObject(items, searchParams.get(key).split(','));
                } else {
                    return this.createFilterObject(items, []);
                }
             },
             setFilterFromUrl: function() {
                // read URL params and if any are set refresh the query
                 const url = new URL(window.location);
                 let dateWasSet = false;

                // set date
                if (url.searchParams.has("date") && url.searchParams.get("date")) {
                    this.selectedDate = moment.tz(url.searchParams.get("date"), "YYYY-MM-DD", this.tz).toDate();
                    dateWasSet = true;
                } else {
                    this.selectedDate = moment.tz(this.tz).startOf('day').toDate()
                }
                this.$refs.CalendarPicker.updateDate(this.selectedDate);

                // set paid
                if (url.searchParams.has("paid") && url.searchParams.get("paid")) {
                    this.paidFilter = url.searchParams.get("paid");
                } else {
                    this.paidFilter = '';
                }

                // set group
                if (this.allowgroup) {
                    if (url.searchParams.has("group") && url.searchParams.get("group")) {
                        this.groupProductions = url.searchParams.get("group") == "true";
                    }
                }

                // only allow set filters on some pages
                if (this.allowfilters) {

                    this.filterConcertTypes = this.getFilterObjectFromUrl(url.searchParams, "concert-types", this.concerttypes);
                    this.filterGenres = this.getFilterObjectFromUrl(url.searchParams, "genres", this.genres);
                    this.filterVenues = this.getFilterObjectFromUrl(url.searchParams, "venues", this.venues);
                    this.filterPlatforms = this.getFilterObjectFromUrl(url.searchParams, "platforms", this.platforms);
                    this.filterSeasons = this.getFilterObjectFromUrl(url.searchParams, "seasons", this.seasonsList);
                } else {

                    // always allow season filter?
                    this.filterSeasons = this.getFilterObjectFromUrl(url.searchParams, "seasons", this.seasonsList);
                }

                 return dateWasSet;
             },
             scrollToTop: function() {
                 this.$refs.Container.scrollIntoView({
                    behavior: 'smooth'
                });
             },
             onChangeViewDate: function(viewDate) {
                 // behave as if we are selecting the 1st of the month
                 // when moving to a new month view
                 this.selectedDate = viewDate.toDate();
                 this.writeFilterToUrl();
                 this.scrollToTop();
                 this.firstPage();
             },
             onSelectDay: function(selectedDate, dayClicked) {
                 if (dayClicked) {
                    this.calendarOpen = false;
                 }
                 this.selectedDate = selectedDate.toDate();
                 this.writeFilterToUrl();
                 this.scrollToTop();
                 this.firstPage();
             },
             paidFilterChange: function(value) {
                 if (this.paidFilter != value) {
                    this.paidFilter = value;
                    this.writeFilterToUrl();
                    this.scrollToTop();
                    this.firstPage();
                 }
             },
             concertTypeChange: function(isSet, value) {
                this.filterConcertTypes[value] = isSet;
             },
             venueChange: function(isSet, value) {
                this.filterVenues[value] = isSet;
             },
             genreChange: function(isSet, value) {
                this.filterGenres[value] = isSet;
             },
             platformChange: function(isSet, value) {
                this.filterPlatforms[value] = isSet;
             },
             seasonChange: function(isSet, value) {
                console.log("seasonChange", isSet, value);
                console.dir(this.filterSeasons);
                this.filterSeasons[value] = isSet;
             },
             seasonChangeAndUpdate: function(isSet, value) {
                console.log("seasonChangeAndUpdate", isSet, value);
                this.seasonChange(isSet, value);
                this.findEvents();
             },
             clearFilter: function(filter) {
                 if (filter) {
                    Object.keys(filter).forEach((key) => {
                        filter[key] = false;
                    });
                }
             },
             clearFilters: function() {
                this.clearFilter(this.filterConcertTypes);
                this.clearFilter(this.filterGenres);
                this.clearFilter(this.filterVenues);
                this.clearFilter(this.filterPlatforms);
                this.clearFilter(this.filterSeasons);

                this.paramsOpen = false;
                this.writeFilterToUrl();
                this.scrollToTop();
                this.firstPage();
             },
             findEvents: function() {
                this.paramsOpen = false;
                this.writeFilterToUrl();
                this.scrollToTop();
                this.firstPage();
             },
             loadData(skipCache) {

                 if (skipCache === undefined) skipCache = false;

                 // util to unpack the data and set in state
                 let unpackData = (data) => {

                    let unpackedData = Object.assign({}, data);
                    unpackedData.eventDates = this.eventDates;

                    // load the calendar event dates if we have them, but also
                    // only needed on the first page
                    if (data && data.eventDates && this.resultsPage == 0) {
                        this.eventDates = data.eventDates;
                        unpackedData.eventDates = data.eventDates
                    }

                    // concat the performances after first page
                    if (data) {
                        if (this.resultsPage == 0) {
                            this.totalResults = data.eventsCount;
                            if (data.productions) {
                                this.events = data.productions;
                                unpackedData.productions = data.productions;
                            } else {
                                this.events = data.performances;
                                unpackedData.performances = data.performances;
                            }
                        } else {
                            if (data.productions) {
                                unpackedData.productions = this.events.concat(unpackedData.productions);
                                unpackedData.eventsCount = this.eventsCount;
                                this.events = this.events.concat(data.productions);
                            } else {
                                unpackedData.performances = this.events.concat(unpackedData.performances);
                                unpackedData.eventsCount = this.eventsCount;
                                this.events = this.events.concat(data.performances);
                            }
                        }
                    }

                    if (data && data.events) {
                        unpackedData.events = Object.assign(this.eventData, data.events);
                        this.eventData = Object.assign(this.eventData, data.events);
                    }

                    // next load will be next page
                    unpackedData.resultsPage = this.resultsPage;

                    return unpackedData;
                 };

                 // send criteria to server and display returned data
                 var request = {
                    from: moment.tz(this.selectedDate, 'UTC').tz(this.tz),
                    paid: this.paidFilter,
                    group: this.groupProductions,
                    concerttypes: Object.keys(this.filterConcertTypes).map(key => this.filterConcertTypes[key] ? key : false ).filter(x => x),
                    genres: Object.keys(this.filterGenres).map(key => this.filterGenres[key] ? key : false ).filter(x => x),
                    venues: Object.keys(this.filterVenues).map(key => this.filterVenues[key] ? key : false ).filter(x => x),
                    platforms: Object.keys(this.filterPlatforms).map(key => this.filterPlatforms[key] ? key : false ).filter(x => x),
                    seasons: Object.keys(this.filterSeasons).map(key => this.filterSeasons[key] ? key : false ).filter(x => x),
                }

                // cahce key (before page!)
                let cacheKey = JSON.stringify(request);

                // check in sessionStorage
                let hasCache = false;
                if (!skipCache) {
                    let data = this.getCache(cacheKey);
                    if (data) {
                        hasCache = true;
                        unpackData(data);
                        this.loading = false;
                    }
                }

                // add the page before requesting from server
                request.page = this.resultsPage;

                // load from server
                if (!hasCache) {
                    this.loading = true;
                    this.$http.post('/umbraco/surface/events/calendar', request)
                        .then(({ data }) => {

                            let unpackedData = unpackData(data);

                            // cache data
                            this.setCache(cacheKey, unpackedData);

                            this.loading = false;
                        })
                        .catch((error) => {

                            this.loading = false;

                            console.error("Error loading events");
                            console.dir(error);
                        });
                }
             },
             firstPage() {
                this.resultsPage = 0; // new query always start from page 0
                this.events = [];
                this.totalResults = 0;
                this.eventData = {};
                this.$nextTick(() => {
                    this.loadData();
                });
             },
             nextPage() {
                this.resultsPage = this.resultsPage + 1;
                this.loadData(true);
             },
             setCache(key, data) {
                SessionStoreWithExpiry.setSessionStorageWithExpiry(key, data, (60 * 60 * 100));
             },
             getCache(key) {
                return SessionStoreWithExpiry.getSessionStorageWithExpiry(key);
             }
        },
        mounted() {
            this.calendarNode = this.$el.getElementsByClassName('calendar__col__container')[0];

            if (this.calendarNode) {
                document.addEventListener('mousedown', this.documentPointerEvent, false);
                document.addEventListener('keyup', this.documentOnKeyUp);
            }

            window.addEventListener('popstate', (event) => {
                this.setFilterFromUrl();
                this.firstPage();
            });

            let dateWasSet = this.setFilterFromUrl();

            if (!dateWasSet && this.seasonfilter.PrefilterId && this.seasonfilter.PrefilterId > 0) {
                var request = {
                    seasonId: this.seasonfilter.PrefilterId
                }
                this.loading = true

                this.$http.post('/umbraco/surface/events/CalendarStart', request)
                    .then(({ data }) => {
                        this.selectedDate = moment.tz([data.year, (data.month - 1), 1, 0], this.tz).toDate()

                        this.$refs.CalendarPicker.updateDate(this.selectedDate);
                        this.firstPage();

                    })
                    .catch((error) => {
                        this.firstPage();
                        //console.error("Error loading start date");
                        //console.dir(error);
                    });
            }
            else {
                this.firstPage();
            }            
        },
        beforeUnmount() {
            window.removeEventListener('popstate');

            if (this.calendarNode) {
                document.removeEventListener('mousedown', this.documentPointerEvent, false);
                document.removeEventListener('keyup', this.documentOnKeyUp);
            }
        }
    }
</script>
