<template>
    <div class="pager" v-if="totalpages > 1">
        <a class="pager__prev" href="#" v-on:click.prevent="prev">Prev</a>
        <a class="pager__btn" href="#" v-bind:class="{active : 1 == currentPage}" v-on:click.prevent="select(1)">1</a>
        <a class="" href="#" v-on:click.prevent v-if="currentPage > 4">...</a>
        <a class="pager__btn" href="#" v-for="page in pages" v-bind:class="{active : page == currentPage}" v-on:click.prevent="select(page)">{{page}}</a>
        <a class="" href="#" v-on:click.prevent v-if="currentPage < totalpages - 3">...</a>
        <a class="pager__btn" href="#" v-bind:class="{active : totalpages == currentPage}" v-on:click.prevent="select(totalpages)">{{totalpages}}</a>
        <a class="pager__next" href="#" v-on:click.prevent="next">Next</a>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { EventBus } from '../vues/event-bus'

    export default {
        props: ['totalpages'],
        data() {
            return {
                currentPage: 1
            }
        },
        computed: {
            pages() {
                var pages = []
                for (var i = 2; i < this.totalpages; i++) {
                    var delta = Math.abs(this.currentPage - i)
                    if (this.currentPage < 3) {
                        if (delta <= 5 - (this.currentPage)) {
                            pages.push(i)
                        }
                    }
                    else if (this.totalpages - this.currentPage < 2) {
                        if (delta <= 4 - (this.totalpages - this.currentPage )) {
                            pages.push(i)
                        }
                    }
                    else if (delta > 2) {
                        // do not show
                    }
                    else {
                        pages.push(i)
                    }
                }
                return pages
            }
        },
        methods: {
            select(page) {
                this.currentPage = page
                this.emit()
            },
            prev() {
                if (this.currentPage > 1) {
                    this.currentPage--;
                    this.emit()
                }
            },
            next() {
                if (this.currentPage < this.totalpages) {
                    this.currentPage++
                    this.emit()
                }
            },
            emit() {
                EventBus.$emit('pager', { page: this.currentPage })
            }
        },
        mounted() {
            var self = this
            EventBus.$on('changePage', (data) => {
                self.currentPage = data.page
            })
        }
    }
</script>