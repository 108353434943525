<template>
    <div>
        <div class="sidebar__title">{{ title }}</div>
        <div class="sidebar__articles articles related-performances">
            <div class="articles__item" v-for="(item, index) in items" :key="index">
                <a class="articles__preview" :href="item.url"><img class="articles__pic" :src="item.image" :alt="item.title"></a>
                <div class="articles__inner">
                    <a class="articles__title" :href="item.url">{{item.title}}</a>
                    <a class="articles__dates">{{ item.performanceDates }}</a>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Vue from 'vue'
    // import Post from './Post.vue';

    export default {
        props: ['title', 'keywords', 'limit'],
        data() {
            return {
                items: []
            }
        },
        components: {
            // Post,
        },
        computed: {
            hasItems() {
                return (this.countItems > 0);
            },
            countItems() {
                if (this.items) {
                    return this.items.length;
                }
                return 0;
            }
        },
        methods: {
            update(kws) {
                var request = {
                    limit: this.limit,
                    keywords: kws.split("|")
                }
                this.$http.post('/umbraco/surface/events/related', request)
                    .then(({ data }) => {
                        if (data) {

                            if (data.length == 0) {
                                this.update("");
                            } else {
                                data.sort((a, b) => {
                                    return a.nextPerformanceEpoch - b.nextPerformanceEpoch;
                                });
                                // this.items = data;
                                let targetScore = data[0].score / 2;
                                this.items = data.filter(item => {
                                    return item.score >= targetScore;
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        console.error("Error loading related performances");
                        console.dir(error);
                     })
            }
        },
        mounted() {
            this.update(this.keywords);
        }
    }
</script>
