<template>
    <div class="schedule_packages" v-if="active">
        <div class="schedule_packages_row" v-for="item in packages">
            <div class="spr_title">{{item.webTitle}}</div>
            <div class="spr_info" v-html="item.shortDescription"></div>
            <a class="btn btn_sm" :href="item.url">
                <div class="btn__circle"></div>
                <div class="btn__title" v-html="item.buttonText"></div>
            </a>
            <div class="spr_more" v-on:click="getConcerts(item.id, item.url)">See all concerts in this package [+]</div>
        </div>        
        <div class="loading" v-if="!loaded">
            <div class="loading_bar"></div>
            <div class="loading_text">Loading Packages...</div>
        </div>
        <div class="loading" v-if="loaded && !packages.length">
            <div class="loading_text">No options found.</div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    export default {
        props: {
            performanceId: {
                type: Number,
                default: 0
            },
            active: {
                type: Boolean,
                default: false
            }            
        },
        data() {
            return {             
                packages: [],
                loaded: false,
                busy: false
            }
        },
        watch: {
            active: function (newVal, oldVal) {
                if (newVal && !this.loaded) {
                    this.getPackages()
                }
            }
        },
        methods: {
            getUrl(pkgUrl) {
                window.location.href = pkgUrl
            },
            getPackages() {
                if (!this.busy) {
                    var self = this
                    this.busy = true

                    var request = {
                        performanceIds: [this.performanceId]
                    }
                    self.$http.post('/umbraco/surface/events/PackagesForPerformances', request)
                        .then(({ data }) => {
                            self.packages = data
                            self.loaded = true
                        })
                        .catch((error) => {
                            self.loaded = true
                        })
                }
            },
            getConcerts: function (pkgId, pkgUrl) {
                this.$emit('on-all-concerts', pkgId, pkgUrl);
            }
        },
        mounted() {}
    }
</script>