<template>
    <div class="upcoming-event">
       <div class="upcoming__events events events_upcoming">
            <div v-if="hasItem" class="events__item" :class="`${bordered ? 'bordered' : ''} ${background ? 'background' : ''}`">
                <a class="events__preview" :href="getUrl(item)">
                    <img class="events__pic" :src="item.productionSeasonListImageUrl" :alt="item.productionSeasonListImageAlt">
                </a>
                <div class="events__inner">
                    <template v-if="showTag && tagText && tagText.length > 0">
                        <a v-if="tagLink && tagLink.length > 0" class="events__link link" :href="tagLink" :target="tagTarget">{{ tagText }}</a>
                        <div v-else class="events__link">{{ tagText }}</div>
                    </template>
                    <template v-else-if="showTag">
                        <a v-if="item.concertTypes && item.concertTypes.length > 0" class="events__link link" :href="item.concertTypes ? '/concerts-tickets/whats-on?concert-types=' + item.concertTypes[0] : ''">{{ item.concertTypes ? item.concertTypes[0] : '' }}</a>
                        <div v-if="item.priceRange == 'Free'" class="events__badge">Free Event</div>
                    </template>
                <h6 class="events__title">
                    <a :href="item.contentUrl">
                        <!-- Redman, Mehldau, McBride, Blade A MoodSwing Reunion -->
                        {{ item.contentTitle ||
                        item.description }}
                    </a>
                </h6>
                <div class="events__meta">
                    <div class="events__detail"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="15"><path fill="#b22222" fill-rule="evenodd" d="M7 .62a7 7 0 0 1 7 6.98 7 7 0 0 1-7 6.98A7 7 0 0 1 0 7.6 7 7 0 0 1 7 .62zm0 1.5A5.5 5.5 0 0 0 1.5 7.6 5.5 5.5 0 0 0 7 13.08a5.5 5.5 0 0 0 5.5-5.48A5.5 5.5 0 0 0 7 2.12zM7 3.8c.38 0 .69.31.69.69h0v3.48l1.84 1.33c.31.23.37.66.15.96h0l-.07.08c-.1.1-.23.17-.38.19a.7.7 0 0 1-.11.01c-.14 0-.27-.04-.39-.12h0 0l-.01-.01-2.4-1.74h.01-.01V4.48c0-.38.31-.69.69-.69z"/></svg>{{ item.performanceDates }}</div>
                    <div v-if="item.venues && item.venues.length > 0" class="events__detail">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16"><path fill="#b22222" fill-rule="evenodd" d="M6.53.06a5.9 5.9 0 0 1 4.28 1.91c1.12 1.17 1.77 2.69 1.77 4.17 0 2.13-1.64 4.94-4.87 8.36h0l-.22.23c-.26.27-.61.41-.96.41-.29 0-.58-.1-.83-.29h0l-.01-.01-.1-.09C2.2 11.2.48 8.31.48 6.13c0-1.47.64-2.99 1.77-4.17A5.9 5.9 0 0 1 6.53.06zm0 1.52c-2.35 0-4.57 2.22-4.57 4.56 0 1.65 1.54 4.12 4.57 7.35 3.03-3.22 4.57-5.7 4.57-7.35 0-2.34-2.22-4.56-4.57-4.56zm0 1.42a3.1 3.1 0 0 1 3.1 3.09 3.1 3.1 0 0 1-3.1 3.09 3.1 3.1 0 0 1-3.1-3.09A3.1 3.1 0 0 1 6.53 3h0zm0 1.47c-.89 0-1.62.73-1.62 1.62S5.63 7.7 6.53 7.7s1.62-.73 1.62-1.62-.73-1.62-1.62-1.62z"/></svg>
                    {{ item.venues.join(', ') }}
                    </div>
                </div>
                <div v-if="item.contentMetaDescription && item.contentMetaDescription.length > 0" class="events__content events__content_extended_3" v-html="item.contentMetaDescription"></div>
                <template v-else>
                <div v-if="item.productionSeasonDescriptionLong && item.productionSeasonDescriptionLong.length > 0" class="events__content events__content_extended_3" v-html="item.productionSeasonDescriptionLong"></div>
                </template>
                <div v-if="!buyTicketsButton" class="events__foot">
                    <a class="events__more" :href="item.contentUrl">Event details »</a>
                    <span v-if="item.state > 1" class="events__divider">|</span>
                    <a v-if="item.state == 2" class="events__more" :href="item.subscribeUrl">Subscribe »</a>
                    <a v-if="item.state > 2" class="events__more" :href="item.bookUrl">Order now »</a>
                </div>
                </div>
                <div v-if="buyTicketsButton" class="events__supplimentary">
                    <a class="btn btn_sm" :href="item.contentUrl /*getUrl(item)*/">
                        <div class="btn__circle"></div>
                        <div class="btn__title">Get tickets</div>
                    </a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Vue from 'vue'
    import moment from 'moment-timezone';

    export default {
        props: [
            'performance-season',
            'bordered',
            'background',
            'show-tag',
            'tag-text',
            'tag-link',
            'tag-target',
            'buy-tickets-button'
        ],
        data() {
            return {
                item: null,
                SALE_STATE_PRIVATE: 0,
                SALE_STATE_ANNOUNCEMENT: 1,
                SALE_STATE_SUBSCRIPTION: 2,
                SALE_STATE_PRESALE: 3,
                SALE_STATE_SALE: 4,
                tz: "America/Chicago",
            }
        },
        computed: {
            hasItem() {
                return !!this.item;
            },
            countItems() {
                if (this.hasItem) {
                    return 1;
                }
                return 0;
            }
        },
        methods: {
            getTitle(item) {
                if (item.performanceTitle) {
                    return item.performanceTitle;
                } else if (item.description) {
                    return item.description;
                }
                return "";
            },
            getUrl(item) {
                if (item.bookUrl) {
                    return item.bookUrl;
                } else if (item.contentUrl) {
                    return item.contentUrl;
                } else if (item.url) {
                    return item.url;
                }
                return "";
            },
            getDate(item) {
                if (item.performanceDates) {
                    return item.performanceDates;
                } else if (item.performanceDateLocal) {
                    return moment(item.performanceDateLocal).format("MMM D, YYYY");
                }
                return "";
            },

            update() {
                var request = {
                    id: this.performanceSeason,
                    summary: true
                }
                this.$http.post('/umbraco/surface/events/upcoming', request)
                    .then(({ data }) => {
                        this.item = data;
                    })
                    .catch((error) => {
                        console.error("Error loading event summaries");
                     })
            }
        },
        mounted() {
            this.update()
        }
    }
</script>
