import Vue from '../config.js'
import VueScrollTo from 'vue-scrollto'
import { EventBus } from './event-bus'
var qs = require('qs')

if (document.getElementById('search-vm')) {

    var vm = new Vue({
        el: '#search-vm',
        data: {
            formats: formats,
            items: [],
            totalPages: 0,
            totalItems: 0,
            request: {
                page: 1,
                pageSize: 20,
                format: 'All',
                from: '',
                to: '',
                length: 0,
                q: model.q,
                contentTypeId: model.contentTypeId,
                authorId: model.authorId
            }
        },
        methods: {
            update(scroll = false, setQs = true) {
                var self = this
                self.$http.post('/umbraco/surface/search/query', self.request)
                    .then(({ data }) => {
                        if (setQs) {
                            var s = {}
                            if (this.request.page > 1) {
                                s.page = this.request.page
                            }
                            if (this.request.contentType != 0) {
                                s.ct = this.request.contentType
                            }
                            if (this.request.format != 'All') {
                                s.format = this.request.format
                            }
                            if (this.request.from) {
                                s.from = this.request.from
                                s.to = this.request.to
                            }
                            if (this.request.length != 0) {
                                s.length = this.request.length
                            }
                            if (this.request.page > 1) {
                                s.page = this.request.page
                            }
                            self.setQs(qs.stringify(s, { arrayFormat: 'comma', encode: false, addQueryPrefix: true }))
                        }
                        self.items = data.items
                        self.totalPages = data.totalPages
                        self.totalItems = data.totalItems
                        if (scroll) {
                            VueScrollTo.scrollTo('#results', 700)
                        }
                    })
                    .catch((error) => { })
            },
            closeDatePicker() {
                EventBus.$emit('closeDateRangePicker')
            },
            closeLengthPicker() {
                EventBus.$emit('closeLengthPicker')
            },
            restoreState() {
                var queryString = this.getQs()
                if (queryString) {
                    var qsObj = qs.parse(queryString, { comma: true, ignoreQueryPrefix: true })
                    if (qsObj.format) {
                        this.request.format = qsObj.format
                    }
                    else {
                        this.request.format = 'All'

                    }
                    if (qsObj.length) {
                        this.request.length = qsObj.length
                    }
                    else {
                        this.request.length = 0
                    }
                    if (qsObj.from) {
                        this.request.from = qsObj.from
                        this.request.to = qsObj.to
                    }
                    else {
                        this.request.from = ''
                        this.request.to = ''
                    }
                    if (qsObj.page) {
                        this.request.page = qsObj.page
                    }
                    else {
                        this.request.page = 1
                    }
                }
                else {
                    this.request.format = 'All'
                    this.request.length = 0
                    this.request.page = 1
                }
                EventBus.$emit('setdropdown', { symbol: 'format', selected: this.request.format })
                EventBus.$emit('setLengthPicker', this.request.length)
                EventBus.$emit('setDateRangePicker', { from: this.request.from, to: this.request.to })
                EventBus.$emit('changePage', { page: this.request.page })
                this.update(false, false)
            },
            tallImage(image) {
                if (image.indexOf('width=') == -1) {
                    return true
                }
                return false
            }
        },
        mounted() {
            var self = this

            self.restoreState()

            EventBus.$on('pager', (data) => {
                self.request.page = data.page
                self.update(true)
            })

            EventBus.$on('select', (data) => {
                if (data.symbol == 'format') {
                    self.request.format = []
                    if (data.selected == null) {
                        self.request.format = 'All'
                    }
                    else {
                        self.request.format = data.selected.name
                    }
                }
                self.request.page = 1
                EventBus.$emit('changePage', { page: 1 })
                self.update(true)
            })

            EventBus.$on('daterange', (data) => {
                self.request.from = data.from
                self.request.to = data.to
                self.request.page = 1
                EventBus.$emit('changePage', { page: 1 })
                self.update(true)
            })

            EventBus.$on('length', (data) => {
                self.request.length = data.length
                self.request.page = 1
                EventBus.$emit('changePage', { page: 1 })
                self.update(true)
            })
        }
    })

    window.addEventListener('popstate', function (event) {
        vm.restoreState()
    })
}